import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import CountryInfo from "../../services/CountryInfo";
import CareerJobsService from "../../services/CareerJobsService"
import DashboardJobsList from "../../components/DashboardJobsList"
import JobsModal from "../../components/JobsModal"
import { notify } from "../../helpers/notifyManager";
import Storage from "../../helpers/storage";

const DashboardJobs = () => {
    const [show, setShow] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [country, setCountry] = useState([]);
    const [jobsList, setJobsList] = useState([]);
    const [errors, setErrors] = useState({});
    const [description, setDescription] = useState("");
    const [specialities, setSpecialities] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [searchSpeciality, setSearchSpeciality] = useState("");
    const [showSupervisorForm, setShowSupervisorForm] = useState(true);

    const [values, setValues] = useState({
        name: "",
        country_id: "",
        speciality_id: "",
        salary: "",
        experience: null,
        website: "",
        description: "",
        fee: null,
        non_refundable_fee: null,
        responsibilities: [],
        processing_time: null
    });

    useEffect(() => {
        fetchSpecialities()
        getCountry()
        fetchJobs()
    }, []);

    const fetchSpecialities = async () => {
        let response = await CareerJobsService.fetchSpecialities();
        if (response) {
            setSpecialities(response);
            setShowPage(true);
        }
    };

    const getCountry = async () => {
        let response = await CountryInfo.country();
        if (response) {
            setCountry(response.data);
            setShowPage(true);
        }
    };

    const fetchJobs = async () => {
        let userFromStorage = Storage.get('user')
        let query = { user_id: userFromStorage.user_id };
        if (searchName.length > 0) Object.assign(query, { name: searchName });
        if (searchSpeciality.length > 0) Object.assign(query, { speciality_id: searchSpeciality });
        let jobsList = await CareerJobsService.fetchDashboardJobs(query)
        setJobsList(jobsList)
    }

    const handleClose = () => {
        setValues({
            name: "",
            country_id: "",
            speciality_id: "",
            salary: "",
            experience: null,
            website: "",
            description: "",
            fee: null,
            non_refundable_fee: null,
            responsibilities: [],
            processing_time: null
        })
        setShow(false);
        setShowSupervisorForm(true)
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleUpdate = (id) => {
        let jobs = Object.assign(
            {},
            jobsList.find((value) => {
                return value.id == id;
            }),
        );
        jobs.speciality_id = jobs.speciality.id
        setValues(jobs);
        setDescription(jobs.description);
        setShowSupervisorForm(false)
        handleShow();
    };

    const handleDelete = async (id) => {
        let deleteJobs = await CareerJobsService.deleteJobs(id)
        if (deleteJobs.status === 'success') {
            notify("success", "Successfully deleted")
            await fetchJobs()
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleResponsibilityChange = (event) => {
        const { value } = event.target;
        let responsibility = values.responsibilities
        if (! responsibility.includes(value)) {
            responsibility.push(value)
        }
        setValues({
            ...values,
            responsibilities: responsibility,
        })

    };

    const handleResponsibilityRemove = (value) => {
        let responsibilities = values.responsibilities
        responsibilities = responsibilities.filter(item => item !== value)
        setValues({
            ...values,
            responsibilities: responsibilities,
        })
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        const finalData = { ...values, description: description };
        let res;
        if (values.id) {
            res = await CareerJobsService.updateJobs(
                finalData,
                finalData.id,
            );
        } else {
            res = await CareerJobsService.postJobs(finalData);
        }
        if (!res) {
            return;
        }
        if (typeof res == "object" && res.status !== "success") {
            setErrors(res);
        } else {
            notify("success", res.message);
            handleClose();
            await fetchJobs();
        }
    }
    const resetSearch = () => {
        setSearchName("")
        setSearchSpeciality("")
    };

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="campaigns-card">
                                <div className="title-box d-flex justify-content-between">
                                    <div className="title-left">
                                        <h2>Job List</h2>
                                    </div>
                                    <div className="title-right">
                                        <button className={'cs-btn-success'} onClick={handleShow}>Add New Jobs</button>
                                    </div>
                                </div>

                                <JobsModal
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    country={country}
                                    description={description}
                                    setDescription={setDescription}
                                    show={show}
                                    errors={errors}
                                    specialities={specialities}
                                    handleResponsibilityChange={handleResponsibilityChange}
                                    handleResponsibilityRemove={handleResponsibilityRemove}
                                    showSupervisorForm={showSupervisorForm}
                                />

                                <div className="filter-box mb-3">
                                    <div className="form-group row mb-1">
                                        <div className="search-input mb-3 col-lg-4 col-md-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Name"
                                                onChange={(e) =>
                                                    setSearchName(
                                                        e.target.value,
                                                    )
                                                }
                                                value={searchName}
                                            />
                                        </div>
                                        <div className="form-group col-lg-3 col-md-6">
                                            <select
                                                className="form-control"
                                                value={searchSpeciality}
                                                onChange={(e) =>
                                                    setSearchSpeciality(
                                                        e.target.value,
                                                    )
                                                }
                                            >
                                                <option value="">Select Speciality</option>
                                                {specialities.map((speciality) => {
                                                    return (
                                                        <option
                                                            key={speciality.id}
                                                            value={speciality.id}
                                                        >
                                                            {speciality.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <button
                                            className="btn--apply-filter"
                                            onClick={fetchJobs}
                                        >
                                            Apply Filter
                                        </button>
                                        <button
                                            className="btn--reset-all ml-3"
                                            onClick={resetSearch}
                                        >
                                            Reset All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DashboardJobsList jobsList={jobsList} handleUpdate={handleUpdate} handleDelete={handleDelete} />
                    </div>
                </div>

            )}
        </>
    );
}

export default DashboardJobs;
