import apiCall from "../helpers/apicall"
import {client} from "../helpers/client"
import {catcher} from "../helpers"

const SupportTicketService = {
    async fetchTicket() {
        let api = apiCall("get-tickets");
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postTicket(data) {
        let api = apiCall("post-ticket");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postTicketMessage(data) {
        let api = apiCall("post-tickets-message");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    //fetch messages
    async getTicketMessages(id) {
        let api = apiCall("get-ticket-messages", {}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async updateTicketStatus(id) {
        let api = apiCall("update-tickets-status", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
}

export default SupportTicketService