import React from "react";
import { Link } from "react-router-dom";
import CountryInfo from "../../helpers/CountryInfo";

const ScholarshipListHome = ({ scholarshipList }) => {
    return (
        <>
            {scholarshipList && scholarshipList.map(scholarship => {
                return (
                    <div key={scholarship.id}>
                        <div className="single-job">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="job-content">
                                        <div className="job--text-content">
                                            <h5>
                                                {scholarship.name}
                                            </h5>
                                            <div className="label-container">
                                                <span className="job-label mr-2 institute">
                                                    Institute: {scholarship.institute} Years
                                                </span>
                                                <span className="job-label mr-2 scholarship-type">
                                                    Type: {scholarship.type}
                                                </span>
                                                <span className="job-label mr-2 country">
                                                    Country: {scholarship.country_id ? CountryInfo(
                                                    scholarship.country_id,
                                                        ).country_name
                                                        : " "}{" "}
                                                </span>
                                                <span className="job-label mr-2 last-date">
                                                    Last Date: {scholarship.last_date}
                                                </span>
                                            </div>
                                            <div className="active-status">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to={`/scholarships/${scholarship.id}`} className="col-lg-4 text-in-center">
                                    <h5 className="cs-btn-blue">Click For Details</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default ScholarshipListHome