import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import Storage from "../../helpers/storage";

const DashboardHome = (props) => {
    useEffect(() => {
        const user = Storage.get('user')
        if (user.roles.length < 1) {
            props.history.push("/user/profile/client");
        }
    }, [])


    return (
        <div className="container-fluid">
            <h1>Welcome to Home!</h1>
            <Link to={'/how-it-works/1'} > How it works !</Link>
        </div>
    )
}

export default DashboardHome;