import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const TrandingSearch = ({ BannerText, removeMenu }) => {
    return (
        <section className="trending--search-wrapper" onClick={removeMenu}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="trending--keyword-container text-center">
                            {/* <h3 className="mt-3">Trending Searches</h3> */}
                            <div className="trending--searches-container mb-3">
                                {BannerText && BannerText.map((item, key) => {
                                    return (
                                        <Link key={key} to={{ pathname: `${item.url}` }} target="_blank" rel="noopener noreferrer">
                                            <div className="single--trending-search">
                                                <div className="trending--icon-container">
                                                    <FontAwesomeIcon icon={faSearch} className="trend-icon" />
                                                    <p className="ml-1">{item.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}

                            </div>
                            <Link to={'/'} className="mb-2">More trending searching</Link>
                            <h5>Job availability may vary by location.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default TrandingSearch



