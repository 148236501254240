import React from "react";
import "./style.css";

const MultiStep = (props) => {
    return (
        <div
            className="ms--page-wrapper"
            style={{ backgroundImage: "url(/images/background.png)", minHeight: "100vh" }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="ms--form-container">
                            <div className="step--indicator-container">
                                <div className="single--step-wrapper">
                                    <div
                                        id="completed-1"
                                        className="single-step step-space"
                                    >
                                        <img
                                            src="/images/info.svg"
                                            alt="Information"
                                        />
                                    </div>
                                    <div className="single--step-content">
                                        <p>Basic Info.</p>
                                    </div>
                                </div>
                                <div className="single--step-wrapper">
                                    <div
                                        id="completed-2"
                                        className="single-step step-space"
                                    >
                                        <img
                                            src="/images/career.svg"
                                            alt="career"
                                        />
                                    </div>
                                    <div className="single--step-content">
                                        <p>Professional</p>
                                    </div>
                                </div>
                                <div className="single--step-wrapper">
                                    <div
                                        id="completed-3"
                                        className="single-step step-space"
                                    >
                                        <img src="/images/work.svg" alt="" />
                                    </div>
                                    <div className="single--step-content">
                                        <p>Educational</p>
                                    </div>
                                </div>
                                {props.formType === 'agent' ? (
                                    <div className="single--step-wrapper">
                                        <div
                                            id="completed-4"
                                            className="single-step step-space"
                                        >
                                            <img
                                                src="/images/certificate.svg"
                                                alt="certificate"
                                            />
                                        </div>
                                        <div className="single--step-content">
                                            <p>Service Description</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="single--step-wrapper">
                                        <div
                                            id="completed-4"
                                            className="single-step step-space"
                                        >
                                            <img
                                                src="/images/certificate.svg"
                                                alt="certificate"
                                            />
                                        </div>
                                        <div className="single--step-content">
                                            <p>Certification</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="step-counter">
                                <p id="show-number">Step 1 - 4</p>
                            </div>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiStep;
