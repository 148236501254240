import React from 'react';
import FeatherIcon from "feather-icons-react";

const PowerUpSearch = ({ removeMenu }) => {
    return (

        <section className="promote-wrapper ft_counter_overlay" onClick={removeMenu}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="ft_counter_mockup ft_cover">
                            <div className="animation-circle-inverse">
                                <i /><i /><i />
                            </div>
                            <img src="./images/mockup2.png" className="img-responsive" alt="img" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="app--features-content">
                            <h1 className="mb-5 whiteText"> Welcome To Your Professional Community
                        </h1>
                            <ul className="mb-4">
                                <li>
                                    <h6 className="whiteText"><FeatherIcon className="text-warning" icon="check-square" /> Make your job easier by clear guidance </h6>
                                </li>
                                <li>
                                    <h6 className="whiteText"><FeatherIcon className="text-warning" icon="check-square" /> One to one support </h6>
                                </li>
                                <li>
                                    <h6 className="whiteText"><FeatherIcon className="text-warning" icon="check-square" /> Individual career consultancy </h6>
                                </li>
                                <li>
                                    <h6 className="whiteText"><FeatherIcon className="text-warning" icon="check-square" /> Reliable service is our first priority </h6>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PowerUpSearch
