import apiCall from "../helpers/apicall"
import { client } from "../helpers/client"
import { catcher } from "../helpers"

const ConversationService = {
    async fetchConversations() {
        let api = apiCall("conversation-list");
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchConversationsByFileId(file_id) {
        let api = apiCall("get-conversation-by-file-id", {}, file_id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async fetchConversationMessages(conversation_id) {
        let api = apiCall("get-conversation-messages", {}, conversation_id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postConversationMessage(data) {
        let api = apiCall("post-conversation-message");
        try {
            let apiHeader = Object.assign({}, api.header)
            Object.assign(apiHeader, {
                'Content-Type': 'multipart/form-data'
            });
            const response = await client[api.method](api.url, apiHeader, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
}

export default ConversationService