import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import PaymentService from "../../services/PaymentService"
// import DashboardJobsList from "../../components/DashboardJobsList"
import PaymentModal from "../../components/PaymentModal"
import { notify } from "../../helpers/notifyManager";
import DashboardPaymentList from "../../components/DashboardPaymentList";
// import Storage from "../../helpers/storage";

const Payment = () => {
    const [show, setShow] = useState(false);
    const [showPage, setShowPage] = useState(true);
    const [paymentList, setPaymentList] = useState([]);
    const [errors, setErrors] = useState({});



    const [values, setValues] = useState({
        file_id: "",
        amount_paid: "",
        payment_type: "",
        currency_id: "",
        receiver_amount: "",
        organization_amount: "",
        account_type: ""
    });

    useEffect(() => {
        getPaymentList()
    }, []);


    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const getPaymentList = async () => {
        let res = await PaymentService.getPayment()
        setPaymentList(res.data)
        setShowPage(true)
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        let res = await PaymentService.postPayment(values);

        if (!res) {
            return;
        }
        if (typeof res == "object" && res.status !== "success") {
            setErrors(res);
        } else {
            notify("success", res.message);
            handleClose();
            await getPaymentList();
        }
    }

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="campaigns-card">
                                    <div className="title-box d-flex justify-content-between">
                                        <div className="title-left">
                                            <h2>Payment List</h2>
                                        </div>
                                        <div className="title-right">
                                            <button className={'cs-btn-success'} onClick={handleShow}>Add new Payment</button>
                                        </div>
                                    </div>

                                    <PaymentModal
                                        handleClose={handleClose}
                                        handleSubmit={handleSubmit}
                                        handleChange={handleChange}
                                        values={values}
                                        show={show}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                             <DashboardPaymentList paymentList={paymentList} />
                        </div>
                    </div>

                )}
        </>
    );
}

export default Payment
