import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Errors from "../Errors";
import SearchSelect from "../../components/Select"

const AfiliatorModal = ({
    handleClose,
    handleSubmit,
    handleChange,
    values,
    show,
    errors,
    options
}) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create Client Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Name<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="john Doe"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Email<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="hello@gmail.com"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Phone No<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="01XXXXXXXXX"
                                    name="phone_no"
                                    value={values.phone_no}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="5001, Dhaka
                                    profession:Engineer
                                    nationality:29"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Profession<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Engineer"
                                    name="profession"
                                    value={values.profession}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Nationality</label>
                                <SearchSelect UniqueId="nationality" SearchList={options} handleChange={handleChange} SelectedItem={values.nationality} />
                            </div>
                        </div>
                    </div>
                    <Errors errorList={errors} />
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="float-right">
                            <Button className="button-modal-close cs-btn-remove" onClick={handleClose}>
                                Close
                </Button>
                            <Button type="submit" onSubmit={handleSubmit}
                                className={'button-modal-submit cs-btn-success'}>Submit</Button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AfiliatorModal