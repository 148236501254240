import React from "react";
import "./style.css";
import Navbar from "../../components/CommonNavbar"

const Affiliator = () => {
    return (
        <div
            className="affiliator--page-wrapper"
            style={{ backgroundImage: 'url("./images/option3.svg")' }}
        >
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="affiliator--content-container text-center">
                            <h2>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit!
                            </h2>
                            <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Ipsum accusamus optio unde
                                assumenda est minima voluptate sint! Magnam
                                fugit doloribus eos. Laboriosam, qui dolorum
                                rerum assumenda alias esse nisi amet.Ipsum
                                accusamus optio unde assumenda est minima
                                voluptate sint! Magnam fugit doloribus eos.
                                Laboriosam, qui dolorum rerum assumenda alias
                                esse nisi amet
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Affiliator
