const DataRequired = (values) => {
  let data = {}
  values.map(item => {
    for (let [key, value] of Object.entries(item)) {
      if (!data.hasOwnProperty(key)) {
        data[key] = []
      }
      data[key].push(value)
    }
  })
  return data
}

export default DataRequired
