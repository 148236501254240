import React from "react";
import CountryInfo from "../../helpers/CountryInfo";
import {commonAvatar} from "../../helpers";

const UserDetailsSidebar = ({data}) => {
    return (
        <div className="col-lg-4 col-xl-4 col-md-5">
            <div className="card-box text-center custom--card-box">
                <img
                    src={data.avatar ? data.avatar.source_path : commonAvatar()}
                    className="rounded-circle avatar-lg img-thumbnail"
                />
                <h4 className="mb-0 mt-2">
                    {data.name}
                </h4>
                <p className="text-muted font-13 mb-4 mt-2">
                    {data.career_objective}
                </p>
                {/*<button className={'cs-btn-success'} onClick={() => {history.push('/user/join')}}>Edit Profile</button>*/}
                <div className="text-left mt-3">
                    <p className="text-muted mb-2 font-13">
                        <strong>KYC FRONT :</strong>
                        {
                            data.kyc_front &&
                            <span className="ml-2">
                                <a target="_blank" rel="noopener noreferrer" href={data.kyc_front.source_path}>Click Here</a>
                            </span>
                        }

                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>KYC BACK :</strong>
                        {data.kyc_back &&
                        <span className="ml-2">
                            <a target="_blank" rel="noopener noreferrer" href={data.kyc_back.source_path}>Click Here</a>
                        </span>}

                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Last Name :</strong>
                        <span className="ml-2">
                            {data.name}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Email :</strong>
                        <span className="ml-2">{data.email}</span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Mobile :</strong>
                        <span className="ml-2">
                            {data.phone_no}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Date of Birth :</strong>
                        <span className="ml-2">
                            {data.date_of_birth}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Nationality :</strong>
                        <span className="ml-2">
                            {data.nationality ? CountryInfo(data.nationality).country_name: ''}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Passport No :</strong>
                        <span className="ml-2">{data.passport_no}</span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Date of Expire :</strong>
                        <span className="ml-2">
                            {data.date_of_expire}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Profession :</strong>
                        <span className="ml-2">
                            {data.profession}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Portfolio Link :</strong>
                        <span className="ml-2">
                            {data.website}
                        </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                        <strong>Address :</strong>
                        <span className="ml-2">
                            {data.address}
                        </span>
                    </p>
                </div>
            </div>
            {/* end card-box */}
        </div>
    );
};

export default UserDetailsSidebar;
