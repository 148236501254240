import { catcher } from "../helpers"
import apiCall from "../helpers/apicall"
import { client } from "../helpers/client"

const FileService = {
    async saveFile(data) {
        let api = apiCall("post-file");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchFiles(query) {
        let api = apiCall("get-files", query);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchFilesById(id) {
        let api = apiCall("get-files-by-id", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async updateStatus(id, data) {
        let api = apiCall("update-files-by-id", {}, id);
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async saveFileAsPdf(id) {
        let api = apiCall("save-file-as-pdf", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async sendPaymentRequestToAdmin(id, type) {
        let api = apiCall("file-payment-request", {}, id, type);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    }
}

export default FileService