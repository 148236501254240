import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import BannerTextService from "../../services/BannerTextService"
import BannerTextModal from "../../components/BannerTextModal"
import BannerTextList from "../../components/BannerTextList"
import { notify } from "../../helpers/notifyManager";


const BannerText = () => {
    const [show, setShow] = useState(false);
    const [showPage, setShowPage] = useState(true);
    const [errors, setErrors] = useState({});
    const [bannerText, setBannerText] = useState([]);

    const [values, setValues] = useState({
        type: "",
        url: "",
        name: ""
    });

    useEffect(() => {
        fetchBannerInfo()
        console.log("data", bannerText)
    }, []);

    const fetchBannerInfo = async () => {
        let response = await BannerTextService.fetchBannerText();
        if (response) {
            setBannerText(response);
            setShowPage(true);
        }
    };

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleUpdate = async (id) => {
        let res = await BannerTextService.deleteBannerText(id);


        if (typeof res == "object" && res.status !== "success") {
            setErrors(res);
        } else {
            notify("success", res.message);
            await fetchBannerInfo();
        }

    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        let res = await BannerTextService.postBannerText(values);

        if (!res) {
            return;
        }

        notify("success", res.message);
        handleClose();
        await fetchBannerInfo();

    }

    return (
        <>
            {!showPage && bannerText.length <= 0 ? (
                <Loader />
            ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="campaigns-card">
                                    <div className="title-box d-flex justify-content-between">
                                        <div className="title-left">
                                            <h2>Banner Text List</h2>
                                        </div>
                                        <div className="title-right">
                                            <button className={'cs-btn-success'} onClick={handleShow}>Add New Text</button>
                                        </div>
                                    </div>

                                    <BannerTextModal
                                        handleClose={handleClose}
                                        handleSubmit={handleSubmit}
                                        handleChange={handleChange}
                                        values={values}
                                        show={show}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <BannerTextList BannerText={bannerText} handleUpdate={handleUpdate} />
                        </div>
                    </div>

                )}
        </>
    );
}

export default BannerText
