import React from 'react';
import DateManager from "../../../helpers/DateManager";
import DataNotFound from "../../DataNotFound";
import CountryInfo from "../../../helpers/CountryInfo";

const ProfessionalTab = ({data}) => {
    return (
        <div className="tab-pane show active" id="professional">
            <h5 className="mb-4 mt-2 text-uppercase card-title">Experience</h5>
            {data && data.length > 0 && data[0].info_type === 'profession' ? data.map((value, key) => {
                return (
                    <ul className="list-unstyled timeline-sm" key={key}>
                        <li className="timeline-sm-item">
                            <span className="timeline-sm-date">{DateManager(value.start_date, 'y')} - {DateManager(value.end_date, 'y')}</span>
                            <h5 className="mt-0 mb-1">{value.title}</h5>
                            <p className="cs--text-color">{value.institution}</p>
                            <p className="text-muted mt-2">
                                {value.description}
                            </p>
                            <span><b>Country :</b> {CountryInfo(value.location).country_name}</span>
                        </li>
                    </ul>
                )
            }) : <DataNotFound />}

        </div>
    );
}

export default ProfessionalTab
