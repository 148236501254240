import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import DashboardFaqsList from "../../components/DasboardFaqsList"
import FaqsModal from "../../components/FaqsModal"
import { notify } from "../../helpers/notifyManager";
import FaqsService from '../../services/FaqsService';

const DashboardFaqs = () => {

  const [show, setShow] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [faqsList, setFaqsList] = useState([]);
  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({
    question: "",
    answer: "",
  });

  useEffect(() => {
    fetchFaqs()
  }, []);


  const fetchFaqs = async () => {
    let faqsList = await FaqsService.fetchFaqs();
    setFaqsList(faqsList)
    setShowPage(true)
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  const handleUpdate = (id) => {
    let faqs = Object.assign(
      {},
      faqsList.find((value) => {
        return value.id == id;
      }),
    );
    console.log("update id", id)
    console.log("update", faqs)
    setValues(faqs);
    handleShow();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };


  const handleDelete = async (id) => {
    // event.preventDefault();
    let res;
    res = await FaqsService.deleteFaqs(id);

    if (typeof res == "object" && res.status !== "success") {
      setErrors(res);
    } else {
      notify("success", res.message);
      handleClose();
      await fetchFaqs();
    }

  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    let res;
    if (values.id) {
      res = await FaqsService.updateFaqs(
        values,
        values.id,
      );
    } else {
      res = await FaqsService.postFaqs(values);
    }

    if (!res) {
      return;
    }

    if (typeof res == "object" && res.status !== "success") {
      setErrors(res);
    } else {
      notify("success", res.message);
      handleClose();
      await fetchFaqs();
    }
  }


  return (
    <>
      {!showPage ? (
        <Loader />
      ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="campaigns-card">
                  <div className="title-box d-flex justify-content-between">
                    <div className="title-left">
                      <h2>FAQ List</h2>
                    </div>
                    <div className="title-right">
                      <button className={'cs-btn-success'} onClick={handleShow}>Add New Faqs</button>
                    </div>
                  </div>

                  <FaqsModal
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    show={show}
                    errors={errors}
                  />

                </div>
              </div>
              <DashboardFaqsList faqsList={faqsList} handleUpdate={handleUpdate} handleDelete={handleDelete} />
            </div>
          </div>

        )}
    </>
  );
}

export default DashboardFaqs
