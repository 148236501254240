import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import "./style.css";
import {FB_APP_ID, GOOGLE_API_URL} from "../../config";
import FormInput from "../../components/Input";
import { Link } from "react-router-dom";
import Storage from "../../helpers/storage";
import {
    TOKEN_NAME,
    REGULAR_LOGIN,
    CLIENT_ROLE,
    PROVIDER_GOOGLE, PROVIDER_FACEBOOK,
} from "../../constants/Auth";
import Loader from "../../components/Spinner";
import AuthService from "../../services/AuthService";
import LoginSignup from "../../layouts/loginSignup";
import Errors from "../../components/Errors";
import Navbar from "../../components/CommonNavbar"
import Modal from "react-bootstrap/Modal";
import FacebookLogin from "react-facebook-login";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare} from "@fortawesome/free-solid-svg-icons";

const SignUp = (props) => {
    const [showPage, setShowPage] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        type: REGULAR_LOGIN,
        role: CLIENT_ROLE,
    });
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (Storage.has(TOKEN_NAME)) {
            props.history.push("/");
        }
        setShowPage(true);
    }, [props.history]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let res = await AuthService.signup(values);
        if (res === true) {
            const user = await Storage.get('user')
            if (user.roles.length < 1) {
                props.history.push("/user/profile/client");
            } else {
                props.history.push("/dashboard");
            }
        } else if (typeof res == "object") {
            setErrors(res);
        }
    };

    const responseFacebook = async (response) => {
        let data = {role: CLIENT_ROLE};
        let res = await AuthService.socialProvider(
            PROVIDER_FACEBOOK,
            response,
            data
        );
        if (res === true) {
            props.history.push("/dashboard");
        } else if (typeof res == "object") {
            setErrors(res);
        }
    }

    const responseGoogle = async (response) => {
        let res = await AuthService.socialProvider(
            PROVIDER_GOOGLE,
            response,
            values
        );
        if (res === true) {
            props.history.push("/dashboard");
        } else if (typeof res == "object") {
            setErrors(res);
        }
    };
    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <LoginSignup>
                    <Navbar />
                    <div className="login-card">
                        <h2 className="login-title">Welcome to</h2>
                        <FacebookLogin
                            textButton="Log in With Facebook"
                            size={"medium"}
                            cssClass="external-login"
                            appId={FB_APP_ID}
                            autoLoad={false}
                            fields="name,email"
                            // callback={responseFacebook}
                        />
                        <GoogleLogin
                            className="external-login"
                            clientId={GOOGLE_API_URL}
                            autoLoad={false}
                            buttonText="Signup in With Google"
                            // onSuccess={responseGoogle}
                            // onFailure={responseGoogle}
                        />
                        <div>
                            <span className="divider">Or</span>
                        </div>
                        <div className="email--signup-container">
                            <form className="signup--form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <FormInput
                                        type="text"
                                        className="form-control"
                                        placeholder="Full name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <FormInput
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <FormInput
                                        type="password"
                                        className="form-control"
                                        placeholder="Password (min. 6 characters)"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <FormInput
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        name="password_confirmation"
                                        value={values.password_confirmation}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="row">
                                    <input id="checkbox" type="checkbox" required style={{
                                        'height': "1em",
                                        'width': "1em",
                                        'verticalAlign': "middle",
                                        "marginRight": "1em"
                                    }} />
                                    <label htmlFor="checkbox"> I agree to these <span style={{
                                        'cursor': 'pointer',
                                        'textDecorationLine': 'underline',
                                        'color': 'blue',
                                        'textDecorationStyle': 'solid'
                                    }}
                                        onClick={() => {
                                            setShow(true)
                                        }}>Terms of Service, Privacy Policy</span><span style={{
                                            'cursor': 'pointer',
                                            'textDecorationLine': 'underline',
                                            'color': 'blue',
                                            'textDecorationStyle': 'solid'
                                        }}>&</span><span style={{
                                            'cursor': 'pointer',
                                            'textDecorationLine': 'underline',
                                            'color': 'blue',
                                            'textDecorationStyle': 'solid'
                                        }}
                                            onClick={() => {
                                                setShow(true)
                                            }}>Cookies</span></label>
                                </div>
                                <Errors errorList={errors} />
                                <button
                                    type="submit"
                                    className="cs-btn btn-success form-control btn--custom-signin"
                                >
                                    Sign Up
                                </button>
                            </form>

                            <p className="have--account-title">
                                Already have an account? <Link to="/login">Log in </Link>
                            </p>
                        </div>
                        <Modal
                            size="md"
                            show={show}
                            onHide={() => {
                                setShow(false)
                            }}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Terms of Services & Privacy Policy</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-xs-10 align-content-center">
                                    <div className="content">
                                        <h4 className="section-title"><Link to={'/terms-of-service'}> Terms of service</Link></h4>
                                        <h4 className="section-title"><Link to={'/privacy-policy'}> Privacy Policy</Link></h4>
                                        <h4 className="section-title"><Link to={'/faq'}> FAQ´s</Link></h4>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </LoginSignup>
            )}
        </>
    );
};
export default SignUp;
