import React, { useEffect } from 'react';
import { Link } from "react-router-dom"
import SearchSelect from "../../components/Select"
import { TYPES } from "../../constants/VisaCategory";

const HomeBanner = ({ SearchKey, country, handleChange, values, removeMenu }) => {


    return (

        <div className="ft_banner_wrapper" onClick={removeMenu}>
            <div className="container">

                <div className="row ">
                    <div className="col-md-6 content-position">
                        <h1 className="banner-title"> Easy way to build your career in abroad</h1>
                        <div className="form-group mt-4 mb-5 fixtotop" id="scrolled-top">
                            <div className="row new-top">
                                <div className="col-md-6 column-4">
                                    <div className="form-group">
                                        <SearchSelect UniqueId="location" SearchList={country}
                                            handleChange={handleChange} SelectedItem={values.location} />
                                    </div>
                                </div>
                                <div className="col-md-6 column-4">
                                    <div className="form-group">
                                        <select className="form-control"
                                            id="visa_category_id"
                                            value={values.visa_category_id}
                                            name="visa_category_id"
                                            onChange={handleChange}>
                                            <option>Select Category</option>
                                            {Object.keys(TYPES).map(value => {
                                                return (
                                                    <option value={value} key={value}>{TYPES[value]}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 column-4">
                                    <a href={`/agents?country_id=${values.location}&category_id=${values.visa_category_id}`}
                                        className="border-default btn--find-jobs">Find Supervisor</a>
                                </div>

                            </div>

                        </div>
                        <div className="job--searching-list mt-4">
                            <ul>
                                <li> <h6 className="mr-2">Trending Keywords:</h6></li>
                                {SearchKey && SearchKey.map((item, key) => {
                                    return (
                                        <li key={key}><Link to={{ pathname: `${item.url}` }} target="_blank" rel="noopener noreferrer">{item.name}</Link>&nbsp; &nbsp;</li>
                                    )
                                })}
                            </ul>

                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="ft_banner_right d-none d-sm-none d-md-none d-lg-none d-xl-block">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeBanner