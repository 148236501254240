import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import CountryInfo from "../../helpers/CountryInfo";
import { Dropdown } from "react-bootstrap";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";

const DashboardJobsList = ({ jobsList, handleUpdate, handleDelete }) => {
    return (
        <div className="col-lg-12">
            <div className="table-responsive-sm">
                <div className={jobsList && jobsList.length > 0 ? "table-table-head align-items-center" : "none"}>
                    <div className="column-head w-20">Title</div>
                    <div className="column-head w-15">Country</div>
                    <div className="column-head w-15">Experience</div>
                    <div className="column-head w-15">Speciality</div>
                    <div className="column-head w-15">Salary</div>
                    <div className="column-head w-15">Website</div>
                    <div className="column-head w-5">Action</div>
                </div>
                {jobsList && jobsList.map((job, key) => {
                    return (
                        <div
                            className="table--content-box align-items-center"
                            key={key}
                        >
                            <div className="column w-20">{job.name}</div>
                            <div className="column w-15">
                                {job.country_id
                                    ? CountryInfo(job.country_id).country_name
                                    : " "}
                            </div>
                            <div className="column text-primary w-15">
                                {job.experience} Years
                            </div>
                            <div className="column text-success w-15">
                                {job.speciality.name}
                            </div>
                            <div className="column text-warning w-15">
                                {job.salary}
                            </div>
                            <div className="column w-15">
                                <Link
                                    to={job.website}
                                    target="_blank"
                                    className="text-primary"
                                    rel="noopener noreferrer"
                                >
                                    Visit Website
                                </Link>
                            </div>
                            <div className="column w-5">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <span
                                            className="text-primary"
                                            data-toggle="dropdown"
                                        >
                                            <FontAwesomeIcon icon={faCog} />{" "}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => handleUpdate(job.id)}
                                        >
                                            <FontAwesomeIcon
                                                className="text-primary fa-1x"
                                                icon={faWrench}
                                            />{" "}
                                            Update
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(job.id) }}
                                        >
                                            <FontAwesomeIcon
                                                className="text-primary fa-1x"
                                                icon={faTrash}
                                            />{" "}
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DashboardJobsList;
