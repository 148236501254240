import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import Loader from "../../components/Spinner";
import UserInfo from "../../services/UserInfo";

const NotificationList = () => {
    const [showPage, setShowPage] = useState(false);
    const [notifications, setNotifications] = useState(false);

    useEffect(() => {
        getAllNotification()
        markAllAsRead()
    }, []);

    const getAllNotification = async () => {
        let notifications = await UserInfo.notificationList()
        setNotifications(notifications.data)
        setShowPage(true)
    }

    const markAllAsRead = async () => {
        let notifications = await UserInfo.markNotificationRead()
    }

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="campaigns-card">
                                <div className="title-box d-flex justify-content-between">
                                    <div className="title-left">
                                        <h2>Notification List</h2>
                                    </div>
                                    <div className="title-right">
                                        {/*<button className={'cs-btn-success'} onClick={markAllAsRead}>Mark All as Read</button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="table-responsive-sm align-content-between">
                                <div className={notifications && notifications.length > 1 ? "table-table-head align-items-center" : "none"}>
                                    <div className="column-head w-30">Title</div>
                                    <div className="column-head w-65">Message</div>

                                </div>
                                {notifications && notifications.map((notification, key) => {
                                    return (
                                        <div
                                            style={notification.read_at == null ? {"background": "#F4F4F4"}: {}}
                                            className="table--content-box align-items-center"
                                            key={key}
                                        >
                                            <div className="column w-30">{notification.data.title}</div>
                                            <div dangerouslySetInnerHTML={{ __html: notification.data.message }} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
}

export default NotificationList
