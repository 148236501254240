import React, { useEffect, useState } from "react";
import "./style.css";
import Multistep from "../../../layouts/multistep";
import CountryInfo from "../../../services/CountryInfo";
import Loader from "../../../components/Spinner";
import UserInfo from "../../../services/UserInfo";
import { produce } from "immer";
import DataRequired from "../../../helpers/dataRequired";
import RemoveItem from "../../../helpers/removeItem";
import AgentInformation from "../../../components/AgentInfomation"
import Errors from "../../../components/Errors";
import history from "../../../routes/history";
import { notify } from "../../../helpers/notifyManager";
import Navbar from "../../../components/CommonNavbar";

let fields = {
    country_id: "",
    visa_category_id: "",
    fee: "",
    description: "",
    responsibility: "",
    responsibilities: [],
    is_enabled: true,
    non_refundable_fee: "",
    processing_time: ""
}

const AgentForm = (props) => {
    const [values, setValues] = useState([fields]);
    const [country, setCountry] = useState([])
    const [showPage, setShowPage] = useState(false)
    const [errors, setErrors] = useState({});


    useEffect(() => {
        getCountry()
        setShowPage(true)
        getAgentInfo()
    }, []);

    useEffect(() => {

        if (showPage) {
            document.getElementById("show-number").innerHTML = "Step 4 - 4";
            document.getElementById("completed-4").className += " completed";
            view();
        }
    }, [showPage])

    const view = () => {
        if (values.length === 1) {
            return (document.getElementById("removeBtn").style.display =
                "none");
        }
    };

    const getCountry = async () => {
        let response = await CountryInfo.country();
        if (response) {
            setCountry(response.data);
        }
    };

    const getAgentInfo = async () => {
        let response = await UserInfo.getAgentInfo();
        if (response.length > 0) {
            setValues(response);
        }
    };

    const handleChange = (event, key = null) => {
        let value = ""
        let id = ""
        if (key == null) {
            value = event.target.value;
            id = event.target.id
        }
        else {
            value = event.hasOwnProperty("value") ?  event.value : event
            id = key
        }
        let arr = [];
        arr = id.split("-");
        if (arr[0] == 'is_enabled') {
            value == false ? value = 1 : value = 0
        }
        if (arr[0] == 'description') {
            value = event
        }
        setValues((values) =>
            produce(values, (v) => {
                v[arr[1]][arr[0]] = value;
            })
        );
    };

    const handleResponsibilityChange = (event) => {
        const { value, id } = event.target;

        let arr = [];
        arr = id.split("-");

        setValues((values) =>
            produce(values, (v) => {
                if (!v[arr[1]]['responsibilities'].includes(value)) {
                    v[arr[1]]['responsibilities'].push(value)
                }
            })
        );
    };

    const handleResponsibilityRemove = (value, key) => {
        setValues((values) =>
            produce(values, (v) => {
                v[key]['responsibilities'].splice(v[key]['responsibilities'].findIndex(item => item === value), 1);
            })
        )
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let res = await UserInfo.saveAgentInfo(DataRequired(values));

        if (typeof res == "object" && !res.hasOwnProperty("data")) {
            setErrors(res);
        } else if (res.hasOwnProperty("data") && res.status == "success") {
            history.push("/dashboard");
            notify('success', 'Successfully updated your information')
        }
    };

    const addMoreAgentInfo = (e) => {
        setValues([...values, fields]);
    };

    const remove = async (event, itemKey, id) => {
        event.preventDefault();
        if (id) {
            await UserInfo.deleteAgentInfo(id);
        }
        setValues(RemoveItem(itemKey, values));
    };

    return (
        <>
            <Navbar />
            {!showPage ? (
                <Loader />
            ) : (
                <Multistep formType={props.formType}>
                    <div className="col-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <h4 className="form-title mb-4">Supervisor information</h4>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="agent--info-wrapper">
                                    <div className="row mb-4">
                                        {values.map((item, key) => {
                                            return (
                                                <AgentInformation
                                                    key={key}
                                                    handleChange={handleChange}
                                                    itemKey={key}
                                                    item={item}
                                                    remove={remove}
                                                    country={country}
                                                    handleResponsibilityChange={handleResponsibilityChange}
                                                    handleResponsibilityRemove={handleResponsibilityRemove}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                                <Errors errorList={errors} />
                                <div className="row mb-4">
                                    <div className="col-lg-12 col-md-12">
                                        <p className="cs-btn btn--add-more" id="btn--more-agentinfo"
                                            onClick={addMoreAgentInfo}>
                                            + add more
                                        </p>
                                    </div>
                                </div>
                                <div className="nextprevious--btn-container">
                                    <div className="previous-btn-container">
                                        <button
                                            type="button"
                                            className="cs-btn btn-next-previous btn--previous-ms"
                                            id="prevBtn"
                                            onClick={props.prevStep}
                                        >
                                            Previous
                                        </button>
                                    </div>
                                    <div className="next-btn-container">
                                        <button
                                            type="submit"
                                            className="cs-btn btn-next-previous btn--next-ms"
                                            id="nextBtn"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Multistep>
            )}
        </>
    )
}
export default AgentForm;
