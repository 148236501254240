import React, { useEffect, useState } from "react";
import HomeBanner from "../../components/HomeBanner"
import PostJob from "../../components/PostJob"
import PowerUpSearch from "../../components/PowerUpSearch"
import OurServices from "../../components/OurServices"
import LatestPosts from "../../components/LatestPosts"
import HomeFooter from "../../components/HomeFooter"
import MenuBar from "../../components/MenuBar";
import TrandingSearch from "../../components/TrandingSearch"
import BannerTextService from "../../services/BannerTextService"
import Loader from "../../components/Spinner";
import CountryInfo from "../../services/CountryInfo"

const Home = () => {
    const [showPage, setShowPage] = useState(false);
    const [bannerText, setBannerText] = useState([])
    const [searchKey, setSearchKey] = useState([])
    const [country, setCountry] = useState([])
    const [values, setValues] = useState({
        location: "",
        visa_category_id: ""
    });

    useEffect(() => {
        fetchBannerInfo()
        getCountry()

    }, [])

    const getCountry = async () => {
        let response = await CountryInfo.supervisorCountry();
        if (response) {
            setCountry(response.data.map(country => {
                return (
                    {
                        label: country.country_name,
                        value: country.id
                    }
                )
            }));
        }
    };

    const fetchBannerInfo = async () => {
        let response = await BannerTextService.fetchBannerText();
        if (response) {
            let banner = response.filter(item => item.type === 1);
            let searchKey = response.filter(item => item.type === 2);
            setBannerText(banner);
            setSearchKey(searchKey);
            setShowPage(true)
        }
    };

    const handleChange = (event, key = null) => {
        let value = ""
        let name = ""
        if (key == null) {
            value = event.target.value;
            name = event.target.name
        } else {
            value = event.value
            name = key
        }

        setValues({
            ...values,
            [name]: value,
        });
    };

    const removeMenu = () => {

        if (document.getElementsByClassName("menu-items block")) {
            document.getElementById("menubar").classList.remove("block");
        }
    }



    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="home-wrapper">
                    <MenuBar />
                    {
                        <HomeBanner SearchKey={searchKey} country={country} handleChange={handleChange} values={values} removeMenu={removeMenu} />
                    }
                    {/* <div> hello</div> */}
                    <TrandingSearch BannerText={bannerText} removeMenu={removeMenu} />
                    <PowerUpSearch removeMenu={removeMenu} />
                    <OurServices removeMenu={removeMenu} />
                    <PostJob removeMenu={removeMenu} />
                    {/* <Testimonial /> */}
                    <LatestPosts removeMenu={removeMenu} />
                    <HomeFooter removeMenu={removeMenu} />
                </div>
            )}
        </>
    )
};

export default Home;