import React from 'react';

const ChatHead = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">Chat</h4>
        </div>
      </div>
    </div>
  );
}

export default ChatHead
