import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import FaqsService from "../../services/FaqsService";
import Loader from "../../components/Spinner";
import Navbar from "../../components/CommonNavbar"

const Faq = () => {
    const [faq, setFaq] = useState([]);
    const [showPage, setShowPage] = useState(false);

    useEffect(() => {
        getFaqs()
    }, []);

    const openQuestion = (id) => {
        let accordions = document.getElementsByClassName("accordion-item");

        for (let i = 0; i < accordions.length; i++) {
            if (accordions[i].classList.contains("open")) {
                accordions[i].classList.remove("open")
                accordions[i].classList.add("closed")
            }
        }

        let accordion = document.getElementById(id);
        accordion.classList.remove("closed")
        accordion.classList.add("open")
    }

    const getFaqs = async () => {
        let res = await FaqsService.fetchFaqs();
        setFaq(res)
        setShowPage(true)

    };

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                    <>
                        <div className="fags-wrapper">
                            <Navbar />
                            <div className="container extra-space">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="fag--questions-wrapper">
                                            <h1 className="text-center">Popular Question</h1>
                                            <div className="fag--questions-container">
                                                {faq.map((faq, key) => {
                                                    return (
                                                        <div key={key} id={key} className={`accordion-item closed`}
                                                            onClick={() => openQuestion(key)}>
                                                            <div className="accordion--item-heading odd-heading">
                                                                <h4 className="pr-5">
                                                                    {faq.question}
                                                                </h4>
                                                                <FontAwesomeIcon icon={faAngleDown}
                                                                    className="icon-animation" />
                                                            </div>
                                                            <div className="accordion--item-content">
                                                                <p>
                                                                    {faq.answer}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                            <div className="fag--questions-outer">
                                                <div className="faq--socialbtn-container text-uppercase">

                                                    <a href={'/'}
                                                        className="faq--outer-btn faq--btn-social mr-3"><i
                                                            className="fa fa-twitter faq--btn-icon bg-twitter"
                                                            aria-hidden="true" /><span><img src="./images/twitter.svg" style={{ width: "25px", paddingRight: "7px" }} /></span>Tweet Us</a>
                                                    <a href={'/'} className="faq--outer-btn faq--btn-social"><i
                                                        className="fa fa-facebook-official faq--btn-icon bg-facebook"
                                                        aria-hidden="true" /><span><img src="./images/facebook.svg" style={{ width: "25px", paddingRight: "7px" }} /></span>Ask on facebook</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </>
    );
}

export default Faq
