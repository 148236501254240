import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import ReactQuill from 'react-quill';
import Errors from "../Errors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {LIST} from "../../constants/AgentResponsibilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const ScholarshipModal = ({
                              handleClose,
                              handleSubmit,
                              handleChange,
                              values,
                              country,
                              handleDateChange,
                              description,
                              setDescription,
                              show,
                              errors,
                              handleResponsibilityChange,
                              handleResponsibilityRemove,
                              showSupervisorForm
                          }) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Scholarships</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Name<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Scholarship Name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Field Of Study<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="example: Computer Science"
                                    name="institute"
                                    value={values.institute}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Type<span style={{ 'color': 'red' }}>*</span></label>
                                <select
                                    className="form-control"
                                    name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select</option>
                                    <option value="undergraduate">Undergraduate</option>
                                    <option value="course">Course</option>
                                    <option value="masters">Masters</option>
                                    <option value="PHD">Ph.D.</option>
                                    <option value="diploma">Diploma</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Website<span style={{ 'color': 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="http://hello.com"
                                    name="website"
                                    value={values.website}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{position: "relative", zIndex: "999"}}>
                            <div className="form-group">
                                <label>Last Date</label>
                                {/* <CalendarPicker
                      variable={values.last_date}
                      value={values.last_date}
                      name="last_date"
                      onChangeHandler={handleDateChange}
                  /> */}
                                <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                                            selected={values.last_date}
                                            name="last_date"
                                            onChange={date => handleDateChange(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Country</label>
                                <select
                                    className="form-control"
                                    placeholder="Enter Country"
                                    name="country_id"
                                    value={values.country_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Country</option>
                                    {country.map((country) => {
                                        return (
                                            <option
                                                key={country.id}
                                                value={country.id}
                                            >
                                                {country.country_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>Description</label>
                                <ReactQuill theme="snow" value={description} onChange={setDescription} placeholder={'Maximum 250 words'}/>
                            </div>
                        </div>
                    </div>
                    {
                        showSupervisorForm ?
                            <div className="row mb-4">
                                <h3 className="card-title ml-2">
                                    Add your supervising Information
                                </h3>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Fee(Taka)</label>
                                        <input type="number"
                                               className="form-control"
                                               id="fees"
                                               value={values.fee}
                                               name="fee"
                                               onChange={handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Non refundable fee (Taka)</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="non_refundable_fee"
                                            value={values.non_refundable_fee}
                                            name="non_refundable_fee"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Responsibility</label>
                                        <select
                                            id="responsibility"
                                            value={values.responsibilities}
                                            name="responsibility"
                                            className="form-control"
                                            onChange={handleResponsibilityChange}
                                        >
                                            <option>Select Responsibilities</option>
                                            {Object.keys(LIST).map((value, key) => {

                                                return (
                                                    <option value={LIST[value]} key={key}>{LIST[value]}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Responsibilities</label>
                                        {values.responsibilities && values.responsibilities.map((value, key) => {
                                            return (
                                                <div className="input-group mb-3" key={key}>
                                                    <input type="text" className="form-control"
                                                           id="responsibilities"
                                                           name="responsibilities"
                                                           value={value == "others" ? " " : value}
                                                           readOnly={value == "others" ? false : true}
                                                    />
                                                    <div className="input-group-append">
                                    <span style={{cursor: 'pointer'}} className="input-group-text"
                                          onClick={(e) => handleResponsibilityRemove(value)}>
                                        <FontAwesomeIcon icon={faTrash} style={{color: 'red'}}/>
                                    </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Processing Time (Months)</label>
                                        <input type="number"
                                               className="form-control"
                                               placeholder="2"
                                               id="processing_time"
                                               value={values.processing_time}
                                               name="processing_time"
                                               onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div> :
                            <span> To update your supervising information <Link to={'/user/profile/agent'}>Click Here </Link>  </span>
                    }

                    <Errors errorList={errors}/>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="float-right">
                            <Button className="button-modal-close cs-btn-remove" onClick={handleClose}>
                                Close
                            </Button>
                            <Button type="submit" onSubmit={handleSubmit}
                                    className={'button-modal-submit cs-btn-success'}>Submit</Button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default ScholarshipModal;
