import { toast } from "react-toastify";
import swal from "sweetalert";

export let notify = (type, message) => {
  if (type === "success") {
    toast.success(message);
  } else if (type === "error") {
    if (!message) message = "Something Went Wrong. Try Again!";
    toast.error(message);
  } else if (type === "warning") {
    toast.warning(message);
  } else {
    toast(message);
  }
};

export let confirm = (
  title = "Are you sure to delete?",
  text = "",
  type = "error",
  buttons = ["Cancel", "Delete"],
  dangerMode = true
) => {
  return swal({
    title: title,
    text: text,
    icon: type,
    buttons: buttons,
    dangerMode: dangerMode,
  });
};
