import React from 'react';
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import Navbar from "../../components/CommonNavbar"

const OurTeam = () => {
    return (
        <>
            <Navbar />
            <div className="aboutus-wrapper">
                <div className="container">
                    <div className="about--text-content">
                        <p>OurTeam</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurTeam;