import Alert from 'react-bootstrap/Alert'
import React from "react";

const ErrorList = ({ errorList }) => (
  <div style={{ textAlign: "left" }}>
    {errorList && Object.keys(errorList).length > 0 && (
      <Alert variant="danger">
        <Alert.Heading>You got an error!</Alert.Heading>
        <hr />
        {Object.keys(errorList) && Object.keys(errorList).map((error, key) => (
          <ul
            key={key}
            id={key}
            style={{ padding: "0 0 0 .4rem", marginBottom: "0.3rem" }}
          >
            <li>
              <span>
                {error} : {errorList[error]}
              </span>
            </li>
          </ul>
        ))}
      </Alert>
    )}
  </div>
);

export default ErrorList
