import axios from "axios"

export const client = {
    get(url, header) {
        return axios({
            headers: header,
            method: "GET",
            url: url,
        });
    },
    post(url, header, data = {}) {
        return axios({
            headers: header,
            method: "POST",
            url: url,
            data: data,
        });
    },
    put(url, header, data = {}) {
        return axios({
            headers: header,
            method: "PUT",
            url: url,
            data: data,
        });
    },
    delete(url, header) {
        return axios({
            headers: header,
            method: "DELETE",
            url: url,

        });
    },
    async fetch(url, method = "POST", header, data) {
        try {
            const response = await fetch(url, {
                method: method,
                headers: header,
                body: JSON.stringify(data),
            });
            const responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    },
};
