import React, {useEffect, useState} from 'react';
import AccordionContent from "../AccordionContent"
import AgentTab from "../TabContent/AgentTab"
import ProfessionalTab from "../TabContent/ProfessionalTab"
import EducationalTab from "../TabContent/EducationalTab"
import CertificationTab from "../TabContent/CertificationTab"
import {USER_CERTIFICATION, USER_EDUCATION, USER_PROFESSION} from "../../constants/UserProfileInfo";

const UserDetailsAccordion = ({getUserDetailsOf, accordionPageInfo, authUser}) => {
    const [selectedTab, setSelectedTab] = useState(USER_PROFESSION);

    useEffect(() => {
        document.getElementById(USER_PROFESSION).classList.add("active")
    }, [])

    useEffect(() => {
        getUserDetailsOf(selectedTab)

    }, [selectedTab])

    const selectTab = (event) => {
        setSelectedTab(event.target.id)
        let elements = document.getElementsByClassName("active");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('active');
        }
        document.getElementById(event.target.id).classList.add("active")
    }

    return (
        <div className="col-lg-8 col-xl-8 col-md-7">
            <AccordionContent select={selectTab}/>
            <div className="card-box custom--card-box">
                <div className="tab-fixed">
                    {selectedTab === USER_PROFESSION ?
                        <ProfessionalTab data={accordionPageInfo}/> :
                        selectedTab === USER_EDUCATION ?
                            <EducationalTab data={accordionPageInfo}/> :
                            selectedTab === USER_CERTIFICATION ?
                                <CertificationTab data={accordionPageInfo}/> :
                                <AgentTab data={accordionPageInfo} authUser={authUser}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default UserDetailsAccordion
