import React from "react";
import { commonAvatar } from "../../helpers";

const ChatUsers = ({ conversations, authUser, conversationInfo }) => {
    return (
        <div className="col-xl-4 col-lg-4">
            <div className="card mb-3">
                <div className="card-body">
                    <div className="media mb-3">
                        {/*<img*/}
                        {/*    src={*/}
                        {/*        process.env.PUBLIC_URL +*/}
                        {/*        "/images/dashboard/users/user-1.jpg"*/}
                        {/*    }*/}
                        {/*    alt="user-image"*/}
                        {/*    className="rounded-circle"*/}
                        {/*    width={40}*/}
                        {/*/>*/}
                        {/*<div className="media-body">*/}
                        {/*    <h5 className="mt-0 mb-0 font-15">*/}
                        {/*        <a*/}
                        {/*            href="contacts-profile.html"*/}
                        {/*            className="text-reset"*/}
                        {/*        >*/}
                        {/*            Geneva McKnight*/}
                        {/*        </a>*/}
                        {/*    </h5>*/}
                        {/*    <p className="mt-1 mb-0 text-muted font-14">*/}
                        {/*        <small className="mdi mdi-circle text-success" />{" "}*/}
                        {/*        Online*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        <div>
                            <a href={"/"} className="text-reset font-20">
                                <i className="mdi mdi-cog-outline" />
                            </a>
                        </div>
                    </div>
                    {/* start search box */}
                    {/*<form className="search-bar mb-3">*/}
                    {/*    <div className="position-relative">*/}
                    {/*        <input*/}
                    {/*            type="text"*/}
                    {/*            className="form-control form-control-light"*/}
                    {/*            placeholder="People, groups & messages..."*/}
                    {/*        />*/}
                    {/*        <span className="mdi mdi-magnify" />*/}
                    {/*    </div>*/}
                    {/*</form>*/}
                    <h6 className="font-13 text-muted text-uppercase mb-2">
                        Contacts
                    </h6>
                    {/* users */}
                    <div className="row">
                        <div className="col" style={{
                            height: '375px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}>
                            <div
                                data-simplebar="init"
                                style={{ maxHeight: "375px" }}
                            >
                                <div
                                    className="simplebar-wrapper"
                                    style={{
                                        margin: "0px"
                                    }}
                                >
                                    <div className="simplebar-height-auto-observer-wrapper">
                                        <div className="simplebar-height-auto-observer" />
                                    </div>
                                    <div className="simplebar-mask">
                                        <div
                                            className="simplebar-offset"
                                            style={{
                                                right: "0px",
                                                bottom: "0px",
                                            }}
                                        >
                                            <div
                                                className="simplebar-content-wrapper"
                                                style={{
                                                    height: "auto",
                                                    overflow: "hidden scroll",
                                                }}
                                            >
                                                <div
                                                    className="simplebar-content"
                                                    style={{ padding: "0px" }}
                                                >
                                                    {conversations &&
                                                        conversations.map(
                                                            (
                                                                conversation,
                                                                index,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        onClick={() =>
                                                                            conversationInfo(
                                                                                conversation.id,
                                                                            )
                                                                        }
                                                                        className="text-body"
                                                                        key={
                                                                            index
                                                                        }
                                                                        style={{
                                                                            cursor:
                                                                                "pointer",
                                                                        }}
                                                                    >
                                                                        <div className="media p-2">
                                                                            <img
                                                                                src={
                                                                                    conversation.agent.id == authUser.user_id
                                                                                        ? conversation.client.avatar ? conversation.client.avatar.source_path : commonAvatar()
                                                                                        : conversation.agent.avatar ? conversation.agent.avatar.source_path : commonAvatar()

                                                                                }
                                                                                alt="user-image"
                                                                                className="rounded-circle mr-3"
                                                                                width={
                                                                                    40
                                                                                }
                                                                            />
                                                                            <div className="media-body">
                                                                                <h5 className="mt-0 mb-0 font-14 mr-0">
                                                                                    <span className="badge badge-warning float-right text-info font-weight-normal font-12">
                                                                                        {conversation.agent.id == authUser.user_id ? "you are supervisor" : "you are client"}
                                                                                    </span>
                                                                                    {conversation.agent.id == authUser.user_id
                                                                                        ? conversation.client.name
                                                                                        : conversation.agent.name}
                                                                                </h5>
                                                                                <p className="mt-1 mb-0 text-muted font-14">
                                                                                    <span className="w-75">
                                                                                        {conversation.last_message &&
                                                                                            conversation
                                                                                                .last_message
                                                                                                .message
                                                                                            ? conversation.last_message.message.substring(0, 40)
                                                                                            : ""}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="simplebar-placeholder"
                                        style={{
                                            width: "auto",
                                            height: "696px",
                                        }}
                                    />
                                </div>
                                <div
                                    className="simplebar-track simplebar-horizontal"
                                    style={{ visibility: "hidden" }}
                                >
                                    <div
                                        className="simplebar-scrollbar"
                                        style={{
                                            width: "0px",
                                            display: "none",
                                        }}
                                    />
                                </div>
                                <div
                                    className="simplebar-track simplebar-vertical"
                                    style={{ visibility: "visible" }}
                                >
                                    <div
                                        className="simplebar-scrollbar"
                                        style={{
                                            height: "202px",
                                            transform:
                                                "translate3d(0px, 4px, 0px)",
                                            display: "block",
                                        }}
                                    />
                                </div>
                            </div>
                            {/* end slim scroll*/}
                        </div>
                        {/* End col */}
                    </div>
                    {/* end users */}
                </div>
                {/* end card-body*/}
            </div>
            {/* end card*/}
        </div>
    );
};

export default ChatUsers;
