import React, { useEffect, useState } from "react";
import {Link, } from "react-router-dom";
import FormInput from "../../components/Input";
import "./style.css";
import {  TOKEN_NAME } from "../../constants/Auth";
import Storage from "../../helpers/storage";
import Loader from "../../components/Spinner";
import Errors from "../../components/Errors";
import LoginSignup from "../../layouts/loginSignup";
import Navbar from "../../components/CommonNavbar"
import AuthService from "../../services/AuthService";

const PasswordEmail = (props) => {
    const [showPage, setShowPage] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [credentials, setCredentials] = useState({
        email: "",
    });

    useEffect(() => {
        if (Storage.has(TOKEN_NAME)) {
            props.history.push("/");
        }
        setShowPage(true);

    }, [props.history]);

    const handleChange = ({ target }) => {
        setCredentials({
            ...credentials,
            [target.name]: target.value,
        });
    };

    const emailPassword = async (event) => {
        event.preventDefault()
        let res = await AuthService.emailPassword(credentials);
        if (res === true) {
            setSuccess(true)
        } else if (typeof res == "object") {
            setErrors(res);
        }

    };
    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <LoginSignup>
                    <Navbar />
                    <div className="login-card">
                        <h2 className="login-title">Reset Password</h2>
                        <div className="email--signup-container">
                            <form className="signup--form" onSubmit={emailPassword}>
                                <div className="form-group">
                                    <FormInput
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={credentials.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {
                                    success &&
                                    <div className="alert alert-success" role="alert">
                                        Password change instruction has been sent to your email. Please check your email.
                                    </div>
                                }

                                <Errors errorList={errors} />
                                <button
                                    type="submit"
                                    className="cs-btn btn-success form-control btn--custom-signin"
                                >
                                    Reset Password
                                </button>
                            </form>

                            <p className="have--account-title">
                                Don't have an account? <Link to="/signup">Sign Up</Link>
                            </p>
                        </div>
                    </div>
                </LoginSignup>
            )}
        </>
    );
};

export default PasswordEmail;
