import React, {useEffect, useState, useRef} from 'react';
import ChatHead from "../../components/ChatHead"
import ChatUsers from "../../components/ChatUsers"
import ChatArea from "../../components/ChatArea"
import ConversationService from "../../services/ConversationService";
import Storage from "../../helpers/storage";
import {notify} from "../../helpers/notifyManager";
import { ONGOING, SUCCESS} from "../../constants/FileStatus";



const Chat = () => {
    const isMounted = useRef(false);

    const [conversations, setConversations] = useState([]);
    const [authUser, setAuthUser] = useState({});
    const [selectedConversation, setSelectedConversation] = useState({});
    const [messages, setMessages] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [chatMessage, setChatMessage] = useState('');

    useEffect(() => {
        fetchConversations()
        userInfo()
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            addConversation()
        }else {
            isMounted.current = true;
        }

    }, [attachment])

    useEffect(() => {
        fetchMessages()
    }, [selectedConversation])

    const fetchConversations = async () => {
        const response = await ConversationService.fetchConversations()
        setConversations(response)
        setSelectedConversation(response[0])
    }

    const fetchMessages = async () => {
        if(selectedConversation && selectedConversation.hasOwnProperty('id')) {
            const response = await ConversationService.fetchConversationMessages(selectedConversation.id)
            setMessages(response)
        }

    }

    const addConversation = async (e) => {
        // e.preventDefault()
        if(chatMessage.length < 1 && !attachment) {
            alert('Please add your text!')
            return
        }
        // let phoneNo = /^\(?([0-9]{3})\)?/;
        // let email = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/
        // let ignoreWords = /(?:^|(?<= ))(one|two|three|four|five|six|seven|eight|nine|zero|gmail|live|hotmail|yahoo)(?:(?= )|$)/
        // if(selectedConversation.file.status != ONGOING && selectedConversation.file.status != SUCCESS) {
        //     if(chatMessage.match(phoneNo) || chatMessage.match(email) || chatMessage.match(ignoreWords)) {
        //         notify("error", 'Sorry! you cannot send number or email.')
        //         return
        //     }
        // }

        const formData = new FormData()
        formData.append('message', chatMessage)
        formData.append('attachment', attachment)
        formData.append('conversation_id', selectedConversation.id)

        const response = await ConversationService.postConversationMessage(formData)
        if (response.status == 'success') {
            await fetchMessages()
            setChatMessage('')
        } else {
            alert("Something went wrong! Please try again.")
        }
    }

    const userInfo = async () => {
        const user = await Storage.get('user')
        setAuthUser(user)
    }

    const conversationMessages = async (conversation_id) => {
        let convInfo = Object.assign(
            {},
            conversations.find((conversation) =>  conversation.id == conversation_id),
        );
        setSelectedConversation(convInfo)
    }

    return (
        <>
            <ChatHead />
            <div className="row">
                <ChatUsers conversations={conversations} authUser={authUser} conversationInfo={conversationMessages}/>
                <ChatArea showSendMessageBox={true} conversation={selectedConversation} conversationMessages={messages} authUser={authUser} setAttachment={setAttachment} message={chatMessage}  setMessage={setChatMessage} addConversation={addConversation}/>
            </div>
        </>
    );
}

export default Chat
