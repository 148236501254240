import React, { useEffect, useState } from "react";
import "../../layouts/dashboard/customdash.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import Loader from "../../components/Spinner";
import CountryInfo from "../../services/CountryInfo";
import ScholarshipsService from "../../services/ScholarshipsSvc";
import ScholarshipList from "../../components/ScholarshipList";
import ScholarshipModal from "../../components/ScholarshipModal";
import { notify } from "../../helpers/notifyManager";
import Storage from "../../helpers/storage";
import dayjs from "dayjs";

const Scholarships = () => {
    const [show, setShow] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [country, setCountry] = useState([]);
    const [scholarshipList, setScholarshipList] = useState([]);
    const [errors, setErrors] = useState({});
    const [description, setDescription] = useState("");
    const [searchName, setSearchName] = useState("");
    const [searchInstitute, setSearchInstitute] = useState("");
    const [searchType, setSearchType] = useState("");
    const [showSupervisorForm, setShowSupervisorForm] = useState(true);

    const [values, setValues] = useState({
        name: "",
        institute: "",
        type: "",
        website: "",
        last_date: null,
        country: "",
        description: "",
        fee: null,
        non_refundable_fee: null,
        responsibilities: [],
        processing_time: null
    });

    useEffect(() => {
        getCountry();
        fetchScholarships();
    }, []);

    const getCountry = async () => {
        let response = await CountryInfo.country();
        if (response) {
            setCountry(response.data);
            setShowPage(true);
        }
    };

    const fetchScholarships = async () => {
        let userFromStorage = Storage.get("user");
        let query = { user_id: userFromStorage.user_id };
        if (searchName.length > 0) Object.assign(query, { name: searchName });
        if (searchInstitute.length > 0)
            Object.assign(query, { institute: searchInstitute });
        if (searchType.length > 0) Object.assign(query, { type: searchType });
        let scholarshipList = await ScholarshipsService.fetchDashboardScholarships(
            query,
        );
        setScholarshipList(scholarshipList);
    };

    const resetSearch = () => {
        setSearchName("");
        setSearchInstitute("");
        setSearchType("");
    };

    const handleClose = () => {
        setValues({
            name: "",
            institute: "",
            type: "",
            website: "",
            last_date: null,
            country: "",
            description: "",
            fee: null,
            non_refundable_fee: null,
            responsibilities: [],
            processing_time: null
        })
        setShow(false);
        setShowSupervisorForm(true)
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleDelete = async (id) => {
        let deleteJobs = await ScholarshipsService.deleteScholarships(id)
        if (deleteJobs.status === 'success') {
            notify("success", "Successfully deleted")
            await fetchScholarships()
        }
    }

    const handleUpdate = (id) => {
        let scholarship = Object.assign(
            {},
            scholarshipList.find((value) => {
                return value.id == id;
            }),
        );
        Object.assign(scholarship, {
            last_date:  new Date(scholarship.last_date),
        });
        setValues(scholarship);
        setDescription(scholarship.description);
        setShowSupervisorForm(false)
        handleShow();
    };

    const handleDateChange = (date) => {
        setValues({ ...values, last_date: date });
    };

    const handleResponsibilityChange = (event) => {
        const { value } = event.target;
        let responsibility = values.responsibilities
        if (! responsibility.includes(value)) {
            responsibility.push(value)
        }
        setValues({
            ...values,
            responsibilities: responsibility,
        })

    };

    const handleResponsibilityRemove = (value) => {
        let responsibilities = values.responsibilities
        responsibilities = responsibilities.filter(item => item !== value)
        setValues({
            ...values,
            responsibilities: responsibilities,
        })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let last_date = null
        if (values.last_date) {
            last_date = dayjs(values.last_date).format('YYYY-MM-DD')
        }
        const finalData = {
            ...values,
            last_date: last_date,
            description: description,
        };
        let res;
        if (values.id) {
            res = await ScholarshipsService.updateScholarShips(
                finalData,
                finalData.id,
            );
        } else {
            res = await ScholarshipsService.postScholarships(finalData);
        }
        if (!res) {
            return;
        }
        if (typeof res == "object" && res.status !== "success") {
            setErrors(res);
        } else {
            notify("success", res.message);
            handleClose();
            await fetchScholarships();
        }
    };

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="campaigns-card">
                                <div className="title-box d-flex justify-content-between">
                                    <div className="title-left">
                                        <h2>Scholarship List</h2>
                                    </div>
                                    <div className="title-right">
                                        <button
                                            className={
                                                "cs-btn-success font-for-sm"
                                            }
                                            onClick={handleShow}
                                        >
                                            Add New Scholarship
                                        </button>
                                    </div>
                                </div>

                                <ScholarshipModal
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    country={country}
                                    handleDateChange={handleDateChange}
                                    description={description}
                                    setDescription={setDescription}
                                    show={show}
                                    errors={errors}
                                    handleResponsibilityChange={handleResponsibilityChange}
                                    handleResponsibilityRemove={handleResponsibilityRemove}
                                    showSupervisorForm={showSupervisorForm}
                                />

                                <div className="filter-box mb-3">
                                    <div className="form-group row mb-1">
                                        <div className="search-input mb-3 col-lg-4 col-md-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Name"
                                                onChange={(e) =>
                                                    setSearchName(
                                                        e.target.value,
                                                    )
                                                }
                                                value={searchName}
                                            />
                                        </div>
                                        <div className="search-input mb-3 col-lg-4 col-md-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Institute"
                                                onChange={(e) =>
                                                    setSearchInstitute(
                                                        e.target.value,
                                                    )
                                                }
                                                value={searchInstitute}
                                            />
                                        </div>
                                        <div className="form-group col-lg-3 col-md-6">
                                            <select
                                                className="form-control"
                                                value={searchType}
                                                onChange={(e) =>
                                                    setSearchType(
                                                        e.target.value,
                                                    )
                                                }
                                            >
                                                <option value="" disabled>
                                                    Type
                                                </option>
                                                <option value="undergraduate">
                                                    Undergraduate
                                                </option>
                                                <option value="course">
                                                    Course
                                                </option>
                                                <option value="masters">
                                                    Masters
                                                </option>
                                                <option value="PHD">Ph.D.</option>
                                                <option value="diploma">
                                                    Diploma
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <button
                                            className="btn--apply-filter"
                                            onClick={() => fetchScholarships()}
                                        >
                                            Apply Filter
                                        </button>
                                        <button
                                            className="btn--reset-all ml-3"
                                            onClick={resetSearch}
                                        >
                                            Reset All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ScholarshipList
                            scholarshipList={scholarshipList}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Scholarships;
