import React from "react";
import "./style.css";

const loginSignup = (props) => {
    return (
        <>
            <div className="page-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="login--heading-content">
                                <h2 className="content-title">
                                    Welcome To Your Professional Community
                                </h2>
                                <p className="content--sub-title">
                                </p>
                            </div>
                            <div className="login--image-container">
                                <img
                                    src="./images/login_work_together.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="login-container text-center">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default loginSignup;
