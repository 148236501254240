import React from 'react';
import DateManager from "../../../helpers/DateManager";
import DataNotFound from "../../DataNotFound";
import CountryInfo from "../../../helpers/CountryInfo";

const EducationalTab = ({data}) => {
    return (
        <div className="tab-pane" id="educational">
            <h5 className="mb-4 mt-2 text-uppercase card-title">Education</h5>
            {data && data.length > 0 && data[0].info_type === 'education' ? data.map((value, key) => {
                return (
                    <ul className="list-unstyled timeline-sm" key={key}>
                        <li className="timeline-sm-item">
                            <span className="timeline-sm-date">{DateManager(value.start_date, 'y')} - {DateManager(value.end_date, 'y')}</span>
                            <h5 className="mt-0 mb-1">{value.degree}</h5>
                            <p className="cs--text-color">{value.field_of_study}</p>
                            <p className="cs--text-color">{value.institution}</p>
                            <span><b>Score :</b>{value.score} out of {value.score_out_of} </span>
                            <p className="text-muted mt-2">
                                {value.description}
                            </p>
                            <span><b>Location :</b>{ value.location ? CountryInfo(value.location) ? CountryInfo(value.location).country_name : "N/A" : "N/A"} </span>
                        </li>
                    </ul>
                )
            }) : <DataNotFound />
            }
        </div>
    );
}

export default EducationalTab
