import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import Modal from "react-bootstrap/Modal";


const DashboardUserList = ({ userList, updateUserStatus }) => {

    const [showModal, setShowModal] = useState(false);
    const [affiliators, setAffiliators] = useState([]);

    const userStatus = (status) => {
        if (status == 1) {
            return 'Active'
        } else if (status == 2) {
            return 'Blocked'
        } else if (status == 3) {
            return 'Pending Supervisor'
        }
    }

    // useEffect(() => {
    //     handleShow()
    //     console.log(affiliators)
    // }, [affiliators])

    const handleClose = () => {
        setShowModal(false);
    }

    const handleShow = () => {
        setShowModal(true);
    }

    const affiliatorModal = (list) => {
        setAffiliators(list)
        handleShow()
    }


    return (
        <div className="col-lg-12 align-content-between">
            <div className={userList && userList.length > 0 ? "table-table-head align-items-center" : "none"}>
                <div className="column-head w-20">Name</div>
                <div className="column-head w-20">Email</div>
                <div className="column-head w-10">Status</div>
                <div className="column-head w-20">Referer Accounts</div>
                <div className="column-head w-15">Details</div>
                <div className="column-head w-5">Action</div>
            </div>
            {userList && userList.map((user, key) => {
                return (
                    <div className="table--content-box align-items-center" key={key}>
                        <div className="column w-20">{user.name}
                            {user.roles && user.roles.map((value, key) => {
                                return (
                                    <div key={key} className="badge badge-warning mr-2 ml-2">{value.name == 'AGENT' ? 'SUPERVISOR' : value.name}</div>
                                )
                            })}
                        </div>
                        <div className="column w-20">{user.email}</div>
                        <div className="column w-10">{userStatus(user.status)}</div>
                        <div className="column w-20">{user.referer_accounts.length > 0 ? <button className="cs-btn-success" onClick={(e) => affiliatorModal(user.referer_accounts)}>Show</button> : 'N/A'}</div>
                        <div className="column text-primary w-15">
                            <Link to={`users/${user.id}`}>
                                <button className="cs-btn-success">Click For Details</button>
                            </Link>
                        </div>
                        <div className="column w-5">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <span
                                        className="text-primary"
                                        data-toggle="dropdown"
                                    >
                                        <FontAwesomeIcon icon={faCog} />{" "}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() =>
                                            updateUserStatus(user.id, 2) // 2 = block user
                                        }
                                    >
                                        <FontAwesomeIcon
                                            className="text-primary fa-1x"
                                            icon={faWrench}
                                        />{" "}
                                        Block User
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() =>
                                            updateUserStatus(user.id, 1) // 1 = active user
                                        }
                                    >
                                        <FontAwesomeIcon
                                            className="text-primary fa-1x"
                                            icon={faWrench}
                                        />{" "}
                                        Approve User as Supervisor
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                )
            })}
            <Modal
                size="lg"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Refer List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table table-bordered">
                        <div className="table-table-head">
                            <div className="column-head w-20">Name</div>
                            <div className="column-head w-30">Email</div>
                            <div className="column-head w-20">Status</div>
                            <div className="column-head w-30">Files</div>
                        </div>
                        {affiliators.length > 0 && affiliators.map((value, key) => {
                            return (
                                <div className="table-bordered" key={key}>
                                    <div className="table-responsive-sm">

                                        <div className="table--content-box">
                                            <div className="column w-20">
                                                {value.name}
                                            </div>
                                            <div className="column text-primary w-30">
                                                {value.email}
                                            </div>
                                            <div className="column text-success w-20">
                                                {userStatus(value.status)}
                                            </div>
                                            <div className="column text-success w-30">
                                                {
                                                    value.client_files.length > 0 && value.client_files.map((value2, key2) => {
                                                        return (
                                                            <span key={key2}>
                                                                <Link to={`files/${value2.id}`} target="_blank" rel="noopener noreferrer"> {value2.id} </Link>,
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DashboardUserList
