import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import CreateClientService from "../../services/CreateClientService"
import AfiliatorModal from "../../components/AfiliatorModal"
import AfiliatorClientList from "../../components/AfiliatorClientList"
import { notify } from "../../helpers/notifyManager";
import CountryInfo from "../../services/CountryInfo";



const CreateClient = () => {
    const [show, setShow] = useState(false);
    const [showPage, setShowPage] = useState(true);
    const [errors, setErrors] = useState({});
    const [client, setClient] = useState([]);
    const [country, setCountry] = useState([]);

    const [values, setValues] = useState({
        name: "",
        email: "",
        phone_no: "",
        address: "",
        profession: "",
        nationality: ""
    });

    useEffect(() => {
        fetchClient()
        getCountry()

    }, []);

    const getCountry = async () => {
        let response = await CountryInfo.country();
        if (response) {
            setCountry(response.data);
        }
    };

    const options = country.map((country) => {
        return {
            label: country.country_name,
            value: country.id
        }
    })

    const fetchClient = async () => {
        let response = await CreateClientService.fetchClient();
        if (response) {
            setClient(response);
            setShowPage(true);
        }
    };

    const handleClose = () => {
        setShow(false);

    }

    const handleShow = () => {
        setValues({
            name: "",
            email: "",
            phone_no: "",
            address: "",
            profession: "",
            nationality: ""
        })
        setShow(true);
    }

    const handleChange = (event, key = null) => {
        let value = ""
        let name = ""
        if (key == null) {
            value = event.target.value;
            name = event.target.name
        } else {
            value = event.value
            name = key
        }

        setValues({
            ...values,
            [name]: value,
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        let res = await CreateClientService.postClient(values);

        if (typeof res == "object" && res.status !== "success") {
            setErrors(res);
        } else {
            notify("success", res.message);
            handleClose();
            setValues({
                name: "",
                email: "",
                phone_no: "",
                address: "",
                profession: "",
                nationality: ""
            })
            await fetchClient();
        }


    }

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="campaigns-card">
                                    <div className="title-box d-flex justify-content-between">
                                        <div className="title-left">
                                            <h2>Create Client</h2>
                                        </div>
                                        <div className="title-right">
                                            <button className={'cs-btn-success'} onClick={handleShow}>Add New Client</button>
                                        </div>
                                    </div>

                                    <AfiliatorModal
                                        handleClose={handleClose}
                                        handleSubmit={handleSubmit}
                                        handleChange={handleChange}
                                        values={values}
                                        show={show}
                                        errors={errors}
                                        country={country}
                                        options={options}
                                    />
                                </div>
                            </div>
                            {
                                client.length > 0 && <AfiliatorClientList Client={client} />
                            }

                        </div>
                    </div>

                )}
        </>
    );
}

export default CreateClient
