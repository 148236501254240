import React from 'react';
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import Navbar from "../../components/CommonNavbar"

const TearmsOfService = () => {
    return (
        <>
            <Navbar/>
            <div className="job-wrapper" style={{
                backgroundImage: "url(/images/background.png)",
                minHeight: "100vh",
            }}>
                <div className="container job--list-wrapper">
                    <div className="about--text-content"
                         style={{background: "#fff", padding: "30px", marginTop: "60px"}}>
                        <h3 className="card-title mb-5">Terms of Services</h3>
                        <ul>
                            <li><br/> <b>A. Introduction:</b><br/></li>
                            <li>
                                <p>
                                    Welcome to www.supervisor4u.com additionally also known as "we", "us" or "Jaha
                                    Consultant". We are an online platform and these are the terms of services
                                    overseeing your entrance and utilization of www.supervisor4u.com alongside its
                                    related sub-areas, destinations, portable application, administrations and
                                    apparatuses (the "Website"). By utilizing the Site, you thusly acknowledge these
                                    terms of services (counting the connected data in this) and speak to that you
                                    consent
                                    to conform to these terms of services (the "Client Agreement"). This User Agreement
                                    is regarded as successful upon your utilization of the Site which means your
                                    acknowledgment of these terms. On the off chance that you don't consent to be
                                    bound by this User Agreement kindly don't get to, register with or utilize this
                                    Site. This
                                    Site is claimed and worked by Jaha Consultant Bangladesh.
                                </p>
                            </li>
                            <li><p>

                                The Site maintains whatever authority is needed to change, adjust, include, or expel
                                bits of these terms of services whenever with no earlier warning. Changes will be
                                successful when posted on the Site with no other notification gave. If it's not too
                                much trouble check these terms of services normally for refreshes. You proceed with
                                the utilization of the Site following the presenting of changes on terms of services of
                                utilization establishes your acknowledgment of those changes.
                            </p></li>
                            <li>
                                <br/> <b>Terms description:</b><br/>
                            </li>
                            <li><p>
                                <b>Client-</b> all registered person in www.supervisor4u.com Site are known as Client. A
                                Client could be a student, or/and job-seeker. <br />
                                <b>Supervisor-</b> Client may send request to admin to become a Supervisor. A Client
                                becomes Supervisor after approval from Admin. Supervisor provides services to
                                Client to process a file. <br />
                                <b>Affiliator-</b> a Client who helps supervisor4u or Jaha Consultant to grow by creating
                                new Client. <br />
                            </p></li>
                            <li>
                                <br/> <b>B. CONDITIONS OF USE</b><br/>
                            </li>
                            <li>
                                <p>
                                    <br/> <b>1. Your account</b><br/>
                                    To get to specific administrations offered by the stage, we may necessitate that you
                                    make a record with us or give individual data to finish the making of a record. We
                                    may whenever in our sole and total watchfulness, nullify the username as well as
                                    password without giving any explanation or earlier notice and will not be at risk or
                                    answerable for any misfortunes endured by, brought about by, emerging out of,
                                    regarding or by reason of such solicitation or negation.

                                    Any agreement between us, regardless of whether for utilization of the site or in
                                    connection to the service through the site will be represented by the laws of
                                    Bangladesh and all gatherings submit to the non-restrictive purview of the
                                    Bangladeshi Courts.
                                    You are answerable for keeping up the classification of your client recognizable
                                    proof, password, account subtleties, and related private data. You consent to
                                    acknowledge this obligation and guarantee your record and its related subtleties are
                                    kept up safely consistently and every essential advance is taken to avoid abuse of
                                    your record. You ought to advise us promptly on the off chance that you have any
                                    motivation to accept that your secret phrase has gotten known to any other
                                    individual,
                                    or if the secret word is being, or is probably going to be, utilized in an
                                    unapproved
                                    way. You concur and recognize that any utilization of the Site and related
                                    administrations offered as well as any entrance to private data, information or
                                    interchanges utilizing your record and secret word will be esteemed to be either
                                    performed by you or approved by you all things considered. You consent to be bound
                                    by any entrance of the Site as well as utilization of any administrations offered by
                                    the
                                    Site (regardless of whether such access or use are approved by you or not). You
                                    concur that we will be entitled (yet not obliged) to follow up on, depend on or
                                    consider
                                    you exclusively capable and subject in regard thereof as though the equivalent were
                                    done or transmitted by you. You further concur and recognize that you will be bound
                                    by and consent to completely reimburse us against all misfortunes emerging from the
                                    utilization of or access to the Site through your record.
                                    It would be ideal if you guarantee that the subtleties you give us are right and
                                    finish
                                    consistently. You are committed to refreshing insights regarding your record
                                    progressively by getting to your record on the web. For snippets of data you are not
                                    ready to refresh by getting to Your Account on the Site, you should advise us by
                                    means of our client care correspondence channels to help you with these changes.
                                    We claim all authority to decline access to the Site, end records, evacuate or alter
                                    content whenever without earlier notice to you. We may whenever in our sole and
                                    total carefulness, demand that you update your Personal Data or forthwith negate the
                                    record or related subtleties without giving any explanation or earlier notice and
                                    will
                                    not be at risk or liability for any misfortunes endured by or brought about by you
                                    or
                                    emerging out of or regarding or by reason of such solicitation or nullification. You
                                    thusly consent to change your secret key now and again and to keep your record
                                    secure and furthermore will be answerable for the privacy of your record and
                                    obligated for any revelation or use (regardless of whether such use is approved or
                                    not) of the username and additionally secret key.

                                    <br/> <b>2. Your account vs your process</b><br/>
                                    We Only Consider the name of a supervisor4u.com account holder as the owner of
                                    and the client of the service which has been proceeded from the respective
                                    supervisor4u.com account. Supervisor4u retains all kinds of authority to
                                    ban/cancel/freeze/unfreeze your account(s) if they think it should be done so.
                                    Supervisor4u is not bound to provide the balance amount (of cashback) to you if your
                                    account is blocked/banned. Any kind of balance paid by you through bank/cash

                                    would be refunded to you within a reasonable time period according to the
                                    agreement between Client and Supervisor as well as Jaha Consultant policy.
                                    <br/> <b>3. Your Conduct</b><br/>
                                    You should not utilize the site at all that causes, or is probably going to cause,
                                    the
                                    Site or access to it to interfere, harmed or disabled in any capacity. You should
                                    not
                                    participate in exercises that could hurt or conceivably hurt the Site, its workers,
                                    officials, delegates, partners or some other gathering legitimately or in a
                                    roundabout
                                    way connected with the Site or access to it to be intruded, harmed or debilitated in
                                    any capacity. You comprehend that you, and not us, are answerable for every single
                                    electronic correspondence and substance sent from your PC to us and you should
                                    utilize the Site for legal purposes as it were. You are carefully restricted from
                                    utilizing
                                    the Site
                                    Supervisor4u.com might not be subject to any individual for any misfortune or harm,
                                    which may emerge from the utilization of any of the data contained in any of the
                                    materials on this site.
                                    <br/>• for deceitful purposes, or regarding a criminal offense or other unlawful
                                    movements
                                    <br/>• use the Site for illicit purposes
                                    <br/>• to cause irritation, bother or unnecessary nervousness
                                    <br/>• for whatever other purposes that is other than what is expected by us

                                    <br/> <b>4. Submission</b><br/>
                                    Anything that you submit to the Site or potentially give to us, including yet not
                                    restricted to, documents, questions, remarks, and proposals (on the whole,
                                    "Entries")
                                    will turn into our sole and selective property. Notwithstanding the rights pertinent
                                    to
                                    any Submission, when you present remarks or surveys on the Site, you additionally
                                    award us the privilege to utilize the name that you submit, regarding such audit,
                                    remark, or other substance. You will not utilize a bogus email address, profess to
                                    be
                                    somebody other than yourself or generally misdirect us or outsiders with regards to
                                    the cause of any Submissions. We may, yet will not be committed to, expel or alter
                                    any Submissions with no notification or lawful course appropriate to us in such
                                    manner.
                                    <br/> <b>5. Claims against objectionable content</b><br/>
                                    We list a huge number of services offered by various Supervisors from different
                                    countries, it is impossible for us to verify all services shown in the website. On
                                    the off
                                    chance that you accept that any substance on the Site is illicit, hostile,
                                    beguiling,
                                    deluding, harsh, profane, irritating, impious, slanderous, offensive, revolting,
                                    obscene, pedophilic or threatening; ethnically questionable, criticizing; or is
                                    generally
                                    damaging to outsiders; or identifies with or advances tax evasion; or is destructive
                                    to
                                    minors in any capacity; or mimics someone else; or compromises the solidarity,
                                    trustworthiness, security or sway of Bangladesh or cordial relations with remote
                                    States; or shocking or generally unlawful in any way at all; or which comprises of
                                    or

                                    contains programming infections, (" frightful substance "), please tell us quickly
                                    by
                                    following by keeping in touch with us on contact@supervisor4u.com. We will make
                                    every single useful undertaking to examine and evacuate substantial offensive
                                    substance griped about inside a sensible measure of time.
                                    <br/> <b>6. Losses</b><br/>
                                    We won't be answerable for any business or individual misfortunes or whatever other
                                    backhanded or weighty misfortune that isn't sensibly predictable to both you and us
                                    when you initiated utilizing the Site.
                                    <br/> <b>7. Corrections to conditions or alterations of service and related
                                    promise</b><br/>
                                    We maintain all authority to make changes to the Site, its strategies, these terms
                                    and
                                    conditions and some other freely showed condition or administration guarantee
                                    whenever. You will be dependent upon the approaches and terms and conditions in
                                    power at the time you utilized the Site except if any change to those strategies or
                                    these conditions is required to be made by law or government expert (in which case
                                    it
                                    will apply to orders recently set by you). In the event that any of these conditions
                                    is
                                    regarded invalid, void, or in any way, shape or form unenforceable, that condition
                                    will
                                    be considered severable and won't influence the legitimacy and enforceability of any
                                    outstanding condition.
                                    <br/> <b>8. Waiver</b><br/>
                                    You recognize and perceive that we are a private business endeavor and claim all
                                    authority to lead the business to accomplish our targets in a way we esteem fit. You
                                    additionally recognize that in the event that you break the conditions expressed on
                                    our Site and we make no move, we are as yet qualified for utilizing our privileges
                                    and
                                    cures in whatever other circumstance where you rupture these conditions.
                                    <br/> <b>9. Administering LAW AND JURISDICTION</b><br/>
                                    These terms and conditions are administered by and interpreted as per the laws of
                                    The People's Republic of Bangladesh. You concur that the courts, councils and
                                    additionally semi-legal bodies situated in Dhaka, Bangladesh will have the
                                    restrictive
                                    purview on any question emerging inside Bangladesh under this Agreement.
                                    <br/> <b>10. Profile development</b><br />• The user shall certify that the information/data given by them to
                                    Supervisor4u.com is accurate and correct.
                                    <br/>• Supervisor4u.com allows to show partial profile from Client or/and Supervisor
                                    until file processing fee is paid in Jaha Consultant account.
                                    <br/>• Supervisor4u.com would not be held liable for loss of any data technical or
                                    otherwise, information, particulars supplied by customers due to reasons
                                    beyond its control like corruption of data or delay or failure to perform as a
                                    result of any causes or conditions that are beyond Supervisor4u.com´s
                                    reasonable control including but not limited to strikes, riots, civil unrest, Govt.
                                    policies, tampering of data by unauthorized persons like hackers, war and
                                    natural calamities.

                                    <br/>• Supervisor4u.com reserves its right to reject any insertion or
                                    information/data
                                    provided by the user without assigning any reason.
                                    <br/>• Supervisor4u.com has the right to make all such modifications/editing of
                                    profile
                                    in order to fit it in database.

                                    <br/> <b>11. Art of Service listing</b>
                                    <br/>• Supervisor4u.com allows Supervisor to Post/Submit their art of service
                                    description in the Supervisor4u.com website free of cost.
                                    <br/>• Supervisor4u.com offers no guarantee or/and warranties that there would be a
                                    satisfactory response or any response once the service description is put on
                                    display.
                                    <br/> <b>12. Jobs listing</b>
                                    <br/>• Supervisor4u.com allows Supervisor to list Jobs in the Supervisor4u.com
                                    website free of cost.
                                    <br/>• Supervisor4u.com offers no guarantee or/and warranties that there would be a
                                    satisfactory response or any response once the jobs description is put on
                                    display.

                                    <br/> <b>13. Scholarships listing</b><br />• Supervisor4u.com allows Supervisor to list scholarships in the
                                    Supervisor4u.com website free of cost.
                                    <br/>• Supervisor4u.com offers no guarantee or/and warranties that there would be a
                                    satisfactory response or any response once the scholarship is put on display.

                                    <br/> <b>14. File Processing</b><br/>
                                    All costs are recorded in Bangladeshi Taka (BDT) and file processing fee is defined
                                    by the Supervisor himself. How a file processing works defined in section “How it
                                    works”. For further details take a look in this section.
                                    <br/> <b>15. Non-returnable fees</b><br/>
                                    Fees which is paid in Supervisor´s account though a process is unsuccessful.
                                    Supervisor has to describe in service description box why non-returnable fee is
                                    necessary. If needed, supporting document has to be showed/ uploaded.
                                    <br/> <b>16. Supervisor4u.com service charge</b><br/>
                                    For a successful process supervisor4u.com charge 10% service charge from
                                    Supervisory fees. Minimum service charge for supervisor4u.com is 2000BDT and
                                    maximum is 20000BDT.
                                    For an unsuccessful/failure process supervisor4u.com charge 10% service charge
                                    from non-returnable fees.
                                    <br/> <b>17. Payment</b><br/>
                                    After confirmation from Supervisor to continue file processing Client has to pay
                                    processing fee which is defined by Supervisor in Jaha Consultant account. No cash
                                    payment is acceptable. Usually we confirm payment confirmation within 2 working
                                    days.
                                    After a successful process usually we pay supervisory fee in Supervisors account
                                    within 2 working days after confirmation from Client. Jaha Consultant is not bound
                                    to
                                    pay advisory fee in cash.
                                    After a successful process usually we pay Affiliators reward within 2 working days
                                    after confirmation from Client. Jaha Consultant is not bound to pay Affiliators
                                    reward
                                    in cash.
                                    <br/> <b>18. File processing cancellation</b><br/>
                                    During a process in “Ongoing” status, Jaha Consultant does not bound to cancel a
                                    processing unless defined processing time is finished. A file process cancellation
                                    may be possible before finishing processing time if Jaha Consultant, Supervisor and
                                    Client agreed upon it.
                                    <br/> <b>19. Refund</b><br/>
                                    After an unsuccessful process usually we refund processing fee within 2 working
                                    days in Clients account after confirmation from Supervisor according to the
                                    agreement between Client & Supervisor. Jaha Consultant is not bound to refund
                                    processing fee in cash.
                                    <br/> <b>20. Affiliator´s reward</b><br/>
                                    Inviting Client- Affiliator will be rewarded 30% of Jaha Consultant service charge
                                    fees
                                    from a successful process.
                                    Inviting Supervisor- Affiliator will be rewarded 30% of Jaha Consultant service
                                    charge
                                    fees from successful processes until he gets 10000BDT.
                                    No reward for Affiliator for an unsuccessful/ failure process. Jaha Consultant is
                                    not
                                    bound to pay Affiliators reward in cash.
                                    <br/> <b>21. Warning</b><br />• Supervisor4u.com is as an online job portal of communication between Client
                                    and Supervisor. Supervisor4u.com is not liable on account of any kind of
                                    financial transaction among Client, and/or Affiliator, and/or Supervisor.
                                    <br/>• Any service description published in Supervisor4u.com website is provided by
                                    the Supervisors. Individual Supervisor or Organization is responsible for any

                                    type of service description or recruitment process published by
                                    Supervisor4u.com Website.
                                    <br/>• Clients are expressly prohibited from involving any kind of financial dealing
                                    with Affiliator or Supervisor. If the Client provides/discloses any financial
                                    information such as Credit/Debit card number, personal bank account, online
                                    payment information and if anyone makes arrangements transaction via
                                    money order, wire transfer, international funds transfer, electronic currency
                                    etc. Supervisor4u.com will not be responsible regarding these sort of
                                    transaction issues.
                                    <br/>• Supervisor4u.com is not accountable for any kind of fraud by Supervisors or
                                    Affiliators after processing through the website as well as transferring money
                                    or retain a portion in the name of payment for security charge to the
                                    Supervisors or Affiliators.
                                    <br/>• Supervisor4u.com will not be liable on account of any irregularity,
                                    incomplete
                                    information, inaccuracy of information provided by the Supervisors on this
                                    website. It is the responsibility for the Client doing further research regarding
                                    the information given on the site.
                                    <br/>• Supervisor4u.com is only responsible for the file processing fee which is
                                    paid
                                    in Jaha Consultant account.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TearmsOfService;