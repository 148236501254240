import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom";
import Storage from "../../helpers/storage";

const MenuBar = () => {
    // const nav = document.querySelector("#scrolled-top");
    // const mobIcon = document.querySelector(".mobile--menu-icon");
    // const menuItems = document.getElementById("menubar");
    const topNav = 200;

    function fixNav() {
        if (window.scrollY >= topNav) {
            document.body.classList.add("fixed-nav");
            if (window.screen.width > 576) {
                document.getElementById("logo").style.display = "none"
            }
        } else {
            document.body.classList.remove("fixed-nav");
            document.getElementById("logo").style.display = "block"

            if (document.getElementById("menubar")) {
                document.getElementById("menubar").classList.remove("block");
            }
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", fixNav);

        return () => { window.removeEventListener('scroll', fixNav) }
    }, [])


    const toggleMenu = () => {
        document.getElementById("menubar").classList.toggle("block");
    }

    return (
        <header>
            <div className="mobile--menu-icon" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} size={window.screen.width > 576 ? "2x" : "1x"} className="trend-icon" />
            </div>
            <div className="menu-wrapper">
                <nav className="menu container">
                    <div id="logo" className="logo-container">
                        <Link to=""
                        >
                            {window.screen.width > 576 &&
                                <img src="./images/logo_final.png" />
                            }

                            {window.screen.width < 576 &&
                                <img src="./images/blackbg_rhdpi.png" />
                            }

                        </Link>
                    </div>
                    <ul className="menu-items" id="menubar">
                        <li className="list-item"><Link to="/">Home</Link></li>
                        <li className="list-item"><Link to={`/how-it-works/1`}>How it works</Link></li>
                        <li className="list-item"><Link to="/scholarships">Scholarships</Link></li>
                        <li className="list-item"><Link to="/jobs">Jobs</Link></li>
                        <li className="list-item">
                            {
                                Storage.has('user') ? <Link to={`/dashboard`} className="btn-signup">
                                    Dashboard
                                </Link> : <Link to={`/login`} className="btn-signup">
                                    Login
                                </Link>
                            }
                        </li>
                    </ul>
                </nav>
            </div>
        </header>


    );
}

export default MenuBar
