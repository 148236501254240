import React from "react";
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import Navbar from "../../components/CommonNavbar"

const OurMission = () => {
    return (
        <>
            <Navbar/>
            <div className="job-wrapper" style={{
                backgroundImage: "url(/images/background.png)",
                minHeight: "100vh",
            }}>
                <div className="container job--list-wrapper">
                    <div className="about--text-content" style={{background: "#fff", padding: "30px", marginTop: "60px"}}>
                        <h3 className="card-title mb-5">Our Mission</h3>
                        <p><span style={{fontWeight: 400}}>Higher education and employment in abroad is a very important aspect in socio economic life of Bangladesh. Every year thousands of people are going to abroad for higher education and employment purpose. Many of those people take assist from different overseas companies or third parties. Some overseas companies and third parties cheat people and swindle money from innocent students and jobseekers. Also a lot of experienced expatriates who are capable and wish to help people with their practical experience. Among them only a tiny fraction of people get the opportunity to explore themselves and help young generation to ease their struggle during application process. Our mission is to bring those people under a reliable platform. Also to bring reliability, connectivity and best services between experienced and needy young generation who wish to go to abroad. For us, the focus is on personal and trusting dealings with our clients as well as goal-oriented cooperation.</span></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurMission;
