import React from 'react';
import DateManager from "../../../helpers/DateManager";
import DataNotFound from "../../DataNotFound";

const CertificationTab = ({data}) => {
    return (
        <div className="tab-pane" id="certification">
            {data && data.hasOwnProperty('certification') &&
            data.certification.length > 0 ?
                <div>
                    <div>
                        <h5 className="mb-4 mt-2 text-uppercase card-title">Certification</h5>
                        <ul className="list-unstyled timeline-sm">
                            {data && data.hasOwnProperty('certification') &&
                            data.certification.length > 0 &&
                            data.certification[0].info_type == 'certification' ?
                                data.certification.map((value, key) => {
                                    return (
                                        <li className="timeline-sm-item" key={key}>
                                            <span className="timeline-sm-date">{DateManager(value.end_date, 'y')}</span>
                                            <h5 className="mt-0 mb-1">{value.title}</h5>
                                            {/*<h5 className="mt-0 mb-1">{CountryInfo(value.location).country_name}</h5>*/}
                                            <p className="cs--text-color">{value.institution}</p>
                                            <p className="text-muted mt-2">
                                                {value.description}
                                            </p>
                                        </li>)
                                }) : <h5> Sorry! No Certification Found!</h5>}
                        </ul>
                    </div>
                    <div>
                        <h5 className="mb-4 mt-4 text-uppercase card-title">Exam Score</h5>
                        <ul className="list-unstyled timeline-sm">
                            {data && data.hasOwnProperty('examInfo') &&
                            data.examInfo.length > 0 &&
                            data.examInfo[0].info_type == 'exam_score' ?
                                data.examInfo.map((value, key) => {
                                    return (
                                        <li className="timeline-sm-item" key={key}>
                                            <h5 className="mt-0 mb-1 cs--text-color">{value.title.toUpperCase()}</h5>
                                            <h5 className="mt-0 mb-1">{value.score}</h5>

                                        </li>)
                                }) : <h5> Sorry! No Exam Information Found!</h5>}
                        </ul>
                    </div>
                </div>
                : <DataNotFound/>}
        </div>
    );
}

export default CertificationTab
