import React from "react";
import {Link} from "react-router-dom";
import CountryInfo from "../../helpers/CountryInfo";

const JobListCard = ({jobList}) => {
    return (
        <>
            {jobList && jobList.map(job => {
                return (
                    <div key={job.id}>
                        <div className="single-job">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="job-content">
                                        <div className="job--text-content">
                                            <h5>
                                                {job.name} @ {job.speciality.name}
                                            </h5>
                                            <div className="label-container">
                                                <span className="job-label mr-2">
                                                    Experience: {job.experience} Years
                                                </span>
                                                <span className="job-label mr-2">
                                                    Salary: Approx. {job.salary}
                                                </span>
                                                <span className="job-label mr-2">
                                                    Country: {job.country_id ? CountryInfo(job.country_id).country_name : " "}
                                                </span>
                                            </div>
                                            <div className="active-status">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to={`/job-details/${job.id}`} className="col-lg-4 text-in-center">
                                    <h5 className="cs-btn-blue">Click For Details</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default JobListCard;
