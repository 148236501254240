export const REJECTED = 0;
export const INITIATED = 1;
export const AGENT_PENDING = 2;
export const PAYMENT_PENDING = 3;
export const ONGOING = 4;
export const SUCCESS = 5;
export const FAILED = 6;
export const CLIENT_PENDING = 7;

export const FileStatus = {
    [REJECTED]: 'REJECTED',
    [INITIATED]: 'INITIATED',
    [AGENT_PENDING]: 'SUPERVISOR_PENDING',
    [PAYMENT_PENDING]: 'PAYMENT_PENDING',
    [ONGOING]: 'ONGOING',
    [SUCCESS]: 'SUCCESS',
    [FAILED]: 'FAILED',
    [CLIENT_PENDING]: 'CLIENT_PENDING'
}
