import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { notify } from "../../helpers/notifyManager";
import FileService from "../../services/FileService";
import CountryInfo from "../../helpers/CountryInfo";
import Storage from "../../helpers/storage";
import Errors from "../Errors";

const AgentModal = ({
    handleClose,
    show,
    agentInfo,
    agentId,
    status
}) => {
    const [message, setMessage] = useState("")
    const [visaCategoryId, setVisaCategoryId] = useState("")
    const [errors, setErrors] = useState([])


    const handleMessage = async (event) => {
        event.preventDefault()
        const user = await Storage.get('user')
        if (!user) {
            notify('error', 'Please login first before contacting with agent.')
            handleClose();
            return
        }
        // let phoneNo = /^\(?([0-9]{3})\)?/;
        // let email = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/
        // let ignoreWords = /(?:^|(?<= ))(one|two|three|four|five|six|seven|eight|nine|zero|gmail|live|hotmail|yahoo)(?:(?= )|$)/
        // if (message.match(phoneNo) || message.match(email) || message.match(ignoreWords)) {
        //     notify("error", 'Sorry! you cannot send number or email.')
        //     return
        // }
        let data = {
            message: message,
            visa_category_id: visaCategoryId,
            agent_id: agentId,
            client_id: user.user_id,
            status: status
        }
        const response = await FileService.saveFile(data)
        if (response.status == "success") {
            notify("success", response.message);
            handleClose();
        } else {
            setErrors(response)
        }
    }

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Contact Supervisor
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(event) => handleMessage(event)}>
                    <div className="form-group">
                        <label htmlFor="visa_category_id" className="col-form-label">Select Visa Category</label>
                        <select className="col-md-12 form-control" id="visa_category_id" onChange={(event) => { setVisaCategoryId(event.target.value) }} required>
                            <option value="">Select Visa category</option>
                            {
                                agentInfo && agentInfo.map((value, index) => {
                                    return (
                                        <option key={index} value={value.id}>
                                            {value.country_id ? CountryInfo(value.country_id).country_name : " "} - {value.visa_category.name} - {value.fee}/-
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="col-form-label">Your Message to Supervisor</label>
                        <textarea
                            required
                            rows="5"
                            className="form-control"
                            placeholder="Please do not provide any communication information like: E-Mail, WhatsApp, Mobile No. etc."
                            name="message"
                            id="message"
                            onChange={(event) => { setMessage(event.target.value) }}
                        />
                    </div>

                    <Button id="btn-agent" type="submit"
                        className="button-modal-submit cs-btn-success align-right"
                    >Submit</Button>
                </form>
                <div className="mt-5">
                    <Errors errorList={errors} />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AgentModal