import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Certificate = ({ handleChange, itemKey, item, remove, handleDateChange }) => {
    return (
        <div className="additional-certificate">
            <div className="row mb-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                        <label>Name<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`title-${itemKey}`}
                            className="form-control"
                            value={item.title}
                            placeholder="Name of the certificate"
                            name={`title[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Institute<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`institution-${itemKey}`}
                            className="form-control"
                            value={item.institution}
                            placeholder="Institute Name"
                            name={`institution[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>End Date</label>
                        <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                            selected={item.end_date}
                            name={`end_date[${itemKey}]`}
                            onChange={date => handleDateChange(date, `end_date-${itemKey}`)}
                            showMonthDropdown
                            showYearDropdown
                            adjustDateOnChange
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            id={`description-${itemKey}`}
                            className="form-control"
                            value={item.description}
                            placeholder="Maximum 250 words"
                            name={`description[${itemKey}]`}
                            onChange={handleChange}
                            rows={3}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="col-lg-12 col-sm-12">
                        <button
                            id="removeBtn"
                            className="cs-btn btn-remove"
                            onClick={(e) => remove(e, itemKey, item.id)}
                        >
                            Remove{" "}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Certificate;
