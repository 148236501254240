import React, { useEffect, useState } from "react";
import JobListCard from "../../components/JobListCard";
import CountryInfo from "../../services/CountryInfo";
import Loader from "../../components/Spinner";
import CareerJobsService from "../../services/CareerJobsService";
import useQueryParams from "../../helpers/useQueryParams";
import Navbar from "../../components/CommonNavbar"

const JobList = () => {
    const queryParams = useQueryParams()
    const [country, setCountry] = useState([]);
    const [showPage, setShowPage] = useState(false);
    const [specialities, setSpecialities] = useState([]);
    const [searchName, setSearchName] = useState(queryParams.get('name') ? queryParams.get('name') : '');
    const [searchSpeciality, setSearchSpeciality] = useState(queryParams.get('speciality_id') ? queryParams.get('speciality_id') : '');
    const [searchByCountry, setSearchByCountry] = useState(queryParams.get('country_id') ? queryParams.get('country_id') : '');
    const [jobsList, setJobsList] = useState([]);



    useEffect(() => {
        fetchQueryParams()
        getCountry()
        fetchJobs()
        fetchSpecialities()
    }, []);

    const fetchQueryParams = () => {
        if (queryParams.hasOwnProperty('country_id')) {
            setSearchByCountry(queryParams.country_id)
        }
        if (queryParams.hasOwnProperty('speciality_id')) {
            setSearchSpeciality(queryParams.speciality_id)
        }
        if (queryParams.hasOwnProperty('name')) {
            setSearchName(queryParams.name)
        }
    }
    const getCountry = async () => {
        let response = await CountryInfo.careerJobCountry();
        if (response) {
            setCountry(response.data);
            setShowPage(true);
        }
    };

    const fetchJobs = async () => {
        let query = {}
        if (searchName.length > 0) Object.assign(query, { name: searchName });
        if (searchSpeciality.length > 0) Object.assign(query, { speciality_id: searchSpeciality });
        if (searchByCountry.length > 0) Object.assign(query, { country_id: searchByCountry });
        let jobsList = await CareerJobsService.fetchJobs(query)
        setJobsList(jobsList)
    }

    const fetchSpecialities = async () => {
        let response = await CareerJobsService.fetchSpecialities();
        if (response) {
            setSpecialities(response);
            setShowPage(true);
        }
    };

    const resetSearch = async () => {
        setSearchName("")
        setSearchSpeciality("")
        window.location.reload()
    };


    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <>
                    <Navbar />
                    <div className="job-wrapper"
                        style={{ backgroundImage: "url(/images/background.png)", minHeight: "100vh" }}>

                        <div className="container extra-space">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-panel">
                                        <div className="search-form">
                                            <h1>Find the Right Job For You</h1>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search Job Name"
                                                                onChange={(e) =>
                                                                    setSearchName(
                                                                        e.target.value,
                                                                    )
                                                                }
                                                                value={searchName}
                                                            />

                                                        </div>

                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <select className="form-control"
                                                                value={searchByCountry}
                                                                onChange={(e) => setSearchByCountry(e.target.value)}
                                                            >
                                                                <option value="">Select Country</option>
                                                                {country.map((country) => {
                                                                    return (
                                                                        <option
                                                                            key={country.id}
                                                                            value={country.id}
                                                                        >
                                                                            {country.country_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                value={searchSpeciality}
                                                                onChange={(e) =>
                                                                    setSearchSpeciality(
                                                                        e.target.value,
                                                                    )
                                                                }
                                                            >
                                                                <option value="">Select Speciality</option>
                                                                {specialities.map((speciality) => {
                                                                    return (
                                                                        <option
                                                                            key={speciality.id}
                                                                            value={speciality.id}
                                                                        >
                                                                            {speciality.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-lg-3">
                                                        <div className="form-group mb-0">
                                                            <button
                                                                type={"button"}
                                                                className="btn--apply-filter"
                                                                onClick={fetchJobs}
                                                            >
                                                                Apply Filter
                                                        </button>
                                                            <button
                                                                type={"button"}
                                                                className="btn--reset-all ml-3"
                                                                onClick={resetSearch}
                                                            >
                                                                Reset All
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="job--count-list" />
                            <div className="job--list-wrapper">
                                <JobListCard jobList={jobsList} countries={country} />
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </>
    );
};

export default JobList;
