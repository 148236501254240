import apiCall from "../helpers/apicall"
import {client} from "../helpers/client"
import {catcher} from "../helpers"
import Storage from "../helpers/storage";
import {TOKEN_NAME} from "../constants/Auth";

const ScholarshipsService = {
    async fetchScholarships(query) {
        let api = apiCall("get-scholarships", query);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchDashboardScholarships(query) {
        let api = apiCall("get-scholarships", query);
        try {
            let header = Object.assign(api.header, {
                Authorization: `bearer ${await Storage.get(TOKEN_NAME)}`
            });
            const response = await client[api.method](api.url, header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postScholarships(data) {
        let api = apiCall("post-scholarships");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async updateScholarShips(data, id) {
        let api = apiCall("update-scholarships", {}, id)
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchScholarshipById(id) {
        let api = apiCall("get-scholarship-by-id", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async deleteScholarships(id) {
        let api = apiCall("delete-scholarships", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
}

export default ScholarshipsService