import React, {useState} from "react";

import Errors from "../../components/Errors";
import Loader from "../../components/Spinner";

import Navbar from "../../components/CommonNavbar";
import AuthService from "../../services/AuthService";

const ChangePassword = () => {
    const [errors, setErrors] = useState({});
    const [showPage, setShowPage] = useState(true);
    const [success, setSuccess] = useState(false);
    const [values, setValues] = useState({
        password: "",
        current_password: "",
        password_confirmation: ""
    });


    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault()
        let res = await AuthService.changePassword(values);
        console.log("res",  res)
        if (res === true) {
            setSuccess(true)
        } else if (typeof res == "object") {

            setErrors(res);
        }
    };

    return (
        <>
            <Navbar/>
            {!showPage ? (
                <Loader/>
            ) : (
                <div className="container h-100" style={{'marginTop': '10rem'}}>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="row">
                            <div className="card" style={{'width': '30rem'}}>
                                <div className="card-body">
                                    <form id="regForm" onSubmit={handleSubmit}>
                                        <div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="form-title">
                                                        Change Password
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label>Current Password<span style={{'color': 'red'}}>*</span></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="current_password"
                                                        value={values.current_password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label>New Password<span style={{'color': 'red'}}>*</span></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        placeholder="min length 6"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label>Confirm Password<span
                                                        style={{'color': 'red'}}>*</span></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password_confirmation"
                                                        value={values.password_confirmation}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {
                                                success &&
                                                <div className="alert alert-success" role="alert">
                                                    Password has changed successfully.
                                                </div>
                                            }
                                            <Errors errorList={errors}/>

                                            <div className="nextprevious--btn-container">
                                                <div className="previous-btn-container"/>
                                                <div className="next-btn-container">
                                                    <button
                                                        type="submit"
                                                        className="cs-btn btn-next-previous btn--next-ms"
                                                        id="nextBtn"
                                                    >
                                                        Change Password
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChangePassword;
