import apiCall from "../helpers/apicall"
import {client} from "../helpers/client"
import {catcher} from "../helpers"
import {USER} from "../constants/Auth";
import Storage from "../helpers/storage";
import {CERTIFICATION, EDUCATION, EXAM_SCORE, PROFESSION} from "../constants/UserInformation";
import {notify} from "../helpers/notifyManager";
import history from "../routes/history";

const UserInfo = {
    async getBasic(id = null) {
        let userInfo = Storage.get(USER)
        if (!userInfo) {
            return history.push('/login');
        }

        let userId = userInfo.user_id
        if (id) {
            userId = id
        }
        let api = apiCall("user-profile", {}, userId);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getProfession() {
        let api = apiCall("get-user-information", {info_type: PROFESSION})
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getProfessionById(id) {
        let api = apiCall("get-user-information-by-id", {info_type: PROFESSION}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getEducation() {
        let api = apiCall("get-user-information", {info_type: EDUCATION})
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getEducationById(id) {
        let api = apiCall("get-user-information-by-id", {info_type: EDUCATION}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getCertification() {
        let api = apiCall("get-user-information", {info_type: CERTIFICATION})
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getCertificationById(id) {
        let api = apiCall("get-user-information-by-id", {info_type: CERTIFICATION}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },


    async getExams() {
        let api = apiCall("get-user-information", {info_type: EXAM_SCORE})
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async getExamsById(id) {
        let api = apiCall("get-user-information-by-id", {info_type: EXAM_SCORE}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async basicInfo(payload) {
        let userInfo = Storage.get(USER)
        let api = apiCall("update-user-info", {}, userInfo.user_id);
        try {
            let apiHeader = Object.assign({}, api.header)
            Object.assign(apiHeader, {
                'Content-Type': 'multipart/form-data'
            });
            const response = await client[api.method](api.url, apiHeader, payload);
            const data = response.data.data
            const user = {
                name: data.name,
                username: data.username,
                user_id: data.user_id,
                roles: data.roles,
                avatar: data.avatar,
                email: data.email
            }
            await Storage.set(USER, user, true)
            return data
        } catch (error) {
            return catcher(error);
        }
    },

    async saveUserInfo(data) {
        let api = apiCall("post-user-info");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async deleteUserInfo(id) {
        let api = apiCall("delete-user-info", {}, id);

        try {
            const response = await client[api.method](api.url, api.header);
            notify('success', response.data.message)
            return response.data;
        } catch (error) {

            return catcher(error);
        }
    },

    async getAgentInfo() {
        let api = apiCall("get-agent-info")
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async getAgentInfoById(id) {
        let api = apiCall("get-agent-info-by-id", {}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async saveAgentInfo(data) {
        let api = apiCall("save-agent-info")
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },

    async deleteAgentInfo(id) {
        let api = apiCall("delete-agent-info", {}, id);

        try {
            const response = await client[api.method](api.url, api.header);
            notify('success', response.data.message)
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async getUsers(query = {}) {
        let api = apiCall("get-users", query)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async joinAsAffiliator() {
        let api = apiCall("join-as-affiliator")
        try {
            const response = await client[api.method](api.url, api.header)
            const data = response.data.data
            const user = {
                name: data.name,
                username: data.username,
                user_id: data.user_id,
                roles: data.roles,
                avatar: data.avatar,
                email: data.email
            }
            await Storage.set(USER, user, true)
            return user
        } catch (e) {
            return catcher(e)
        }

    },
    async updateUserStatus(data) {
        let api = apiCall("update-user-status")
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async notificationList() {
        let api = apiCall("notification-list")
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async unreadNotificationCount() {
        let api = apiCall("unread-notification-count")
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async markNotificationRead() {
        let api = apiCall("mark-notification-read")
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    }
};

export default UserInfo
