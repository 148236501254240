import React from 'react';
import { Link } from "react-router-dom";

const OurServices = ({ removeMenu }) => {
    return (
        <div onClick={removeMenu}>
            <section className="service-wrapper">
                <div className="container">
                    {/* section title  */}
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h2> Searching Part-time Job</h2>
                                <h4 className="mb-5">Work with Us</h4>
                                {/* <p className="mb-5">
                                    Handshake has the latest jobs from employers
                                    hiring at your school.
                                </p> */}
                            </div>
                        </div>
                    </div>
                    {/*End section title  */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="single-card-service text-center mb-5">
                                <div className="mb-4">
                                    <img className="service-icon" src="./images/become-affiliator.svg" alt="" />
                                </div>
                                <h4 className="mb-3">Become Affiliator</h4>
                                {/* <p>Received Personalized job recommendations</p> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="single-card-service text-center mb-5">
                                <div className="mb-4">
                                    <img className="service-icon" src="./images/invite.svg" alt="" />
                                </div>
                                <h4 className="mb-3">
                                    Invite Friends
                                </h4>
                                {/* <p>
                                    Stand out to employers during virtual fairs and
                                    events
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="single-card-service text-center mb-5">
                                <div className="mb-4">
                                    <img className="service-icon" src="./images/get-reward.svg" alt="" />
                                </div>
                                <h4 className="mb-3"> Get Reward</h4>
                                {/* <p>Apply to jobs meant for students for like you</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <Link to={"/how-it-works/3"} className="btn--how-works">How it works</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default OurServices
