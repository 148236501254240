import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import { useParams } from "react-router-dom";
import ScholarshipsService from "../../services/ScholarshipsSvc";
import Navbar from "../../components/CommonNavbar"
import history from "../../routes/history";
import CountryInfo from "../../helpers/CountryInfo";


const ScholarshipDetails = () => {
    const [scholarshipDetails, setScholarshipDetails] = useState('')
    const { id } = useParams()

    useEffect(() => {
        fetchScholarshipById(id)
    }, [])

    const fetchScholarshipById = async (scholarshipId) => {
        const response = await ScholarshipsService.fetchScholarshipById(scholarshipId)
        setScholarshipDetails(response)
    }

    const goToPersonalAgentProfile = (id) => {
        return history.push('/agents/' + id)
    }


    return (
        <div className="job-details-wrapper">
            <Navbar />
            <div className="container extra-space">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-xs-12 jobs-card">
                        {/* job description */}
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">About The Scholarship</h3>
                                { scholarshipDetails.description &&
                                    <div dangerouslySetInnerHTML={{ __html: scholarshipDetails.description }} /> }
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4 col-lg-4 jobs-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="job-detail-detail">
                                    <div className="inner-image">
                                    </div>
                                    <h3 className="widget-title">Scholarship Overview</h3>
                                    <ul className="list">
                                        <li>
                                            <span className="text">Scholarship Name: <b>{scholarshipDetails.name} </b> </span>
                                        </li>
                                        <li>Institute: <b> {scholarshipDetails.institute}</b></li>
                                        <li>Type: {scholarshipDetails.type} </li>
                                        <li>Country: {scholarshipDetails.country_id ? CountryInfo(
                                            scholarshipDetails.country_id,
                                            ).country_name
                                            : " "}{" "}</li>
                                        <li> Last Date: <b>{scholarshipDetails.last_date}</b></li>
                                        <li>
                                            <span className="text">Website:
                                                <a className="value" href={scholarshipDetails.website} style={{ 'color': 'blue' }} rel="noopener noreferrer" target="_blank">
                                                    Visit Website
                                                 </a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {scholarshipDetails.agent_information && (
                                    <div className={"mt-3 mr-5"}>
                                        <button
                                            className={"cs-btn-success"}
                                            onClick={() => { goToPersonalAgentProfile(scholarshipDetails.agent_information.id) }}
                                        >
                                            Apply with{" "}
                                            {scholarshipDetails.agent_information.name}
                                        </button>
                                        <br />
                                    </div>
                                )}
                                <div className="mt-3 mr-1">
                                    <Link to={`/agents?country_id=${scholarshipDetails.country_id}&category_id=1`}>
                                        <button className="cs-btn-success">Choose from Supervisor List</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ScholarshipDetails;