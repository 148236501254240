import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCopyright, faSquare} from "@fortawesome/free-solid-svg-icons"
import {Link} from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const HomeFooter = ({removeMenu}) => {
    let currentYear = new Date().getFullYear();
    return (
        <div className="footers ft_cover" onClick={removeMenu}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-4 col-12">
                        <div className="footerNav ft_cover footer_border_displ">
                            <Link to={'/'}><img src="./images/logo_final.png"/></Link>
                            <ul className="nav-widget">
                                <li>
                                    <Link to="/contact-us"><FontAwesomeIcon icon={faSquare}/> Contact Us</Link>
                                </li>
                                <li>
                                    <Link to={'/banking-details'}><FontAwesomeIcon icon={faSquare}/> Banking Details</Link>
                                </li>
                                <li>
                                    <Link to={'/our-mission'}><FontAwesomeIcon icon={faSquare}/> Our Mission</Link>
                                </li>
                                <li>
                                    <Link to={'/'}><FontAwesomeIcon icon={faSquare}/> Our Team</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-12">
                        <div className="footerNav ft_cover footer_border_displ">
                            {window.screen.width > 576 &&
                            <h1 style={{margin: "90px 0 0 0"}}></h1>
                            }
                            <ul className="nav-widget">
                                <li>
                                    <Link to={'/terms-of-service'}><FontAwesomeIcon icon={faSquare}/> Terms of service</Link>
                                </li>
                                <li>
                                    <Link to={'/privacy-policy'}><FontAwesomeIcon icon={faSquare}/> Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to={'/faq'}><FontAwesomeIcon icon={faSquare}/> FAQ´s</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-12">
                        <div className="footerNav ft_cover footer_border_display">
                            {window.screen.width > 576 &&
                            <h1 style={{margin: "90px 0 0 0"}}></h1>
                            }
                            <ul className="icon_list_news ft_cover">
                                <li>
                                    <a href="https://www.facebook.com/Supervisor4u-100249845604253"><FeatherIcon className="whiteText" icon="facebook"/></a>
                                </li>
                                <li>
                                    <a href="#"><FeatherIcon className="whiteText" icon="twitter"/></a>
                                </li>
                                <li>
                                    <a href="#"><FeatherIcon className="whiteText" icon="linkedin"/></a>
                                </li>
                                <li>
                                    <a href="#"><FeatherIcon className="whiteText" icon="youtube"/></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <h6 className="copyright_left">
                        <FontAwesomeIcon icon={faCopyright} /> {currentYear}<Link to={'/'}> Jaha Consultant </Link> All Rights Reserved.
                    </h6>
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="waveWrapper waveAnimation">
                <div className="waveWrapperInner bgTop gradient-color">
                    <div className="wave waveTop"></div>
                </div>
                <div className="waveWrapperInner bgMiddle">
                    <div className="wave waveMiddle"></div>
                </div>
                <div className="waveWrapperInner bgBottom">
                    <div className="wave waveBottom"></div>
                </div>
            </div>
        </div>


    );
}

export default HomeFooter
