import apiCall from "../helpers/apicall";
import { client } from "../helpers/client";
import { catcher } from "../helpers";

const CountryInfo = {
    async country(data) {
        let api = apiCall("country");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );

            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async supervisorCountry(data) {
        let api = apiCall("supervisor-countries");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );

            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async scholarshipCountry(data) {
        let api = apiCall("scholarship-countries");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );

            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async careerJobCountry(data) {
        let api = apiCall("career-job-countries");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );

            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
};

export default CountryInfo;
