import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css"
import "react-toastify/dist/ReactToastify.css"
import "react-image-lightbox/style.css"
import "bootstrap/dist/css/bootstrap.min.css"
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./App.css"
import Routes from "./routes";
import {ToastContainer} from "react-toastify";


const App = () => {
    return (
        <>
            <ToastContainer autoClose={3000}/>
            <Routes/>
        </>
    );
};

export default App;
