import React, {useEffect, useRef, useState} from "react";
import ProfileTitle from "../ProfileTitle";
import UserDetailsSidebar from "../UserDetailsSidebar";
import UserDetailsAccordion from "../UserDetailsAccordion";
import Loader from "../Spinner";
import UserInfo from "../../services/UserInfo";
import { useParams } from 'react-router-dom'
import {
    USER_AGENT_INFORMATION,
    USER_CERTIFICATION,
    USER_EDUCATION,
    USER_PROFESSION
} from "../../constants/UserProfileInfo";
import Storage from "../../helpers/storage";

const DashContent = (props) => {
    const [basicInfo, setBasicInfo] = useState({});
    const [accordionPageInfo, setAccordionPageInfo] = useState([]);
    const [showPage, setShowPage] = useState(false);
    const [authUser, setAuthUser] = useState({});
    const initialRender = useRef(true);
    let { id } = useParams();

    useEffect(() => {
        userInfo()
        getUserDetailsOf(USER_PROFESSION)
    }, []);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (authUser.roles.indexOf('ADMIN') >= 0 || authUser.user_id == id || !id ) {
                userBasicInfo()
            }
            setShowPage(true)
        }
    },[authUser])

    const userBasicInfo = async () => {
        setBasicInfo(await UserInfo.getBasic(id))
    }

    const userInfo = async () => {
        const user = await Storage.get('user')
        setAuthUser(user)
    }

    const getUserDetailsOf = async (key) => {
        let res = []
        switch (key) {
            case USER_AGENT_INFORMATION:
                res = await UserInfo.getAgentInfoById(id)
                break
            case USER_PROFESSION:
                res = await UserInfo.getProfessionById(id)
                break
            case USER_EDUCATION:
                res = await UserInfo.getEducationById(id)
                break
            case USER_CERTIFICATION:
                res.certification = await UserInfo.getCertificationById(id)
                res.examInfo = await UserInfo.getExamsById(id)
                break
        }
        setAccordionPageInfo(res)
    }

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <>
                    <ProfileTitle title={"User Details"} />
                    <div className="row">
                        {(authUser.roles.includes('ADMIN') || authUser.user_id == id || !id) &&
                            <UserDetailsSidebar data={basicInfo} />
                        }
                        <UserDetailsAccordion getUserDetailsOf={getUserDetailsOf}
                            accordionPageInfo={accordionPageInfo} />
                    </div>
                </>
            )}
        </>
    );
};

export default DashContent;
