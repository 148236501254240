import apiCall from "../helpers/apicall";
import { client } from "../helpers/client";
import { catcher } from "../helpers";

const AgentService = {
    async agentList(query) {
        let api = apiCall("get-agent-list", query);

        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchByUserId(id) {
        let api = apiCall("get-agent-by-user-id", {}, id);

        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data
        } catch (error) {
            return catcher(error);
        }
    },
};

export default AgentService;
