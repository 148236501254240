import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Navbar from "../../components/CommonNavbar"
import Storage from "../../helpers/storage";
import UserInfo from "../../services/UserInfo";
import {notify} from "../../helpers/notifyManager";

const JoinUs = () => {
    const [authUser, setAuthUser] = useState('')

    useEffect(() => {
        userInfo()
    }, [])

    const userInfo = async () => {
        const user = await Storage.get('user')
        setAuthUser(user)
    }

    const joinAsAffiliator = async () => {
        const response = await UserInfo.joinAsAffiliator()
        if (response.hasOwnProperty('user_id')) {
            setAuthUser(response)
            notify("success", "congratulation! You have successfully joined as our affiliator!");
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
    }

    return (
        <>
            {authUser &&
            <div className="joinus--page-wrapper">
                <Navbar/>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                Lorem ipsum dolor sit amet consectetur adipisicing
                                elit?
                            </h2>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <Link to="/user/profile/client">
                                <div className="single-card">
                                    <div className="card--image-container cs-ml">
                                        <img src="../images/client.svg" alt=""/>
                                    </div>

                                    <div className="card-content">
                                        <h3>
                                            {(authUser.roles.includes('CLIENT')) ?
                                                'Edit Client Profile' : 'Join as Client'
                                            }
                                        </h3>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <Link to="/user/profile/agent">
                                <div className="single-card">
                                    <div className="card--image-container cs-ml">
                                        <img src="../images/agent.svg" alt=""/>
                                    </div>
                                    <div className="card-content">
                                        <h3>
                                            {(authUser.roles.includes('AGENT')) ?
                                                'Edit Supervisor' : 'Join as Supervisor'
                                            }
                                        </h3>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4" onClick={() => joinAsAffiliator()} style={{cursor: 'pointer'}}>
                            <div className="single-card">
                                <div className="card--image-container">
                                    <img
                                        src="../images/affiliator.svg"
                                        alt=""
                                    />
                                </div>

                                <div className="card-content">
                                    <h3>
                                        {(authUser.roles.includes('AFFILIATOR')) ?
                                            'Edit Affiliator Profile' : 'Join as Affiliator'
                                        }
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            </>
    );
};

export default JoinUs;
