import React from 'react';

const SupportTicketList = ({ticketList, handleShow, handleUpdate}) => {
    return (
        <div className="col-lg-12 align-content-between">
            <div className={ticketList && ticketList.length > 1 ? "table-table-head align-items-center" : "none"}>
                <div className="column-head w-20">Name</div>
                <div className="column-head w-20">Email</div>
                <div className="column-head w-30">Subject</div>
                <div className="column-head w-20">View</div>
                <div className="column-head w-20">Status</div>
            </div>
            {ticketList.map((ticket, key) => {
                return (
                    <div className="table--content-box align-items-center" key={key}>
                        <div className="column w-20">{ticket.creator.name}</div>
                        <div className="column w-20">{ticket.creator.email}</div>
                        <div className="column w-30">{ticket.subject}</div>
                        <div className="column w-20">
                            <div>
                                <button className={'cs-btn-success'} onClick={() => handleShow(ticket.id)}>View
                                    Description
                                </button>
                            </div>
                        </div>
                        {ticket.status == 1 ? (
                            <div className="column w-20">
                                <div>
                                    <button className={'cs-btn-success'} onClick={() => handleUpdate(ticket.id)}>Mark
                                        as Closed
                                    </button>
                                </div>
                            </div>
                            ) : (
                            <div className="column w-20">Closed</div>
                        )

                        }
                    </div>
                )
            })}
        </div>
    );
}

export default SupportTicketList

