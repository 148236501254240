import React, {useEffect, useState, useRef} from 'react';
import {Link, useParams} from "react-router-dom";
import Loader from "../../components/Spinner";
import {
    FAILED,
    FileStatus,
    INITIATED,
    ONGOING,
    PAYMENT_PENDING,
    AGENT_PENDING,
    REJECTED,
    SUCCESS, CLIENT_PENDING
} from "../../constants/FileStatus";
import FileService from '../../services/FileService';
import {Countries} from '../../constants/Coutries'
import {TYPES} from '../../constants/VisaCategory'
import DayJS from 'react-dayjs';
import ChatArea from "../../components/ChatArea"
import ConversationService from "../../services/ConversationService";
import Storage from "../../helpers/storage";
import {notify} from "../../helpers/notifyManager";
import CountryInfo from "../../helpers/CountryInfo";

const FileDetails = () => {
    const {id} = useParams()
    const [showPage, setShowPage] = useState(false);
    const isMounted = useRef(false);
    const [authUser, setAuthUser] = useState({});
    const [selectedConversation, setSelectedConversation] = useState({});
    const [messages, setMessages] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [chatMessage, setChatMessage] = useState('');

    const [data, setData] = useState({
        id: id,
        status: "",
        attachment: ""
    })

    const [filesDetails, setFilesDetails] = useState({})


    useEffect(() => {
        userInfo()
        fetchFilesById(id)
        fetchConversations()
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            addConversation()
        } else {
            isMounted.current = true;
        }

    }, [attachment])

    useEffect(() => {
        fetchMessages()
    }, [selectedConversation])


    const userInfo = async () => {
        const user = await Storage.get('user')
        setAuthUser(user)
    }

    const fetchFilesById = async (filesId) => {
        const response = await FileService.fetchFilesById(filesId)
        setFilesDetails(response)
        setShowPage(true)
    }

    const updateStatus = async (event) => {
        if (event) {
            event.preventDefault()
        }
        const formData = new FormData()
        formData.append('id', data.id)
        if (data.status != '') {
            formData.append('status', data.status)
        }
        formData.append('attachment', data.attachment)

        const response = await FileService.updateStatus(id, formData)
        if (response.status == "success") {
            notify("success", "File Status Updated! ");
            window.location.reload()
        } else {
            notify("warning", "Something went wrong! Please try again later. ");
        }
    }
    const handleFile = async (key) => {

        const obj = Object.assign(data, {status: key})
        setData(obj)
        await updateStatus()
        await fetchFilesById(id)
    }

    const fetchConversations = async () => {
        const response = await ConversationService.fetchConversationsByFileId(id)
        if (response.length > 0) {
            setSelectedConversation(response[0])
        }
    }

    const fetchMessages = async () => {
        if (selectedConversation && selectedConversation.hasOwnProperty('id')) {
            const response = await ConversationService.fetchConversationMessages(selectedConversation.id)
            setMessages(response)
        }
    }

    const addConversation = async () => {
        if (chatMessage.length < 1 && !attachment) {
            alert('Please add your text!')
            return
        }
        const formData = new FormData()
        formData.append('message', chatMessage)
        formData.append('attachment', attachment)
        formData.append('conversation_id', selectedConversation.id)

        const response = await ConversationService.postConversationMessage(formData)
        if (response.status == 'success') {
            await fetchMessages()
            setChatMessage('')
        } else {
            alert("Something went wrong! Please try again.")
        }
    }

    const downloadPDF = async () => {
        const pdf = await FileService.saveFileAsPdf(id)
        window.open(
            pdf,
            '_blank'
        )
    }
    const sendPaymentRequest = async (type) => {
        const response = await FileService.sendPaymentRequestToAdmin(id, type)
        if (response) {
            notify('success', 'A message has been send to ADMIN')
        }

    }

    return (
        <>
            {!showPage ? (
                <Loader/>
            ) : (
                <div className="">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="campaigns-card">
                                <div className="title-box d-flex justify-content-between">
                                    <div className="title-left">
                                        <h2>File List</h2>
                                    </div>
                                </div>
                                <div className="statusbar">
                                    <div className="step--indicator-container-dashboard">
                                        <div className="single--step-wrapper">
                                            <div id="completed-1"
                                                 className={"single-step step-space " + (filesDetails.status === REJECTED ? 'completed' : '')}>
                                                <img
                                                    src="/images/info.svg" alt="Information"/></div>
                                            <div className="single--step-content">
                                                <p>Rejected</p>
                                            </div>
                                        </div>
                                        <div className="single--step-wrapper">
                                            <div id="completed-2"
                                                 className={"single-step step-space " + (filesDetails.status === INITIATED ? 'completed' : '')}>
                                                <img
                                                    src="/images/info.svg" alt="Information"/></div>
                                            <div className="single--step-content">
                                                <p>Initiated</p>
                                            </div>
                                        </div>
                                        <div className="single--step-wrapper">
                                            <div id="completed-2"
                                                 className={"single-step step-space " + (filesDetails.status === AGENT_PENDING ? 'completed' : '')}>
                                                <img
                                                    src="/images/info.svg" alt="Information"/></div>
                                            <div className="single--step-content">
                                                <p>Pending</p>
                                            </div>
                                        </div>
                                        <div className="single--step-wrapper">
                                            <div id="completed-3"
                                                 className={"single-step step-space " + (filesDetails.status === PAYMENT_PENDING ? 'completed' : '')}>
                                                <img
                                                    src="/images/career.svg" alt="career"/></div>
                                            <div className="single--step-content">
                                                <p>Payment Pending</p>
                                            </div>
                                        </div>
                                        <div className="single--step-wrapper">
                                            <div id="completed-4"
                                                 className={"single-step step-space " + (filesDetails.status === ONGOING ? 'completed' : '')}>
                                                <img
                                                    src="/images/work.svg" alt=""/></div>
                                            <div className="single--step-content">
                                                <p>Ongoing</p>
                                            </div>
                                        </div>
                                        <div className="single--step-wrapper">
                                            <div id="completed-5"
                                                 className={"single-step step-space " + (filesDetails.status === SUCCESS ? 'completed' : '')}>
                                                <img
                                                    src="/images/certificate.svg" alt="certificate"/></div>
                                            <div className="single--step-content">
                                                <p>Success</p>
                                            </div>
                                        </div>
                                        <div className="single--step-wrapper">
                                            <div id="completed-6"
                                                 className={"single-step step-space " + (filesDetails.status === FAILED ? 'completed' : '')}>
                                                <img
                                                    src="/images/certificate.svg" alt="certificate"/></div>
                                            <div className="single--step-content">
                                                <p>Failed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {filesDetails.status === CLIENT_PENDING && authUser.user_id === filesDetails.client.id &&
                                <div className="col-md-12 mt-5">
                                    <div className="alert alert-warning" role="alert">
                                        <h5>
                                            <strong>Your file has been paused by you. </strong>
                                            Still you can send request to supervisor. Do you like to send request ?
                                            <span onClick={() => handleFile(AGENT_PENDING)}
                                                  style={{'cursor': 'pointer'}}
                                                  className="alert-link ml-5 mr-2"> Yes</span>
                                            • <span onClick={() => handleFile(CLIENT_PENDING)}
                                                    style={{'cursor': 'pointer'}}
                                                    className="alert-link ml-2">No</span></h5>

                                    </div>
                                </div>
                                }
                                {filesDetails.status === INITIATED && authUser.user_id === filesDetails.client.id &&
                                <div className="col-md-12 mt-5">
                                    <div className="alert alert-success" role="alert">
                                        <strong>Your file has been initiated. </strong> Would you
                                        like to send request to this supervisor ?
                                        <span onClick={() => handleFile(AGENT_PENDING)}
                                              style={{'cursor': 'pointer'}}
                                              className="alert-link ml-5 mr-2"> Yes</span>
                                        • <span onClick={() => handleFile(CLIENT_PENDING)}
                                                style={{'cursor': 'pointer'}}
                                                className="alert-link ml-2">No</span>

                                    </div>
                                </div>
                                }
                                {filesDetails.status === AGENT_PENDING && authUser.user_id === filesDetails.agent.id &&
                                <div className="col-md-12 mt-5">
                                    <div className="alert alert-success" role="alert">
                                        <strong> You have a request to be a Supervisor,</strong> would you like to
                                        process this file?
                                        <span onClick={() => handleFile(PAYMENT_PENDING)} style={{'cursor': 'pointer'}}
                                              className="alert-link ml-5 mr-2"> Yes</span>
                                        • <span onClick={() => handleFile(REJECTED)} style={{'cursor': 'pointer'}}
                                                className="alert-link ml-2">No</span>
                                    </div>
                                </div>
                                }

                                {filesDetails.status === PAYMENT_PENDING && authUser.user_id === filesDetails.client.id &&
                                <div className="alert alert-success mt-5" role="alert">
                                    <h5 className="mb-0"> Supervisor accepted your request,
                                        please pay the processing fee  <Link to={'/banking-details'}> Click Here for Bank details </Link>  and send payment
                                        copy to <br/>
                                    </h5>
                                    <hr/>
                                    <p>contact@supervisor4u.com or +8801517-833750, +8801674-335571 (WhatsApp, Imo, Bip) or
                                        +4915210501282 (WhatsApp, Imo, Bip)</p>
                                </div>
                                }
                                {(filesDetails.status === ONGOING || filesDetails.status === SUCCESS || filesDetails.status === FAILED) &&
                                <div className="alert alert-success mt-5" role="alert">
                                    <p>You can download your agreement pdf by
                                        <button onClick={() => downloadPDF()} className="cs-btn-success ml-3"> CLICKING
                                            HERE.</button>
                                    </p>
                                </div>
                                }
                                {filesDetails.status === FAILED && authUser.user_id === filesDetails.client.id &&
                                <div className="alert alert-success mt-5" role="alert">
                                    <p>You can send your Refund Request to ADMIN by
                                        <button onClick={() => sendPaymentRequest('Refund')}
                                                className="cs-btn-success ml-3"> CLICKING
                                            HERE.</button>
                                    </p>
                                </div>
                                }
                                {filesDetails.status === SUCCESS && authUser.user_id === filesDetails.agent.id &&
                                <div className="alert alert-success mt-5" role="alert">
                                    <p>You can send your Payment Request to ADMIN by
                                        <button onClick={() => sendPaymentRequest('Escrow')}
                                                className="cs-btn-success ml-3"> CLICKING
                                            HERE.</button>
                                    </p>
                                </div>
                                }

                                <div className="row mb-5">
                                    <div className="col-lg-4 col-md-4 col-xs-12 jobs-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Client</h3>
                                                {filesDetails.referer_account && authUser.roles.includes('ADMIN') &&
                                                <pre className="mb-3">
                                                        <b>Referer Information</b>
                                                        <ul className="list">
                                                            <li> Name: {filesDetails.referer_account.name}</li>
                                                            <li> Email: {filesDetails.referer_account.email}</li>
                                                        </ul>
                                                    </pre>
                                                }
                                                <ul className="list">
                                                    <li> Name: {filesDetails.client.name}</li>
                                                    <li> Client ID: {filesDetails.client.id}</li>
                                                    <li>
                                                        <span
                                                            className="text">Profession:{" "}<b>{filesDetails.client.profession}{" "}</b>{" "}</span>
                                                    </li>
                                                    <li>
                                                        <span className="text">
                                                            Nationality:{" "}<b>{filesDetails.client.nationality ? CountryInfo(filesDetails.client.nationality,).country_name : " "}{" "}</b>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        {filesDetails.client.email ? (<>{" "}Email:<span
                                                            className="text"><b>{filesDetails.client.email} </b></span></>) : null}
                                                    </li>
                                                    <li>
                                                        {filesDetails.client.phone_no ? (
                                                            <>{" "}Phone no:<span
                                                                className="text"><b>{filesDetails.client.phone_no} </b></span></>
                                                        ) : null}
                                                    </li>
                                                    <li>
                                                    <span className="text">
                                                        {filesDetails.client.website ? (
                                                            <>
                                                                Website:
                                                                <a className="value" href={filesDetails.client.website}
                                                                   style={{color: "blue",}} rel="noopener noreferrer"
                                                                   target="_blank"
                                                                >
                                                                    Visit Website
                                                                </a>
                                                            </>
                                                        ) : null}
                                                    </span>
                                                    </li>
                                                    <li>
                                                        {filesDetails.client.address ? (
                                                            <span className="text"> Address:
                                                                <b>{" "}{filesDetails.client.address}</b>
                                                            </span>
                                                        ) : null}
                                                    </li>
                                                    <li>
                                                        <Link to={`/dashboard/users/${filesDetails.client.id}`}
                                                              className="cs-btn-success mt-3"> Click For Details</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-md-4 col-lg-4 jobs-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Supervisor</h3>
                                                <ul className="list">
                                                    <li> Name: {filesDetails.agent.name}</li>
                                                    <li> Supervisor ID: {filesDetails.agent.id}</li>
                                                    <li>
                                                        <span
                                                            className="text">Profession:{" "}<b>{filesDetails.agent.profession}{" "}</b>{" "}</span>
                                                    </li>
                                                    <li>
                                                        <span className="text">
                                                            Nationality:{" "}<b>{filesDetails.agent.nationality ? CountryInfo(filesDetails.agent.nationality,).country_name : " "}{" "}</b>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        {filesDetails.agent.email ? (<>{" "}Email:<span
                                                            className="text"><b>{filesDetails.agent.email} </b></span></>) : null}
                                                    </li>
                                                    <li>
                                                        {filesDetails.agent.phone_no ? (
                                                            <>{" "}Phone no:<span
                                                                className="text"><b>{filesDetails.agent.phone_no} </b></span></>
                                                        ) : null}
                                                    </li>
                                                    <li>
                                                    <span className="text">
                                                        {filesDetails.agent.website ? (
                                                            <>
                                                                Website:
                                                                <a className="value" href={filesDetails.agent.website}
                                                                   style={{color: "blue",}} rel="noopener noreferrer"
                                                                   target="_blank"
                                                                >
                                                                    Visit Website
                                                                </a>
                                                            </>
                                                        ) : null}
                                                    </span>
                                                    </li>
                                                    <li>
                                                        {filesDetails.agent.address ? (
                                                            <span className="text"> Address:
                                                                <b>{" "}{filesDetails.agent.address}</b>
                                                            </span>
                                                        ) : null}
                                                    </li>
                                                    <li>
                                                        <Link to={`/dashboard/users/${filesDetails.agent.id}`}
                                                              className="cs-btn-success mt-3"> Click For Details</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-md-4 col-lg-4 jobs-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">File Info</h3>
                                                <p dangerouslySetInnerHTML={{ __html: filesDetails.description }} />
                                                <ul className="list">
                                                    <li> Fee: {filesDetails.fee}</li>
                                                    <li> Processing Time: {filesDetails.processing_time}</li>
                                                    <li> No Refundable Fee: {filesDetails.non_refundable_fee}</li>
                                                    <li>Responsibilities: {filesDetails.responsibilities && filesDetails.responsibilities.length > 0
                                                    && filesDetails.responsibilities.map((item, key) => {
                                                        return (
                                                            <ul key={key}
                                                                style={{listStyleType: "circle", marginLeft: "40px"}}>
                                                                <li>{item}</li>
                                                            </ul>
                                                        )
                                                    })}</li>

                                                    <li>Country: {filesDetails.country_id ? CountryInfo(filesDetails.country_id).country_name : " "}</li>
                                                    <li>Visa
                                                        Category: {TYPES[filesDetails.visa_category_id]}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row mb-5">
                                        <div className="col-lg-4 col-md-4 col-xs-12 jobs-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h3 className="card-title">File Document history</h3>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>File Name</th>
                                                            <th>Created at</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {filesDetails.attachment && filesDetails.attachment.slice(0).reverse().map((value, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <a href={value.source_path} target="_blank"
                                                                           className="text-primary"
                                                                           rel="noopener noreferrer">{value.source} </a>
                                                                    </td>
                                                                    <td>
                                                                        <DayJS
                                                                            format="MM-DD-YYYY">{value.created_at}</DayJS>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        {(filesDetails.status !== PAYMENT_PENDING || authUser.roles.includes('ADMIN')) &&
                                        <div className="col-lg-4 col-md-4 col-xs-12 jobs-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h3 className="card-title">Update Status</h3>
                                                    <form className="row" onSubmit={updateStatus}>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Upload File</label>
                                                                <input id="file-input" type="file"
                                                                       accept={'image/jpeg,image/jpg,image/png,application/pdf, zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'}
                                                                       onChange={(e) => setData({
                                                                           ...data,
                                                                           attachment: e.target.files[0],
                                                                       })}/>
                                                            </div>
                                                        </div>
                                                        {authUser.user_id !== filesDetails.client.id &&
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Update Status</label>
                                                                {(authUser.roles.includes('ADMIN')) ?
                                                                    <select
                                                                        required
                                                                        className="form-control dropdown"
                                                                        onChange={(e) =>
                                                                            setData({
                                                                                ...data,
                                                                                status: e.target.value,
                                                                            })
                                                                        }
                                                                        value={data.status}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        {Object.values(FileStatus).map((status, key) => {
                                                                            return (
                                                                                <option
                                                                                    key={key}
                                                                                    value={key}
                                                                                >
                                                                                    {status}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select> : <select
                                                                        required
                                                                        className="form-control dropdown"
                                                                        onChange={(e) =>
                                                                            setData({
                                                                                ...data,
                                                                                status: e.target.value,
                                                                            })
                                                                        }
                                                                        value={data.status}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        <option value="3">PAYMENT_PENDING</option>
                                                                        <option value="6">FAILED</option>
                                                                        <option value="5">SUCCESS</option>
                                                                    </select>}
                                                                <div className="form-group col-lg-3 col-md-3">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <button type="submit"
                                                                    className="cs-btn btn--agent-submit center">Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <div className="col-lg-4 col-md-4 col-xs-12 jobs-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h3 className="card-title">Payments</h3>
                                                    <div className="table table-bordered">
                                                        <div className="table-table-head">
                                                            <div className="column-head w-20">Type</div>
                                                            <div className="column-head w-30">Amount</div>
                                                            <div className="column-head w-20">Status</div>
                                                            <div className="column-head w-30">Payment Type</div>
                                                        </div>
                                                        {filesDetails.payments && filesDetails.payments.length > 0 && filesDetails.payments.map((value, key) => {
                                                            return (
                                                                <div className="table-bordered" key={key}>
                                                                    <div className="table-responsive-sm">

                                                                        <div className="table--content-box">
                                                                            <div className="column w-20">
                                                                                {value.account_type}
                                                                            </div>
                                                                            <div className="column text-primary w-30">
                                                                                {value.amount_paid}
                                                                            </div>
                                                                            <div className="column text-success w-20">
                                                                                {value.payment_status}
                                                                            </div>
                                                                            <div className="column text-success w-30">
                                                                                {value.payment_type}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            {
                                (authUser.user_id === filesDetails.agent.id || authUser.roles.includes('ADMIN')) &&
                                <ChatArea showSendMessageBox={false} conversation={selectedConversation}
                                          conversationMessages={messages} authUser={authUser}
                                          setAttachment={setAttachment} message={chatMessage}
                                          setMessage={setChatMessage} addConversation={addConversation}/>
                            }
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default FileDetails
