import React from 'react';
import { USER_AGENT_INFORMATION, USER_CERTIFICATION, USER_EDUCATION, USER_PROFESSION } from "../../constants/UserProfileInfo";

const AccordionContent = (props) => {
    return (
        <div className="card-box custom--card-box">
            <ul className="nav nav-pills navtab-bg nav-justified">
                <li className="nav-item col-sm-12 pr-0 pl-0">
                    <span style={{ cursor: 'pointer' }} id={USER_PROFESSION} data-toggle="tab" aria-expanded="false" className="nav-link" onClick={props.select}>
                        Professional
                    </span>
                </li>
                <li className="nav-item col-sm-12 pr-0 pl-0">
                    <span style={{ cursor: 'pointer' }} id={USER_EDUCATION} data-toggle="tab" aria-expanded="true" className="nav-link" onClick={props.select}>
                        Educational
                    </span>
                </li>
                <li className="nav-item col-sm-12 pr-0 pl-0">
                    <span style={{ cursor: 'pointer' }} id={USER_CERTIFICATION} data-toggle="tab" aria-expanded="false" className="nav-link" onClick={props.select}>
                        Certification
                    </span>
                </li>
                <li className="nav-item col-sm-12 pr-0 pl-0">
                    <span style={{ cursor: 'pointer' }} id={USER_AGENT_INFORMATION} data-toggle="tab" aria-expanded="false" className="nav-link" onClick={props.select}>
                        Supervisor
                    </span>
                </li>
            </ul>
        </div>
    );
}

export default AccordionContent
