import Spinner from "react-bootstrap/Spinner";
import React from 'react'

const Loader = () => (
  <div style={center}>
    <Spinner
      style={{ marginRight: "2px" }}
      animation="grow"
      variant="primary"
    />
    <Spinner
      style={{ marginRight: "2px" }}
      animation="grow"
      variant="success"
    />
    <Spinner style={{ marginRight: "2px" }} animation="grow" variant="danger" />
    <Spinner style={{ marginRight: "2px" }} animation="grow" variant="warning" />
  </div>
);
const center = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-100px'
}
export default Loader