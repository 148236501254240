import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Storage from "../../helpers/storage";
import { FALLBACK_URL } from "../../constants/Auth";
import storage from "../../helpers/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, } from "@fortawesome/free-solid-svg-icons"

const Navbar = () => {
    const login = async () => {
        const url = window.location.href
        await storage.set(FALLBACK_URL, url, true)
        window.location.href = '/login'
    }

    const topNav = 500;

    function fixNav() {
        if (window.scrollY >= topNav) {
            document.body.classList.add("fixed-nav");
            if (window.screen.width > 576) {
                document.getElementById("clogo").style.display = "none"
            }
        } else {
            document.body.classList.remove("fixed-nav");
            document.getElementById("clogo").style.display = "block"

            if (document.getElementById("cmenubar")) {
                document.getElementById("cmenubar").classList.remove("block");
            }
        }
    }

    useEffect(() => {
        fixNav()

        // window.addEventListener("scroll", fixNav);

        // return () => { window.removeEventListener('scroll', fixNav) }

    }, [])

    const toggleMenu = () => {
        document.getElementById("cmenubar").classList.toggle("block");
    }


    return (
        <header>
            <div className="mobile--menu-icon" onClick={toggleMenu}>
                {window.screen.width < 576 &&
                    <FontAwesomeIcon icon={faBars} size={window.screen.width > 576 ? "2x" : "x"} className="trend-icon" />
                }

            </div>
            <div className="menu-wrapper" style={{ background: 'rgba(0, 0, 0, .3)' }}>
                <nav className="menu container" >
                    <div id="clogo" className="logo-container">
                        <a href="/">
                            {window.screen.width > 576 &&
                                <img src="/images/logo_final.png" />
                            }

                            {window.screen.width < 576 &&
                                <img src="/images/blackbg_rhdpi.png" />
                            }
                        </a>
                    </div>
                    <ul className="menu-items" id="cmenubar">
                        <li className="list-item"><Link to="/">Home</Link></li>
                        <li className="list-item"><Link to="/scholarships">Scholarships</Link></li>
                        <li className="list-item"><Link to="/jobs">Jobs</Link></li>
                        <li className="list-item">
                            {
                                Storage.has('user') ? <Link to={`/dashboard`} className="btn-signup">
                                    Dashboard
                                </Link> : <button type="button" onClick={login} className="btn-signup">
                                    Login
                                </button>
                            }

                        </li>
                    </ul>
                </nav>
            </div>
        </header>


    );
}

export default Navbar
