import React from "react";
import { Link } from "react-router-dom";

const DashboardPaymentList = ({ paymentList }) => {
    return (
        <div className="col-lg-12">
            <div className="table-responsive-sm text-center">
                <div className={paymentList && paymentList.length > 1 ? "table-table-head align-items-center" : "none"}>
                    <div className="column-head w-10">File</div>
                    <div className="column-head w-20">Amount Paid</div>
                    <div className="column-head w-25">Payment Type</div>
                    <div className="column-head w-25">Payment Status</div>
                    <div className="column-head w-15">Transaction ID</div>
                    <div className="column-head w-10">Receiver Amount</div>
                    <div className="column-head w-10">Organization Amount</div>
                </div>
                {paymentList && paymentList.map((payment, key) => {
                    return (
                        <div
                            className="table--content-box align-items-center"
                            key={key}
                        >
                            <div className="column w-5"><Link to={`files/${payment.file_id}`}> {payment.file_id} </Link></div>
                            <div className="column text-info w-25">
                                {payment.amount_paid}
                            </div>
                            <div className="column text-primary w-25">
                                {payment.payment_type}
                            </div>
                            <div className="column text-success w-25">
                                {payment.payment_status}
                            </div>
                            <div className="column text-warning w-15">
                                {payment.transaction_id}
                            </div>
                            <div className="column w-10">
                                {payment.receiver_amount}
                            </div>
                            <div className="column w-10">
                                {payment.organization_amount}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DashboardPaymentList;
