import apiCall from "../helpers/apicall";
import { client } from "../helpers/client";
import { catcher } from "../helpers";

const VisaCategoryService = {
    async fetchCategories(data) {
        let api = apiCall("get-visa-categories");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );

            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
};

export default VisaCategoryService;
