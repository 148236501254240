import { notify } from "./notifyManager";
import React from "react";
const validUrl = require('valid-url');

export let objectToFormData = (data) => {
  let formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export let convertDate = (data) => {
  let dateTime = new Date(data);
  return (
    dateTime.getDate() +
    "-" +
    (dateTime.getMonth() + 1) +
    "-" +
    dateTime.getFullYear()
  );
};

export let displayDateTime = (data) => {
  let dateTime = new Date(data);
  return dateTime.toUTCString();
};


export let formatDateInput = (data) => {
  if (!data) return data;
  let dateTime = new Date(data);
  return `${dateTime.getFullYear()}-${("0" + (dateTime.getMonth() + 1)).slice(
    -2
  )}-${("0" + dateTime.getDate()).slice(-2)}`;
};

export let now = () => {
  let dateTime = new Date();
  // return (dateTime.getFullYear()+'-'+(dateTime.getMonth()+1)+'-'+dateTime.getDate())
  // '1984-01-18' date type input
  // 'YYYY-MM-DD' laravel input
  return `${dateTime.getFullYear()}-${("0" + (dateTime.getMonth() + 1)).slice(
    -2
  )}-${("0" + dateTime.getDate()).slice(-2)}`;
};


export let dateForHuman = (data) => {
  let dateTime = new Date(data);
  return dateTime.toUTCString().split(" ").slice(0, 4).join(" ");
  // return dateTime.toLocaleDateString().split(' ').slice(0, 4).join(' ');
};

export let showImage = (file) => {
  let reader = new FileReader();
  let ImageSrc = null;
  if (file) {
    reader.onloadend = () => {
      // setShowFileImage(reader.result)
      ImageSrc = reader.result;
    };
    reader.readAsDataURL(file);
  }

  return ImageSrc;
};

export let catcher = (error) => {
  if (error.response) {
    notify("error", error.response.data.message)
    if (error.response.data.hasOwnProperty("errors")) {
      return error.response.data.errors
    }
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
  console.log(error);
  return false
}

export let objectDate = (date) => {
  if (!date) return "";
  if (typeof (date) == 'string') {
    let arr = date.split("-")
    date = {}
    date.year = Number(arr[0])
    date.month = Number(arr[1])
    date.day = Number(arr[2])
  }
  return date
}

export let stringDate = (date) => {
  console.log(typeof date);
  if (!date) return "";
  return `${date.year}-${date.month}-${date.day}`;
}

export let showLocalImage = (url) => {
  let src = ''
  if (validUrl.isUri(url)) {
    src = url
  } else {
    src = process.env.REACT_APP_API_URL + url
  }
  try {
    let extension = get_url_extension(url)
    if (extension == 'pdf' || extension == 'zip') {
      return <a className='btn btn-default' href={src} >Download {extension}</a>
    }
    return (
      <img
        src={src}
        alt="user-image"
        className="mr-3"
        width={150}
      />
    )
  } catch (e) {
    return ""
  }

}

export let get_url_extension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

export const commonAvatar = () => {
  return process.env.PUBLIC_URL + "/images/avatar.png"
}
