export const STUDENT = 'STUDENT';
export const JOB = 'JOB';
export const TOURIST = 'TOURIST';
export const HAJJ = 'HAJJ';
export const UMRAH = 'UMRAH';
export const FAMILY = 'FAMILY';
export const OTHER = 'OTHER';
export const MIGRATION = 'MIGRATION';
export const VISA_PROCESSING = 'VISA PROCESSING';

export const  TYPES = {
    1 : STUDENT,
    2 : JOB,
    3 : TOURIST,
    4 : HAJJ,
    5 : UMRAH,
    6 : FAMILY,
    7 : OTHER,
    8 : MIGRATION,
    9: VISA_PROCESSING
};