import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Errors from "../Errors";

const JobsModal = ({
                       handleClose,
                       handleSubmit,
                       handleChange,
                       values,
                       show,
                       errors,
                   }) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Payment Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>File ID</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter File ID"
                                    name="file_id"
                                    value={values.file_id}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Amount Paid</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Paid amount"
                                    name="amount_paid"
                                    value={values.amount_paid}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Payment Type</label>
                                <select className="form-control" name="payment_type" value={values.payment_type}
                                        onChange={handleChange}>
                                    <option value="">Select Payment Type</option>
                                    <option value="payment">Payment</option>
                                    <option value="refund">Refund</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Payment Status</label>
                                <select className="form-control" name="payment_status" value={values.payment_status}
                                        onChange={handleChange}>
                                    <option value="">Select Payment Status</option>
                                    <option value="paid">Paid</option>
                                    <option value="partial">Partial</option>
                                </select>
                            </div>
                        </div>
                        {/*<div className="col-lg-6 col-md-6 col-sm-12">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>Currency ID</label>*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            className="form-control"*/}
                        {/*            placeholder="Enter ID"*/}
                        {/*            name="currency_id"*/}
                        {/*            value={values.currency_id}*/}
                        {/*            onChange={handleChange}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Receiver Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="$40000"
                                    name="receiver_amount"
                                    value={values.receiver_amount}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Organization Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="$40000"
                                    name="organization_amount"
                                    value={values.organization_amount}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Payment Type</label>
                                <select className="form-control" name="account_type" value={values.account_type}
                                        onChange={handleChange}>
                                    <option value="">Select Account Type</option>
                                    <option value="escrow">Escrow</option>
                                    <option value="payment">Payment</option>


                                </select>
                            </div>
                        </div>

                    </div>
                    <Errors errorList={errors}/>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="float-right">
                            <Button className="button-modal-close cs-btn-remove" onClick={handleClose}>
                                Close
                            </Button>
                            <Button type="submit" onSubmit={handleSubmit}
                                    className={'button-modal-submit cs-btn-success'}>Submit</Button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default JobsModal;