import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Navbar from "../../components/CommonNavbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const HowItWorks = () => {

    const { id } = useParams();

    useEffect(() => {
        if (id == 1) {
            document.getElementById("super").click();
        } else if (id == 2) {
            document.getElementById("defaultOpen").click();

        } else if (id == 3) {
            document.getElementById("affiliator").click()
        }
        else {
            document.getElementById("affiliator").click();
        }

    }, []);

    const openClient = (e, clientName) => {

        var i, hiw_tabcontent, tablinks;
        hiw_tabcontent = document.getElementsByClassName(
            "hiw--tab-content",
        );
        for (i = 0; i < hiw_tabcontent.length; i++) {
            hiw_tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(
                " active",
                "",
            );
        }
        document.getElementById(clientName).style.display = "block";

        e.currentTarget.className += " active";
    }

    const openQuestion = (id) => {
        let accordions = document.getElementsByClassName("accordion-item");

        for (let i = 0; i < accordions.length; i++) {
            if (accordions[i].classList.contains("open")) {
                accordions[i].classList.remove("open")
                accordions[i].classList.add("closed")
            }
        }

        let accordion = document.getElementById(id);
        accordion.classList.remove("closed")
        accordion.classList.add("open")
    }

    return (
        <div>
            <Navbar />
            <div className="hiw-wrapper">
                <div className="tab-container">
                    <a href="javascript:void(0)" className="tablinks" onClick={(e) => openClient(e, 'client')} id="defaultOpen"><span className="hiw--m-hide">Steps for</span> Client</a>
                    <a href="#" className="tablinks" onClick={(e) => openClient(e, 'supervisor')} id="super"><span className="hiw--m-hide">Steps for</span> Supervisor
      </a>
                    <a href="#" className="tablinks" onClick={(e) => openClient(e, 'agent')}>
                        <span className="hiw--m-hide" id="affiliator">Steps for</span> Affiliator</a>
                </div>
            </div>
            {/* Start Client  */}
            <div id="client" className="hiw--tab-content">
                <div className="container">
                    {/* Section 1  */}
                    <div className="container extra-space">
                        <div className="row">
                            <div className="col-12">
                                <div className="fag--questions-wrapper accordion-two">

                                    <div className="fag--questions-container">
                                        <div key="1" id="1" className={`accordion-item closed`}
                                            onClick={() => openQuestion(1)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-1: Make your profile
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Complete your profile by filling basic information given in the
                                                    form. We would recommend to
                                                    provide information as much as possible so that Supervisor can
                                                    gather best possible knowledge on
                                                    you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fag--questions-container">
                                        <div key="2" id="2" className={`accordion-item closed`}
                                            onClick={() => openQuestion(2)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-2: Find appropriate Supervisor
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Find an appropriate Supervisor who can help you to fulfill your
                                                    dream. Read the information given by
                                                    the Supervisor carefully. If you have any questions or doubts you
                                                    can chat with the Supervisor
                                                    directly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="3" className={`accordion-item closed`}
                                            onClick={() => openQuestion(3)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-3: Send request to Supervisor to be your advisor
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Send request to Supervisor to be your advisor <br />
                                                    ❖ If Supervisor accept your request go to next step <br />
                                                    ❖ If Supervisor decline your request go to previous step
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="4" className={`accordion-item closed`}
                                            onClick={() => openQuestion(4)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-4: Send supervising fee
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Send supervising fee to Jaha Consultant account. Account details: (Link) <br />
                                                    Note: Jaha Consultant is only responsible for the amount of money, which is credited to tha Jaha Consultant accout.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="5" className={`accordion-item closed`}
                                            onClick={() => openQuestion(5)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-5: Upload money sending copy
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Send money sending copy to E-Mail: contact@supervisor4u.com or +8801517-833750, +8801674-335571 (WhatsApp, Imo)
                                                    or +4915210501282 (WhatsApp, Bip), we will send you a confirmation E-Mail within 2 working days,
                                                    after the supervising fee credited to Jaha Consultant account.
                                                    Note: We accept only Bank payment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="6" className={`accordion-item closed`}
                                            onClick={() => openQuestion(6)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-6: Communicate with Supervisor
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Communicate and follow the guideline of Supervisor during the
                                                    application process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="7" className={`accordion-item closed`}
                                            onClick={() => openQuestion(7)}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-7: Process ending
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    ❖ Successful: Confirm request from Supervisor to pay advisory cost
                                                    to end the process<br />
                                                    ❖ Unsuccessful: Apply for refund advisory cost via your profile and
                                                    wait for refund
                                                    Advisory cost will be refunded according to the agreement with
                                                    Supervisor within 2 working
                                                    days after confirmation from Supervisor
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End section 7 */}
                </div>
            </div>
            {/* End Client  */}
            {/* Start Supervisor  */}
            <div id="supervisor" className="hiw--tab-content">
                <div className="container">
                    {/* Section 1  */}
                    <div className="container extra-space">
                        <div className="row">
                            <div className="col-12">
                                <div className="fag--questions-wrapper accordion-two">
                                    <div className="fag--questions-container">
                                        <div key="1" id="supervisor1" className={`accordion-item closed`}
                                            onClick={() => openQuestion("supervisor1")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-1: Make your profile
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Complete your profile by filling basic information given in the
                                                    form. We would recommend to
                                                    provide information as much as possible so that Client can gather
                                                    best possible knowledge on you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fag--questions-container">
                                        <div key="2" id="supervisor2" className={`accordion-item closed`}
                                            onClick={() => openQuestion("supervisor2")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-2: Join as Supervisor
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Become a Supervisor by sending request to admin via your dashboard.
                                                    We recommend to provide as
                                                    much as information in service description on your service.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="supervisor3" className={`accordion-item closed`}
                                            onClick={() => openQuestion("supervisor3")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-3: Accept or decline request from Client
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Visit Client profile and decide if you can help Client to full-fill
                                                    his dream. <br />
                                                    ❖ If you accept the request to be a Supervisor of the Client go to
                                                    next step<br />
                                                    ❖ If you decline the request of Client the process is ended
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="supervisor4" className={`accordion-item closed`}
                                            onClick={() => openQuestion("supervisor4")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-4: Payment confirmation </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Before start the process make sure that supervisor4u confirms you that the advisory cost
                                                    is credited to Jaha Consultant account via E-Mail: contact@supervisor4u.com.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="supervisor5" className={`accordion-item closed`}
                                            onClick={() => openQuestion("supervisor5")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-5: Communicate with Client
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Communicate and help Client during the process according to
                                                    agreement. Make sure that the
                                                    process ends within defined processing time. According to terms and
                                                    conditions supervisor4u is
                                                    bound to refund the fee if the process does not end within
                                                    processing time.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="supervisor6" className={`accordion-item closed`}
                                            onClick={() => openQuestion("supervisor6")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-6: Process ending
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    ❖ Successful: send request to send advisory cost in your account
                                                    <br />
                                                    ❖ Unsuccessful: confirm request from Client to return the advisory cost
                                                    <br />
                                                    Advisory cost will be credited within 2 working days after confirmation from Client. <br/>
                                                    <b>Applicable Service Charge:</b> <br />
                                                    For a successful process supervisor4u.com charge 10% service charge from Supervisory fees.
                                                    Minimum service charge for supervisor4u.com is 2000BDT and maximum is 20000BDT.
                                                    For an unsuccessful/failure process supervisor4u.com charge 10% service charge from non-returnable fees.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Supervisor  */}
            {/* start Agent  */}
            <div id="agent" className="hiw--tab-content">
                <div className="container">
                    {/* Section 1  */}
                    <div className="container extra-space">
                        <div className="row">
                            <div className="col-12">
                                <div className="fag--questions-wrapper accordion-two">
                                    <div className="fag--questions-container">
                                        <div key="1" id="agent1" className={`accordion-item closed`}
                                            onClick={() => openQuestion("agent1")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-1: Make your profile
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Complete your profile by filling basic information given in the
                                                    form. We would recommend to
                                                    provide information as much as possible.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fag--questions-container">
                                        <div key="2" id="agent2" className={`accordion-item closed`}
                                            onClick={() => openQuestion("agent2")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-2: Join as Affiliator
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Become an Affiliator by clicking on “Join as Affiliator” in your
                                                    dashboard.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="agent3" className={`accordion-item closed`}
                                            onClick={() => openQuestion("agent3")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-3: Invite your friends
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    Invite your friends by clicking on “Create Client” in your
                                                    dashboard. Fill up necessary information and
                                                    invite him to become a Client.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fag--questions-container">
                                        <div id="agent4" className={`accordion-item closed`}
                                            onClick={() => openQuestion("agent4")}>
                                            <div className="accordion--item-heading odd-heading">
                                                <h4 className="pr-5">
                                                    Step-4: Get reward
                                                </h4>
                                                <FontAwesomeIcon icon={faAngleDown}
                                                    className="icon-animation" />
                                            </div>
                                            <div className="accordion--item-content">
                                                <p>
                                                    <b>Inviting Client-</b> Affiliator will be rewarded 30% of Jaha Consultant service charge fees from a successful process. <br />
                                                    <b>Inviting Supervisor-</b> Affiliator will be rewarded 30% of Jaha Consultant service charge fees from successful processes until he gets 10000BDT. <br />
                                                    No reward for Affiliator for an unsuccessful/ failure process. Jaha Consultant is not bound to pay Affiliators reward in cash.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default HowItWorks
