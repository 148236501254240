import React from 'react';
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import Navbar from "../../components/CommonNavbar"

const BankingDetails = () => {
    return (
        <>
            <Navbar/>
            <div className="job-wrapper" style={{
                backgroundImage: "url(/images/background.png)",
                minHeight: "100vh",
            }}>
                <div className="container job--list-wrapper">
                    <div className="about--text-content" style={{background: "#fff", padding: "30px", marginTop: "60px"}}>
                        <h3 className="card-title mb-5">Banking Details</h3>
                        <ul>
                            <li >
                                {/* <FontAwesomeIcon icon={faLocationArrow} /> */}
                                <p>
                                    <strong className="card-title">Islami Bank Bangladesh Limited:</strong> <br/>
                                    <b>A/C Name: </b> Jaha Consultant <br />
                                    <b>A/C Number:</b> 20507170100006109 <br />
                                   <b>Branch:</b> Konapara Sub-Branch, Dhaka.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BankingDetails;