import React, {useEffect, useState} from "react";
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import CountryInfo from "../../services/CountryInfo";
import Loader from "../../components/Spinner";
import AgentListCard from "../../components/AgentListCard";
import AgentService from "../../services/AgentService";
import VisaCategoryService from "../../services/VisaCategoryService";
import DataNotFound from "../../components/DataNotFound";
import useQueryParams from "../../helpers/useQueryParams";
import Navbar from "../../components/CommonNavbar"
import {TYPES} from "../../constants/VisaCategory";

const AgentList = () => {
    const queryParams = useQueryParams()
    const [country, setCountry] = useState([]);
    const [showPage, setShowPage] = useState(false);
    const [visaCategories, setVisaCategories] = useState([]);
    const [searchName, setSearchName] = useState(queryParams.get('name') ? queryParams.get('name') : '');
    const [searchCategory, setSearchCategory] = useState(queryParams.get('category_id') ? queryParams.get('category_id') : '');
    const [searchCountry, setSearchCountry] = useState(queryParams.get('country_id') ? queryParams.get('country_id') : '');
    const [agentList, setAgentList] = useState([]);

    useEffect(() => {
        fetchQueryParams()
        getCountry()
        fetchAgents()
        fetchVisaCategories()
    }, []);

    const fetchQueryParams = () => {
        if (queryParams.hasOwnProperty('country_id')) {
            setSearchCountry(queryParams.country)
        }
        if (queryParams.hasOwnProperty('category_id')) {
            setSearchCategory(queryParams.category)
        }
        if (queryParams.hasOwnProperty('name')) {
            setSearchName(queryParams.name)
        }
    }

    const getCountry = async () => {
        let response = await CountryInfo.supervisorCountry();
        if (response) {
            setCountry(response.data);
            setShowPage(true);
        }
    };

    const fetchAgents = async () => {
        let query = {};
        if (searchName.length > 0) Object.assign(query, {name: searchName});
        if (searchCategory.length > 0)
            Object.assign(query, {visa_category_id: searchCategory});
        if (searchCountry.length > 0)
            Object.assign(query, {visa_country_id: searchCountry});
        let agentList = await AgentService.agentList(query);
        setAgentList(agentList);
    };

    const fetchVisaCategories = async () => {
        let visa = []
        Object.keys(TYPES).map(( key) => {
            let obj = {
                id: key,
                name: TYPES[key]
            }
            visa.push(obj)
        })
        // let response = await VisaCategoryService.fetchCategories();
        setVisaCategories(visa);

        // if (response) {
        //     setShowPage(true);
        // }
    };

    const resetSearch = () => {
        setSearchName("");
        setSearchCategory("");
        setSearchCountry("");
        window.location = window.location.href.split("?")[0];
    };

    return (
        <>
            {!showPage ? (
                <Loader/>
            ) : (
                <div
                    className="job-wrapper"
                    style={{
                        backgroundImage: "url(/images/background.png)",
                        minHeight: "100vh",
                    }}
                >
                    <Navbar/>
                    <div className="container extra-space">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="search-panel">
                                    <div className="search-form">
                                        <h1>Find the Right Supervisor For You</h1>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search Supervisor Name"
                                                            onChange={(e) =>
                                                                setSearchName(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            value={searchName}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                searchCountry
                                                            }
                                                            onChange={(e) =>
                                                                setSearchCountry(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        >
                                                            <option value="">
                                                                Select Country
                                                            </option>
                                                            {country.map(
                                                                (country) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                country.id
                                                                            }
                                                                            value={
                                                                                country.id
                                                                            }
                                                                        >
                                                                            {
                                                                                country.country_name
                                                                            }
                                                                        </option>
                                                                    );
                                                                },
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            value={
                                                                searchCategory
                                                            }
                                                            onChange={(e) =>
                                                                setSearchCategory(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        >
                                                            <option value="">
                                                                Select Visa
                                                                Category
                                                            </option>
                                                            {visaCategories.map(
                                                                (category) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                category.id
                                                                            }
                                                                            value={
                                                                                category.id
                                                                            }
                                                                        >
                                                                            {
                                                                                category.name
                                                                            }
                                                                        </option>
                                                                    );
                                                                },
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-3">
                                                    <div className="form-group mb-0">
                                                        <button
                                                            type="button"
                                                            className="btn--apply-filter"
                                                            onClick={() =>
                                                                fetchAgents()
                                                            }
                                                        >
                                                            Apply Filter
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn--reset-all ml-3"
                                                            onClick={() =>
                                                                resetSearch()
                                                            }
                                                        >
                                                            Reset All
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {agentList.length > 0 ? (
                            <div className="job--list-wrapper">
                                <AgentListCard agentList={agentList}/>
                            </div>
                        ) : (
                            <div className="bg-white mb-5 mt-5">
                                <DataNotFound/>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default AgentList;
