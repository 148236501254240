import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Errors from "../Errors";

const FaqsModal = ({
                       handleClose,
                       handleSubmit,
                       handleChange,
                       values,
                       show,
                       errors
                   }) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Job Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Question</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Write a question"
                                    name="question"
                                    value={values.question}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Answer</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Answer"
                                    name="answer"
                                    value={values.answer}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <Errors errorList={errors}/>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="float-right">
                                <Button className="button-modal-close cs-btn-remove" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="submit" onSubmit={handleSubmit}
                                        className={'button-modal-submit cs-btn-success'}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default FaqsModal 