import apiCall from "../helpers/apicall"
import {client} from "../helpers/client"
import {catcher} from "../helpers"
import Storage from "../helpers/storage";
import {TOKEN_NAME} from "../constants/Auth";

const CareerJobsService = {
    async fetchJobs(query) {
        let api = apiCall("get-jobs", query);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchDashboardJobs(query) {
        let api = apiCall("get-jobs", query);
        try {
            let header = Object.assign(api.header, {
                Authorization: `bearer ${await Storage.get(TOKEN_NAME)}`
            });
            const response = await client[api.method](api.url, header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchJobById(id) {
        let api = apiCall("get-jobs-by-id", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postJobs(data) {
        let api = apiCall("post-jobs");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async updateJobs(data, id) {
        let api = apiCall("update-jobs", {}, id)
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async deleteJobs(id) {
        let api = apiCall("delete-jobs", {}, id);
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async fetchSpecialities() {
        let api = apiCall("get-specialities");
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },

}

export default CareerJobsService