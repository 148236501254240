import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom'
import Storage from "../../helpers/storage";
import UserInfo from "../../services/UserInfo";
import { notify } from "../../helpers/notifyManager";

const Sidebar = ({ removeMenu }) => {
    const [authUser, setAuthUser] = useState('')

    useEffect(() => {
        userInfo()

        setTimeout(() => {
            getHeight()
        }, 1000)
    }, [])

    const getHeight = () => {
        let height = document.getElementById("two")
        if (height) {
            height = height.scrollHeight
            if (height == 57) {
                document.getElementById("one").style.minHeight = "85%"
            } else if (height == 16) {
                document.getElementById("one").style.minHeight = "93%"
            }
        }
    }

    const userInfo = async () => {
        const user = await Storage.get('user')
        setAuthUser(user)
    }

    const activeMenu = (event) => {
        let elements = document.getElementsByClassName("active-nav");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('active-nav');
        }
        if (event.target.id) {
            document.getElementById(event.target.id).classList.add("active-nav")
        }
    }

    const joinAsAffiliator = async () => {
        const response = await UserInfo.joinAsAffiliator()
        if (response.hasOwnProperty('user_id')) {
            setAuthUser(response)
            notify("success", "congratulation! You have successfully joined as our affiliator!");
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
    }

    return (
        <>
            {authUser &&
                <div onClick={removeMenu}>
                    {/* ========== Left Sidebar Start ========== */}
                    <div className="left-side-menu" id="sidebar">
                        <div className="part-one" data-simplebar id="one">

                            <div id="sidebar-menu">
                                <ul id="side-menu" className="main-opt">
                                    <li className="menu-title">Navigation</li>
                                    <li>
                                        <Link to="/dashboard" id="home" onClick={activeMenu} className="">
                                            <FeatherIcon icon="airplay" />
                                            <span>Dashboard</span>
                                        </Link>
                                    </li>
                                    <li className="menu-title mt-2">Apps</li>
                                    <li>
                                        <Link id="profile" to={`/dashboard/users/${authUser.user_id}`} onClick={activeMenu} className="">
                                            <FeatherIcon icon="user" />
                                            <span> Profile </span>
                                        </Link>
                                    </li>

                                    {
                                        (authUser.roles.includes('ADMIN')) &&
                                        <li>
                                            <Link id="profile" to="/dashboard/users" onClick={activeMenu} className="">
                                                <FeatherIcon icon="users" />
                                                <span> Users </span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        (authUser.roles.includes('AGENT') || authUser.roles.includes('ADMIN')) &&
                                        <li>
                                            <Link to="/dashboard/scholarships" id="scholarship" onClick={activeMenu}
                                                className="">
                                                <FeatherIcon icon="calendar" />
                                                <span> Scholarships </span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        (authUser.roles.includes('AGENT') || authUser.roles.includes('ADMIN')) &&
                                        <li>
                                            <Link to="/dashboard/jobs"
                                                data-toggle="collapse" id="jobs" onClick={activeMenu} className="">
                                                <FeatherIcon icon="shopping-cart" />
                                                <span> Jobs </span>

                                            </Link>
                                        </li>
                                    }
                                    {
                                        (authUser.roles.includes('ADMIN')) &&
                                        <li>
                                            <Link to="/dashboard/faqs"
                                                data-toggle="collapse" id="faq" onClick={activeMenu} className="">
                                                <FeatherIcon icon="mail" />
                                                <span> Faqs </span>
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        <Link to="/dashboard/support-ticket"
                                            data-toggle="collapse" id="support-ticket" onClick={activeMenu} className=""
                                        >
                                            <FeatherIcon icon="alert-triangle" />
                                            <span> Support Ticket </span>
                                            <span className="menu-arrow">
                                                <i
                                                    className="fa fa-angle-right"
                                                    aria-hidden="true"
                                                />
                                            </span>

                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/files"
                                            data-toggle="collapse" id="files" onClick={activeMenu} className=""
                                        >
                                            <FeatherIcon icon="book" />
                                            <span> Files </span>
                                            <span className="menu-arrow">
                                                <i
                                                    className="fa fa-angle-right"
                                                    aria-hidden="true"
                                                />
                                            </span>

                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/chat"
                                            data-toggle="collapse" id="chat" onClick={activeMenu} className=""
                                        >
                                            <FeatherIcon icon="mail" />
                                            <span> Chat </span>
                                            <span className="menu-arrow">
                                                <i
                                                    className="fa fa-angle-right"
                                                    aria-hidden="true"
                                                />
                                            </span>

                                        </Link>
                                    </li>
                                    {
                                        (authUser.roles.includes('ADMIN') || authUser.roles.includes('AFFILIATOR')) &&
                                        <li>
                                            <Link to="/dashboard/create-client"
                                                data-toggle="collapse" id="create-client" onClick={activeMenu}
                                                className="">
                                                <FeatherIcon icon="user" />
                                                <span> Create Client </span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        (authUser.roles.includes('ADMIN')) &&
                                        <li>
                                            <Link to="/dashboard/payment"
                                                data-toggle="collapse" id="payment" onClick={activeMenu} className="">
                                                <FeatherIcon icon="dollar-sign" />
                                                <span> Payment </span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        (authUser.roles.includes('ADMIN')) &&
                                        <li>
                                            <Link to="/dashboard/settings"
                                                data-toggle="collapse" id="settings" onClick={activeMenu} className="">
                                                <FeatherIcon icon="settings" />
                                                <span> Settings </span>
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                            {/* End Sidebar */}
                            <div className="clearfix" />
                        </div>

                        <div className="bottom-zero part-two" id="two">

                            <ul>
                                {!authUser.roles.includes('AFFILIATOR') &&
                                    <li className="btn--agent-submit"
                                        style={{ 'cursor': 'pointer', 'color': 'white', width: '240px', marginBottom: "3px" }}>
                                        <span onClick={() => joinAsAffiliator()}> Join as Affiliator </span>
                                    </li>
                                }
                                {
                                    !authUser.roles.includes('AGENT') &&
                                    <Link to="/user/profile/agent">
                                        <li className="btn--agent-submit"
                                            style={{ 'cursor': 'pointer', 'color': 'white', width: '240px' }}>
                                            <span> Join as Supervisor </span>
                                        </li>
                                    </Link>

                                }
                            </ul>

                        </div>


                    </div>

                </div>
            }
        </>
    );
};

export default Sidebar;
