import React from "react";
import SearchSelect from "../../components/Select"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Profession = ({ handleChange, itemKey, item, remove, country, handleDateChange }) => {

    const options = country.map((country) => {
        return {
            label: country.country_name,
            value: country.id
        }
    })

    return (
        <>
            <div className="additional-profession">
                <div className="row mb-4" id={itemKey}>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Designation<span style={{ 'color': 'red' }}>*</span></label>
                            <input
                                type="text"
                                id={`title-${itemKey}`}
                                className="form-control"
                                value={item.title}
                                placeholder="Example Engineer"
                                name={`title[${itemKey}]`}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Employer<span style={{ 'color': 'red' }}>*</span></label>
                            <input
                                type="text"
                                id={`institution-${itemKey}`}
                                className="form-control"
                                value={item.institution}
                                placeholder="Example inc ltd."
                                name={`institution[${itemKey}]`}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>Country<span style={{ 'color': 'red' }}>*</span></label>
                            <SearchSelect UniqueId={`location-${itemKey}`} SearchList={options} handleChange={handleChange} SelectedItem={item.location} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12" style={{ position: "relative" }}>
                        <div className="form-group">
                            <label>Start Date<span style={{ 'color': 'red' }}>*</span></label>
                            <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                                selected={item.start_date}
                                name={`start_date[${itemKey}]`}
                                onChange={date => handleDateChange(date, `start_date-${itemKey}`)}
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12" style={{ position: "relative" }}>
                        <div className="form-group">
                            <label>End Date</label>

                            <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                                selected={item.end_date ? item.end_date : ""}
                                name={`end_date[${itemKey}]`}
                                onChange={date => handleDateChange(date, `end_date-${itemKey}`)}
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                            />

                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                            <label>Responsibilities</label>
                            <textarea
                                className="form-control"
                                id={`description-${itemKey}`}
                                rows="3"
                                value={item.description}
                                placeholder="Max 250 words"
                                name={`description[${itemKey}]`}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="col-lg-12 col-sm-12">
                            <button
                                id="removeBtn"
                                className="cs-btn btn-remove"
                                onClick={(e) => remove(e, itemKey, item.id)}>
                                Remove{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profession;
