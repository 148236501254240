import React from "react";

const Footer = () => {
    return (
        <>
            <div>
                {/* Footer Start   */}
                <footer className="footer" id="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                2019 - © Design by <a href={'/'}>R.K.F.B</a>
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-right footer-links d-none d-sm-block">
                                    <a href={'/about-us'}>About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* end Footer */}
            </div>
        </>
    );
};

export default Footer;
