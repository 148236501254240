import React from 'react';
import { Link } from "react-router-dom";

const PostJob = ({ removeMenu }) => {
    return (
        <section className="job--post-wrapper ft_counter_overlay" onClick={removeMenu}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className="job-posting">
                            <h5>Employers</h5>
                            <h2 className="mb-3">Looking to Search a Job</h2>
                            <p className="mb-3">
                                We have end-to-end solutions that can keep up
                                with you and your standards.
                            </p>
                            <Link to={'/jobs'} className="btn--job-post">Search a job </Link>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5">
                        <div className="search--job-imgbox">
                            <img src="./images/right-side-2.png" alt="" style={{ width: '270px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
}

export default PostJob
