import React, { useEffect, useRef, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import SupportTicketModal from "../../components/SupportTicketModal"
import SupportTicketService from '../../services/SupportTicketService';
import SupportTicketList from "../../components/SupportTicketList"
import { notify } from "../../helpers/notifyManager";
import DescriptionModal from "../../components/DescriptionModal";
import Storage from "../../helpers/storage";

const SupportTicket = () => {

    const isMounted = useRef(false);

    const [authUser, setAuthUser] = useState({});
    const [show, setShow] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [ticketList, setTicketList] = useState();
    const [errors, setErrors] = useState({});
    const [description, setDescription] = useState("");
    const [message, setMessage] = useState("");
    const [attachment, setAttachment] = useState(null);
    const [supportTicketId, setSupportTicketId] = useState(null);

    const [values, setValues] = useState({
        subject: "",
        message: "",
        attachment: ""
    });

    useEffect(() => {
        userInfo()
        fetchTicket()
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            addDescription()
        } else {
            isMounted.current = true;
        }

    }, [attachment])

    const userInfo = async () => {
        const user = await Storage.get('user')
        setAuthUser(user)
    }


    const fetchTicket = async () => {
        let response = await SupportTicketService.fetchTicket();

        setTicketList(response)
        setShowPage(true)
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (event.target.name == 'attachment') {
            setValues({
                ...values,
                [name]: event.target.files[0],
            });
        }
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formData = new FormData()
        formData.append('subject', values.subject)
        formData.append('message', values.message)
        formData.append('attachment', values.attachment)

        let response = await SupportTicketService.postTicket(formData)

        if (response.status == "success") {
            notify("success", response.message);
            handleClose();
            await fetchTicket()
        } else {
            setErrors(response)
        }
    }

    const closeDescriptionModal = () => {
        setShowDescription(false);
    }

    const showDescriptionModal = async (id) => {
        await fetchDescription(id)
        setShowDescription(true);
    }

    const fetchDescription = async (id) => {
        let response = await SupportTicketService.getTicketMessages(id)
        setDescription(response.data)
        setSupportTicketId(id)
    }

    const addDescription = async () => {
        if (message.length < 1 && !attachment) {
            return
        }
        const formData = new FormData()
        formData.append('message', message)
        formData.append('attachment', attachment)
        formData.append('support_ticket_id', supportTicketId)
        const response = await SupportTicketService.postTicketMessage(formData)
        if (response.status == 'success') {
            await fetchDescription(supportTicketId)
            setMessage('')
            setAttachment(null)
        } else {
            alert("Something went wrong! Please try again.")
        }
    }

    const handleStatusUpdate = async (id) => {
        const response = await SupportTicketService.updateTicketStatus(id)
        if (response.status == 'success') {
            await fetchDescription(supportTicketId)
            setMessage('')
        } else {
            alert("Something went wrong! Please try again.")
        }
    }


    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="campaigns-card">
                                <div className="title-box d-flex justify-content-between">
                                    <div className="title-left">
                                        <h2>Support Ticket</h2>
                                    </div>
                                    <div className="title-right">
                                        <button className={'cs-btn-success'} onClick={handleShow}>Open a Ticket
                                        </button>
                                    </div>
                                </div>

                                <SupportTicketModal
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    values={values}
                                    show={show}
                                    errors={errors}
                                />

                            </div>
                        </div>
                        <SupportTicketList ticketList={ticketList} handleShow={showDescriptionModal}
                            handleUpdate={handleStatusUpdate} />
                    </div>
                    {
                        description &&
                        <DescriptionModal
                            addDescription={addDescription}
                            message={message}
                            setMessage={setMessage}
                            handleClose={closeDescriptionModal}
                            show={showDescription}
                            descriptions={description}
                            setAttachment={setAttachment}
                            authUser={authUser}
                        />
                    }

                </div>

            )}
        </>
    );
}

export default SupportTicket

