import React from 'react';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

const SearchSelect = ({ handleChange, UniqueId, SearchList, SelectedItem }) => {
  const location = useLocation();
    return (
        <div>
            <Select
                value={SearchList.filter(option => option.value == SelectedItem)}
                placeholder={location.pathname == '/' ? `Destination Country` : `Select Country`}
                options={SearchList}
                onChange={value => handleChange(value, UniqueId)}
            >
            </Select>
        </div>
    );
}

export default SearchSelect
