import React from 'react';
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import Navbar from "../../components/CommonNavbar"

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar/>
            <div className="job-wrapper" style={{
                backgroundImage: "url(/images/background.png)",
                minHeight: "100vh",
            }}>
                <div className="container job--list-wrapper">
                    <div className="about--text-content"
                         style={{background: "#fff", padding: "30px", marginTop: "60px"}}>
                        <h3 className="card-title mb-5">Privacy Policy</h3>
                        <div>
                            <p><strong>Privacy Policy</strong></p>
                            <p><span style={{fontWeight: 400}}>Updated on 2021-04-17</span></p>
                            <p><span style={{fontWeight: 400}}>The owner of supervisor4u.com is JAHA Consultant. JAHA Consultant is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by JAHA Consultant.</span></p>
                            <p><span style={{fontWeight: 400}}>This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application, JAHA Consultant. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</span></p>
                            <p><strong>Definitions and key terms</strong></p>
                            <p><span style={{fontWeight: 400}}>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</span></p>
                            <ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cookie:&nbsp;small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to JAHA Consultant, House: 25/14, Takwa road, Konapara, Demra, Dhaka-1362, Bangladesh that is responsible for your information under this Privacy Policy.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Country: where JAHA Consultant or the owners/founders of JAHA Consultant are based, in this case is BD</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Client:&nbsp;refers to the company, organization or person (e.g. Supervisor, Affiliator) that signs up to use the JAHA Consultant Service to manage the relationships with service providers or service users.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Device:&nbsp;any internet connected device such as a phone, tablet, computer or any other device that can be used to visit JAHA Consultant and use the services.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personnel:&nbsp;refers to those individuals who are employed by JAHA Consultant or are under contract to perform a service on behalf of one of the parties.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Service: refers to the service provided by JAHA Consultant as described in the Terms of Service and on this platform.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Third-party service:&nbsp;refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Website: site, which can be accessed via this URL: www.supervisor4u.com</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You: a person or entity that is registered with JAHA Consultant to use the Services.</span></li>
                            </ul>
                            <p><strong>What Information Do We Collect?</strong></p>
                            <p><span style={{fontWeight: 400}}>We collect information from you when you visit our website, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.</span></p>
                            <ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Name / Username</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Phone Numbers</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Email Addresses etc.</span></li>
                            </ul>
                            <p><strong>How Do We Use The Information We Collect?</strong></p>
                            <p><span style={{fontWeight: 400}}>Any of the information we collect from you may be used in one of the following ways:</span></p>
                            <ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To personalize your experience (your information helps us to better respond to your individual needs)</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To process transactions</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To administer a contest, promotion, survey or other site feature</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>To send periodic emails</span></li>
                            </ul>
                            <p><strong>When does JAHA Consultant use end user information from third parties?</strong></p>
                            <p><span style={{fontWeight: 400}}>JAHA Consultant will collect End User Data necessary to provide the JAHA Consultant services to our customers.</span></p>
                            <p><span style={{fontWeight: 400}}>End users may voluntarily provide us with information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</span></p>
                            <p><strong>When does JAHA Consultant use customer information from third parties?</strong></p>
                            <p><span style={{fontWeight: 400}}>We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming a JAHA Consultant client, we receive information from a third party that provides automated fraud detection services to JAHA Consultant. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</span></p>
                            <p><strong>Do we share the information we collect with third parties?</strong></p>
                            <p><span style={{fontWeight: 400}}>We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.</span></p>
                            <p><span style={{fontWeight: 400}}>We may engage trusted third-party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and the website, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through the website. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.</span></p>
                            <p><span style={{fontWeight: 400}}>We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the website in a shared location, and type of the device used to visit the website. They may aggregate information about our advertising and what you see on the website and then provide auditing, research and reporting for us and our advertisers.</span></p>
                            <p><span style={{fontWeight: 400}}>We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.</span></p>
                            <p><strong>Where and when is information collected from customers and end users?</strong></p>
                            <p><span style={{fontWeight: 400}}>JAHA Consultant will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above.</span></p>
                            <p><strong>How Do We Use Your Email Address?</strong></p>
                            <p><span style={{fontWeight: 400}}>By submitting your email address on this website, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</span></p>
                            <p><strong>How Long Do We Keep Your Information?</strong></p>
                            <p><span style={{fontWeight: 400}}>We keep your information only so long as we need it to provide JAHA Consultant to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can’t identify you.</span></p>
                            <p><strong>How Do We Protect Your Information?</strong></p>
                            <p><span style={{fontWeight: 400}}>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to JAHA Consultant or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</span></p>
                            <p><strong>Could my information be transferred to other countries?</strong></p>
                            <p><span style={{fontWeight: 400}}>JAHA Consultant is incorporated in BD. Information collected via our website, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans-border transfer and hosting of such information.</span></p>
                            <p><strong>Is the information collected through the JAHA Consultant Service secure?</strong></p>
                            <p><span style={{fontWeight: 400}}>We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty.</span></p>
                            <p><strong>Can I update or correct my information?</strong></p>
                            <p><span style={{fontWeight: 400}}>Clients can update or correct their information from their own profile. JAHA Consultant has no access to edit Clients profile. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.</span></p>
                            <p><span style={{fontWeight: 400}}>You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.</span></p>
                            <p><strong>Personnel</strong></p>
                            <p><span style={{fontWeight: 400}}>If you are a JAHA Consultant worker or applicant, we collect information you voluntarily provide to us. We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants.</span></p>
                            <p><strong>Sale of Business</strong></p>
                            <p><span style={{fontWeight: 400}}>We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of JAHA Consultant or any of its Corporate Affiliates (as defined herein), or that portion of JAHA Consultant or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.</span></p>
                            <p><strong>Affiliates</strong></p>
                            <p><span style={{fontWeight: 400}}>We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, “Corporate Affiliate” means any person or entity which directly or indirectly controls, is controlled by or is under common control with JAHA Consultant, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.</span></p>
                            <p><strong>Governing Law</strong></p>
                            <p><span style={{fontWeight: 400}}>This Privacy Policy is governed by the laws of BD without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy.&nbsp;</span></p>
                            <p><span style={{fontWeight: 400}}>The laws of BD, excluding its conflicts of law rules, shall govern this Agreement and your use of the website. Your use of the website may also be subject to other local, state, national, or international laws.</span></p>
                            <p><span style={{fontWeight: 400}}>By using JAHA Consultant or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.</span></p>
                            <p><strong>Your Consent</strong></p>
                            <p><span style={{fontWeight: 400}}>We’ve updated our&nbsp;Privacy Policy&nbsp;to provide you with complete transparency into what is being set when you visit our site and how it’s being used. By using our website, registering an account, you hereby consent to our Privacy Policy and agree to its terms.</span></p>
                            <p><strong>Links to Other Websites</strong></p>
                            <p><span style={{fontWeight: 400}}>This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by JAHA Consultant. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. You’re browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</span></p>
                            <p><strong>Cookies</strong></p>
                            <p><span style={{fontWeight: 400}}>JAHA Consultant uses “Cookies” to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</span></p>
                            <p><strong>Blocking and disabling cookies and similar technologies</strong></p>
                            <p><span style={{fontWeight: 400}}>Wherever you’re located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g., saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser’s help menu for more information.</span></p>
                            <p><strong>Changes to Our Privacy Policy</strong></p>
                            <p><span style={{fontWeight: 400}}>We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account.</span></p>
                            <p><strong>Third-Party Services</strong></p>
                            <p><span style={{fontWeight: 400}}>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services (“Third- Party Services”).</span></p>
                            <p><span style={{fontWeight: 400}}>You acknowledge and agree that JAHA Consultant shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. JAHA Consultant does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</span></p>
                            <p><span style={{fontWeight: 400}}>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.</span></p>
                            <p><strong>Tracking Technologies</strong></p>
                            <ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Google Maps API</span></li>
                                <ul>
                                    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Google Maps API&nbsp;is a robust tool that can be used to create a custom&nbsp;map, a searchable&nbsp;map, check-in functions, display live data synching with location, plan routes, or create a mashup just to name a few.</span></li>
                                    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Google Maps API may collect information from You and from Your Device for security purposes.</span></li>
                                    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Google Maps API collects information that is held in accordance with its Privacy Policy</span></li>
                                </ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cookies</span></li>
                                <ul>
                                    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>We use Cookies to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously.</span></li>
                                </ul>
                            </ul>
                            <p><strong>What is personal data?</strong></p>
                            <p><span style={{fontWeight: 400}}>Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information that could be used on its own, or in combination with other pieces of information, to identify a person. Personal data extends beyond a person’s name or email address. Some examples include financial information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity.</span></p>
                            <p><span style={{fontWeight: 400}}>The Data Protection Principles include requirements such as:</span></p>
                            <ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personal data collected must be processed in a fair, legal, and transparent way and should only be used in a way that a person would reasonably expect.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personal data should only be collected to fulfil a specific purpose and it should only be used for that purpose. Organizations must specify why they need the personal data when they collect it.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Personal data should be held no longer than necessary to fulfil its purpose.</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>People covered by the GDPR have the right to access their own personal data. They can also request a copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.</span></li>
                            </ul>
                            <p><strong>Why is GDPR important?</strong></p>
                            <p><span style={{fontWeight: 400}}>GDPR adds some new requirements regarding how companies should protect individuals’ personal data that they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breach. Beyond these facts it’s simply the right thing to do. At Help Scout we strongly believe that your data privacy is very important and we already have solid security and privacy practices in place that go beyond the requirements of this new regulation.</span></p>
                            <p><strong>Individual Data Subject’s Rights – Data Access, Portability and Deletion</strong></p>
                            <p><span style={{fontWeight: 400}}>We are committed to helping our clients meet the&nbsp;data subject rights requirements of GDPR. JAHA Consultant processes or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.</span></p>
                            <p><span style={{fontWeight: 400}}>We are aware that if you are working with EU customers, you need to be able to provide them with the ability to access, update, retrieve and remove personal data. We got you! We’ve been set up as self-service from the start and have always given you access to your data and your clients data. Our clients&nbsp;support team is here for you to answer any questions you might have about working with the JAHA Consultant.</span></p>
                            <p><strong>Contact Us</strong></p>
                            <p><span style={{fontWeight: 400}}>Don’t hesitate to contact us if you have any questions.</span></p>
                            <ul>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Via Email:&nbsp;contact@supervisor4u.com</span></li>
                                <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Via Phone Number: +8801517833750</span></li>
                            </ul>
                        </div>
                    </div>

                    );
                }
                });
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
