import React from 'react';
import { Link } from "react-router-dom";
import { FileStatus } from "../../constants/FileStatus";
import { TYPES } from "../../constants/VisaCategory";


const DashboardFileList = ({ fileList }) => {

    return (
        <div className="col-lg-12 align-content-between table-responsive-sm">
            <div className={fileList && fileList.length > 0 ? "table-table-head align-items-center" : "none"}>
                <div className="column-head w-20">Clients Name</div>
                <div className="column-head w-20">Fee</div>
                <div className="column-head w-20">Visa Category</div>
                <div className="column-head w-20">Status</div>
            </div>
            {fileList && fileList.map((files) => {
                return (
                    <div className="table--content-box align-items-center" key={files.id}>
                        <div className="column w-20">{files.client.name}</div>
                        <div className="column w-20">{files.fee}</div>
                        <div className="column text-primary w-20">{files ? TYPES[files.visa_category_id] : ""}</div>
                        <div className="column text-primary w-20">{FileStatus[files.status]}</div>
                        <div className="column text-primary w-20">
                            <Link to={`files/${files.id}`}>
                                <button className="cs-btn-success">Click For Details</button>
                            </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default DashboardFileList
