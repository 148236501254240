import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import browserHistory from './history'

import RouteWithLayout from './RouteWithLayout'
import Dashboard from '../layouts/dashboard'
import Login from '../pages/Login'
import Signup from '../pages/SignUp'
import Affiliator from '../pages/Affiliator'
import JoinUs from '../pages/JoinUs'
import ClientInfo from '../pages/ClientInfo'
import AgentInfo from '../pages/AgentInfo'
import DashContent from '../components/DashContent'
import OurMission from '../pages/AboutUs'
import JobList from '../pages/Joblist'
import Faq from '../pages/Faq'
import Scholarships from '../pages/Scholarships'
import DashboardJobs from '../pages/DashboardJobs'
import JobDetails from '../pages/JobDetails'
import Chat from '../pages/Chat'
import DashboardFaqs from '../pages/DashboardFaqs'
import SupportTicket from '../pages/SupportTicket'
import DashboardFiles from '../pages/DashboardFiles'
import AgentList from "../pages/AgentList";
import DataNotFound from "../components/DataNotFound";
import Home from "../pages/Home"
import AgentDetails from "../pages/AgentDetails";
import ScholarshipHome from '../pages/ScholarshipHome'
import ScholarshipDetails from "../pages/ScholarshipDetails";
import FileDetails from "../pages/FileDetails"
import DashboardUsers from "../pages/DashboardUsers";
import DashboardHome from "../pages/DashboardHome";
import BannerText from "../pages/BannerText"
import CreateClient from "../pages/CreateClient"
import Payment from "../pages/Payment"
import HowItWorks from '../pages/HowItWorks'
import NotificationList from "../pages/NotificationList";
import PasswordReset from "../pages/PasswordReset";
import PasswordEmail from "../pages/PasswordEmail";
import ChangePassword from "../pages/ChangePassword";
import PrivacyPolicy from "../pages/PrivacyPolicy"
import OurTeam from "../pages/OurTeam"
import TermsOfService from "../pages/TermsOfService"
import BankingDetails from "../pages/BankingDetails"
import ContactUs from '../pages/ContactUs'


const Routes = () => {
    return (
        <Router history={browserHistory}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/password/email" component={PasswordEmail} />
                <Route exact path="/password/change" component={ChangePassword} />
                <Route exact path="/password/reset/:token" component={PasswordReset} />
                <Route exact path="/user/profile/client" component={ClientInfo} />
                <Route exact path="/user/profile/agent" component={AgentInfo} />
                <Route exact path="/affiliator" component={Affiliator} />
                <Route exact path="/user/join" component={JoinUs} />
                <Route exact path="/our-mission" component={OurMission} />
                <Route exact path="/jobs" component={JobList} />
                <Route exact path="/job-details/:id" component={JobDetails} />
                <Route exact path="/clients" component={JobList} />
                <Route exact path="/clients/:id" component={JobDetails} />
                <Route exact path="/scholarships" component={ScholarshipHome} />
                <Route exact path="/scholarships/:id" component={ScholarshipDetails} />
                <Route exact path="/agents" component={AgentList} />
                <Route exact path="/agents/:id" component={AgentDetails} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/how-it-works/:id" component={HowItWorks} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/our-team" component={OurTeam} />
                <Route exact path="/terms-of-service" component={TermsOfService} />
                <Route exact path="/banking-details" component={BankingDetails} />
                <Route exact path="/contact-us" component={ContactUs} />


                <RouteWithLayout
                    exact
                    component={Scholarships}
                    layout={Dashboard}
                    path='/dashboard/scholarships'
                />
                <RouteWithLayout
                    exact
                    component={DashboardJobs}
                    layout={Dashboard}
                    path='/dashboard/jobs'
                />

                <RouteWithLayout
                    exact
                    component={DashboardFaqs}
                    layout={Dashboard}
                    path='/dashboard/faqs'
                />

                <RouteWithLayout
                    exact
                    component={SupportTicket}
                    layout={Dashboard}
                    path='/dashboard/support-ticket'
                />

                <RouteWithLayout
                    component={DashContent}
                    exact
                    layout={Dashboard}
                    path='/dashboard/user'
                />
                <RouteWithLayout
                    component={DashboardFiles}
                    exact
                    layout={Dashboard}
                    path='/dashboard/files'
                />
                <RouteWithLayout
                    component={FileDetails}
                    exact
                    layout={Dashboard}
                    path='/dashboard/files/:id'
                />
                <RouteWithLayout
                    component={Chat}
                    exact
                    layout={Dashboard}
                    path='/dashboard/chat'
                />

                <RouteWithLayout
                    component={DashboardUsers}
                    exact
                    layout={Dashboard}
                    path='/dashboard/users'
                />
                <RouteWithLayout
                    component={DashContent}
                    exact
                    layout={Dashboard}
                    path='/dashboard/users/:id'
                />
                <RouteWithLayout
                    component={DashboardHome}
                    exact
                    layout={Dashboard}
                    path='/dashboard'
                />
                <RouteWithLayout
                    component={CreateClient}
                    exact
                    layout={Dashboard}
                    path='/dashboard/create-client'
                />
                <RouteWithLayout
                    component={BannerText}
                    exact
                    layout={Dashboard}
                    path='/dashboard/settings'
                />
                <RouteWithLayout
                    component={Payment}
                    exact
                    layout={Dashboard}
                    path='/dashboard/payment'
                />
                <RouteWithLayout
                    component={NotificationList}
                    exact
                    layout={Dashboard}
                    path='/dashboard/notifications'
                />
                <Route component={DataNotFound} />
            </Switch>
        </Router>
    )
}

export default Routes
