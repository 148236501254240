import React from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import Header from './header'
import Footer from "./footer"
import Sidebar from './sidebar'
import "./customdash.css"

const Dashboard = (props) => {
  const { children } = props;

  const removeMenu = () => {

    if (document.getElementsByClassName("dropdown-content show")) {
      document.getElementById("dropdown").classList.remove("show");
      document.getElementById("account").classList.remove("show");
    }
  }

  return (
    <div id="wrapper" >
      <ToastContainer style={{ zIndex: 99999 }} />
      <Header />
      <Sidebar removeMenu={removeMenu} />
      <div id="content-page" className="content-page" onClick={removeMenu}>
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {children}
          </div>
        </div>
        <Footer />
      </div>

    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
};



export default Dashboard;
