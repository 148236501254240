import React, { useEffect, useState } from "react";
import "./style.css";
import Multistep from "../../../layouts/multistep";
import Certificate from "../../../components/Certificate";
import { produce } from "immer";
import UserInfo from "../../../services/UserInfo";
import DataRequired from "../../../helpers/dataRequired";
import RemoveItem from "../../../helpers/removeItem";
import ForStudent from "../../../components/ForStudent";
import Errors from "../../../components/Errors";
import { CERTIFICATION, EXAM_SCORE } from "../../../constants/UserInformation";
import { notify } from "../../../helpers/notifyManager";
import history from "../../../routes/history";
import Navbar from "../../../components/CommonNavbar";
import dayjs from "dayjs";

const fields = {
    title: "",
    institution: "",
    last_date: "",
    description: "",
    info_type: CERTIFICATION
};

const studentFields = {
    title: "",
    score: "",
    info_type: EXAM_SCORE
};

const Certification = (props) => {
    const [values, setValues] = useState([fields]);
    const [exams, setExams] = useState([studentFields]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        view()
        examViewBtn()
        getCertification()
        getExams()
        document.getElementById("show-number").innerHTML = "Step 4 - 4";
        document.getElementById("completed-4").className += " completed";
    }, []);

    const view = () => {
        if ((values.length = 1)) {
            return (document.getElementById("removeBtn").style.display =
                "none");
        }
    };

    const examViewBtn = () => {
        return (document.getElementById("examRemoveBtn").style.display =
            "none");
    }

    const getCertification = async () => {
        let response = await UserInfo.getCertification();
        if (response.length > 0) {
            let payload = []
            response.map((value, key) => {
                let data = produce(response, draftState => {
                    draftState[key].start_date = draftState[key].start_date ? new Date(draftState[key].start_date): null
                    draftState[key].end_date = draftState[key].end_date ? new Date(draftState[key].end_date) : null
                })
                payload.push(data[key])
            })
            setValues(payload);
        }
    };

    const getExams = async () => {
        let response = await UserInfo.getExams();
        if (response.length > 0) {
            setExams(response);
        }
    };

    const handleExamChange = (event) => {
        const { value, id } = event.target;
        let arr = [];
        arr = id.split("-");

        setExams((exams) =>
            produce(exams, (v) => {
                v[arr[1]][arr[0]] = value;
            })
        );
    };

    const handleChange = (event) => {
        const { value, id } = event.target;
        let arr = [];
        arr = id.split("-");

        setValues((values) =>
            produce(values, (v) => {
                v[arr[1]][arr[0]] = value;
            })
        );
    };

    const handleDateChange = (date, item) => {
        let arr = [];
        arr = item.split("-");

        setValues((values) =>
            produce(values, (v) => {
                v[arr[1]][arr[0]] = date;
            })
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let final = Object.assign({}, values)
        final = Object.values(final)

        let payload = []
        final.map((value, key) => {
            let data = produce(final, draftState => {
                draftState[key].end_date = draftState[key].end_date ? dayjs(draftState[key].end_date).format('YYYY-MM-DD') : null
            })
            payload.push(data[key])
        })
        let res = await UserInfo.saveUserInfo(DataRequired(payload));

        if (typeof res == "object" && !res.hasOwnProperty("data")) {
            setErrors(res);
        } else if (res.hasOwnProperty("data") && res.status == "success") {
            if (await handleExamSubmit()) {
                history.push("/dashboard");
                notify('success', 'Successfully updated your information')
            } else {
                notify('error', 'Something went wrong! Please try again.')
            }
        }
    };

    const handleExamSubmit = async () => {
        if (!exams[0].title) {
            return true
        }
        let res = await UserInfo.saveUserInfo(DataRequired(exams));
        if (typeof res == "object" && !res.hasOwnProperty("data")) {
            setErrors(res);
        } else if (res.hasOwnProperty("data") && res.status == "success") {
            return true
        }
    };

    const addMoreCertificateForm = () => {
        setValues([...values, fields]);
    };

    const addMoreExamForm = () => {
        setExams([...exams, studentFields]);
    };

    const remove = async (event, itemKey, id) => {
        event.preventDefault();
        if (id) {
            await UserInfo.deleteUserInfo(id);
        }
        setValues(RemoveItem(itemKey, values));
    };

    const removeExams = async (event, itemKey, id) => {
        event.preventDefault();
        if (id) {
            await UserInfo.deleteUserInfo(id);
        }
        setExams(RemoveItem(itemKey, exams));
    };

    return (
        <>
            <Navbar />
            <Multistep formType={props.formType}>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="">
                        {/* start title  section */}
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <h4 className="form-title">Certification/Training</h4>
                                <button
                                    type="button"
                                    onClick={() => {
                                        props.history.push('/dashboard')
                                    }}
                                    className="cs-btn btn-next-previous btn--next-ms mr-3 float-right"
                                    id="skipBtn"
                                >
                                    Skip
                                </button>
                            </div>
                        </div>
                        {/* End title  section */}
                        {/* start Form Input Section */}
                        <div className="certification--career-wrapper">
                            {values.map((item, key) => {
                                return (
                                    <Certificate
                                        key={key}
                                        handleChange={handleChange}
                                        itemKey={key}
                                        item={item}
                                        remove={remove}
                                        handleDateChange={handleDateChange}
                                    />
                                );
                            })}
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-12 col-md-12">
                                <p
                                    className="cs-btn btn--add-more"
                                    id="btn--more-profession"
                                    onClick={addMoreCertificateForm}
                                >
                                    + add more
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <h5>For Student</h5>
                            </div>
                            {exams.map((item, key) => {
                                return (
                                    <ForStudent
                                        key={key}
                                        handleChange={handleExamChange}
                                        itemKey={key}
                                        item={item}
                                        remove={removeExams}
                                    />
                                );
                            })}
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-12 col-md-12">
                                <p
                                    className="cs-btn btn--add-more"
                                    id="btn--more-profession"
                                    onClick={addMoreExamForm}
                                >
                                    + add more
                                </p>
                            </div>
                        </div>
                        {/* End Form Input Section */}
                    </div>
                    <Errors errorList={errors} />
                    <div className="nextprevious--btn-container">
                        <div className="previous-btn-container">
                            <button
                                type="button"
                                className="cs-btn btn-next-previous btn--previous-ms"
                                id="prevBtn"
                                onClick={props.prevStep}
                            >
                                Previous
                            </button>
                        </div>
                        <div className="next-btn-container">
                            <button
                                type="submit"
                                className="cs-btn btn-next-previous btn--next-ms"
                                id="nextBtn"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Multistep>
        </>
    );
};

export default Certification;
