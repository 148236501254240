import React, { useEffect, useState } from "react";
import CountryInfo from "../../services/CountryInfo";
import Loader from "../../components/Spinner";
import ScholarshipListHome from "../../components/ScholarshiplListHome";
import ScholarshipsService from "../../services/ScholarshipsSvc";
import Navbar from "../../components/CommonNavbar"
import DataNotFound from "../../components/DataNotFound";

const ScholarshipHome = () => {
    const [country, setCountry] = useState([]);
    const [showPage, setShowPage] = useState(false);
    const [scholarshipList, setScholarshipList] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [searchCountry, setSearchCountry] = useState("");
    const [searchType, setSearchType] = useState("");

    useEffect(() => {
        getCountry();
        fetchScholarships();
    }, []);

    const getCountry = async () => {
        let response = await CountryInfo.scholarshipCountry();
        if (response) {
            setCountry(response.data);
            setShowPage(true);
        }
    };

    const fetchScholarships = async () => {
        let query = {};
        if (searchName.length > 0) Object.assign(query, { name: searchName });
        if (searchCountry.length > 0)
            Object.assign(query, { country: searchCountry });
        if (searchType.length > 0) Object.assign(query, { type: searchType });
        let scholarshipList = await ScholarshipsService.fetchScholarships(
            query,
        );
        setScholarshipList(scholarshipList);
    };

    const resetSearch = async () => {
        setSearchName("");
        setSearchCountry("");
        setSearchType("");
        window.location.reload()
    };

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <>
                    <Navbar />
                    <div
                        className="job-wrapper"
                        style={{
                            backgroundImage: "url(/images/background.png)",
                            minHeight: "100vh",
                        }}
                    >

                        <div className="container extra-space">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-panel">
                                        <div className="search-form">
                                            <h1>Find the Right Scholarship For You</h1>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search Name"
                                                            onChange={(e) =>
                                                                setSearchName(
                                                                    e.target.value,
                                                                )
                                                            }
                                                            value={searchName}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                            onChange={(e) => setSearchCountry(e.target.value)}>
                                                            <option value="">
                                                                Select Country
                                                        </option>
                                                            {country.map(
                                                                (country) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                country.id
                                                                            }
                                                                            value={
                                                                                country.id
                                                                            }
                                                                        >
                                                                            {
                                                                                country.country_name
                                                                            }
                                                                        </option>
                                                                    );
                                                                },
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-3 col-md-6">
                                                    <select
                                                        className="form-control"
                                                        value={searchType}
                                                        onChange={(e) =>
                                                            setSearchType(
                                                                e.target.value,
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Type
                                                    </option>
                                                        <option value="undergraduate">
                                                            Undergraduate
                                                    </option>
                                                        <option value="course">
                                                            Course
                                                    </option>
                                                        <option value="masters">
                                                            Masters
                                                    </option>
                                                        <option value="PHD">Ph.D.</option>
                                                        <option value="diploma">
                                                            Diploma
                                                    </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-0">
                                                        <button
                                                            className="btn--apply-filter"
                                                            type="button"
                                                            onClick={() => fetchScholarships()}
                                                        >
                                                            Apply Filter
                                                    </button>
                                                        <button
                                                            className="btn--reset-all ml-3"
                                                            type="button"
                                                            onClick={
                                                                resetSearch
                                                            }
                                                        >
                                                            Reset All
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="job--count-list" />
                            {scholarshipList.length > 0 ? (
                                <div className="job--list-wrapper">
                                    <ScholarshipListHome
                                        scholarshipList={scholarshipList}
                                    />
                                </div>
                            ) : (
                                <div className="bg-white mb-5 mt-5">
                                    <DataNotFound />
                                </div>
                            )}

                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ScholarshipHome;
