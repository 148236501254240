import React from "react";
import CalendarPicker from "../DatePicker";
import SearchSelect from "../../components/Select"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Education = ({ handleChange, itemKey, item, remove, handleDateChange, country }) => {

    const options = country.map((country) => {
        return {
            label: country.country_name,
            value: country.id
        }
    })

    return (
        <div className="additional-education">
            <div className="row mb-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>School Name<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`institution-${itemKey}`}
                            className="form-control required"
                            value={item.institution}
                            placeholder="Example School of Fine Arts"
                            name={`institution[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Location<span style={{ 'color': 'red' }}>*</span></label>
                        <SearchSelect UniqueId={`location-${itemKey}`} SearchList={options} handleChange={handleChange} SelectedItem={item.location} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Degree<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`degree-${itemKey}`}
                            className="form-control"
                            value={item.degree}
                            placeholder="Bachelor of Computer Science"
                            name={`degree[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Field of Study<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`field_of_study-${itemKey}`}
                            className="form-control"
                            value={item.field_of_study}
                            placeholder="Computer Science"
                            name={`field_of_study[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Start Date<span style={{ 'color': 'red' }}>*</span></label>

                        <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                            selected={item.start_date}
                            name={`start_date[${itemKey}]`}
                            onChange={date => handleDateChange(date, `start_date-${itemKey}`)}
                            showMonthDropdown
                            showYearDropdown
                            adjustDateOnChange
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>End Date<span style={{ 'color': 'red' }}>*</span></label>
                        <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                            selected={item.end_date}
                            name={`end_date[${itemKey}]`}
                            onChange={date => handleDateChange(date, `end_date-${itemKey}`)}
                            showMonthDropdown
                            showYearDropdown
                            adjustDateOnChange
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Score<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`score-${itemKey}`}
                            className="form-control required"
                            value={item.score}
                            placeholder="EX- 3.75"
                            name={`score[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Score Out Of<span style={{ 'color': 'red' }}>*</span></label>
                        <input
                            type="text"
                            id={`score_out_of-${itemKey}`}
                            className="form-control required"
                            value={item.score_out_of}
                            placeholder="EX- 4.00"
                            name={`score_out_of[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            className="form-control"
                            id={`description-${itemKey}`}
                            rows={3}
                            // defaultValue={""}
                            value={item.description}
                            placeholder="Maximum 250 words "
                            name={`description[${itemKey}]`}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="col-lg-12 col-sm-12">
                        <button
                            id="removeBtn"
                            className="cs-btn btn-remove"
                            onClick={(e) => remove(e, itemKey, item.id)}>
                            Remove{" "}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Education;
