import React from 'react';

const Title = (props) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <h4 className="page-title">{props.title}</h4>
                </div>
            </div>
        </div>
    );
}

export default Title;
