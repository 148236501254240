import React, { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { commonAvatar, showLocalImage } from "../../helpers";

const ChatArea = ({ conversation, conversationMessages, authUser, setAttachment, setMessage, addConversation, message, showSendMessageBox }) => {


    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current && showSendMessageBox) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(scrollToBottom, [conversationMessages]);


    return (
        <div className="col-xl-8 col-lg-8">
            <div className="card">
                {conversation ? (
                    <div>
                        <div className="card-body py-2 px-3 border-bottom border-light" >
                            {showSendMessageBox && conversation && conversation.hasOwnProperty('id') &&
                                <div className="media py-1">
                                    <img
                                        src={
                                            conversation.agent.id == authUser.user_id
                                                ? conversation.client.avatar ? conversation.client.avatar.source_path : commonAvatar()
                                                : conversation.agent.avatar ? conversation.agent.avatar.source_path : commonAvatar()

                                        }
                                        alt="user-image"
                                        className="rounded-circle mr-3"
                                        width={40}
                                    />
                                    {
                                        conversation && conversation.hasOwnProperty('id') ? (
                                            <div className="media-body">
                                                <h5 className="mt-0 mb-0 font-15">
                                                    <span
                                                        className="text-reset">{authUser.user_id == conversation.agent.id ? conversation.client.name : conversation.agent.name}</span>
                                                </h5>
                                            </div>
                                        ) : ''
                                    }

                                </div>
                            }
                        </div>
                        <div className="card-body">
                            {conversation &&
                                <ul className="conversation-list" data-simplebar="init" style={{ maxHeight: '500px' }}>
                                    <div className="simplebar-wrapper" style={{ margin: '0px -15px' }}>
                                        <div className="simplebar-height-auto-observer-wrapper">
                                            <div className="simplebar-height-auto-observer" />
                                        </div>
                                        <div className="simplebar-mask">
                                            <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                                <div className="simplebar-content-wrapper"
                                                    style={{ height: 'auto', overflow: 'hidden scroll' }}>
                                                    <div id="scroll" className="simplebar-content" style={{ padding: '0px 15px' }}>
                                                        {conversationMessages && conversationMessages.map((message, index) => {
                                                            return (
                                                                <li className={`clearfix ${authUser.user_id == message.sender_id ? 'odd' : 'even'}`}
                                                                    key={index}>
                                                                    <div className="chat-avatar">
                                                                        <img
                                                                            src={
                                                                                conversation.client.id == message.sender_id
                                                                                    ? conversation.client.avatar ? conversation.client.avatar.source_path : commonAvatar()
                                                                                    : conversation.agent.avatar ? conversation.agent.avatar.source_path : commonAvatar()

                                                                            }
                                                                            alt="user-image"
                                                                            className="rounded-circle mr-3"
                                                                            width={40}
                                                                        />
                                                                        <i>{message.created_at}</i>
                                                                    </div>
                                                                    <div className="conversation-text">
                                                                        <div className="ctext-wrap">
                                                                            <p>
                                                                                {message.message ? message.message : showLocalImage(message.attachment.source_path)}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                </li>
                                                            )
                                                        })}
                                                        <div ref={messagesEndRef} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="simplebar-placeholder" style={{ width: 'auto', height: '907px' }} />
                                    </div>
                                    <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                                        <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }} />
                                    </div>
                                    <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                                        <div className="simplebar-scrollbar"
                                            style={{
                                                height: '233px',
                                                transform: 'translate3d(0px, 0px, 0px)',
                                                display: 'block'
                                            }} />
                                    </div>
                                </ul>
                            }

                            {showSendMessageBox &&
                                <div className="row">
                                    <div className="col">
                                        <div className="mt-2 bg-light p-3 rounded">
                                            <form className="needs-validation" noValidate name="chat-form" id="chat-form">
                                                <div className="row">
                                                    <div className="col mb-2 mb-sm-0">
                                                        <input type="text"
                                                            value={message}
                                                            className="form-control border-0"
                                                            onChange={(event) => setMessage(event.target.value)}
                                                            placeholder="Enter your text" />
                                                    </div>
                                                    <div className="col-sm-auto">
                                                        <div className="btn-group">
                                                            <div className="image-upload">
                                                                <label htmlFor="file-input" style={{ marginTop: '6px' }}>
                                                                    <FontAwesomeIcon
                                                                        className="attach-doc text-primary fa-2x"
                                                                        icon={faPaperclip} aria-hidden="true"
                                                                        style={{ 'cursor': 'pointer' }} />
                                                                </label>
                                                                <input id="file-input" type="file"
                                                                    accept={'image/jpeg,image/jpg,image/png,application/pdf, zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'}
                                                                    style={{ 'display': 'none' }}
                                                                    onChange={(event) => setAttachment(event.target.files[0])} />
                                                            </div>
                                                            <button type="button"
                                                                onClick={(e) => addConversation(e)}
                                                                className="btn btn-alert chat-send btn-block">
                                                                <FontAwesomeIcon className="text-primary fa-2x"
                                                                    icon={faPaperPlane} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* end col */}
                                                </div>
                                                {/* end row*/}
                                            </form>
                                        </div>
                                    </div>
                                    {/* end col*/}
                                </div>
                            }
                            {/* end row */}
                        </div>
                    </div>
                ) : (
                        <div>
                            <span>No Conversation selected</span>
                        </div>
                    )}

            </div>
        </div>
    );
}

export default ChatArea
