import apiCall from "../helpers/apicall";
import { client } from "../helpers/client";
import { catcher } from "../helpers";
import Storage from "../helpers/storage";
import {TOKEN_NAME, SOCIAL_LOGIN, USER, PROVIDER_FACEBOOK} from "../constants/Auth";

const AuthService = {
    async login(data) {
        let api = apiCall("login");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );
            if (response.data.data.hasOwnProperty("access_token")) {
                let data = response.data.data
                Storage.set(TOKEN_NAME, data.access_token, true);
                const user = { name: data.name, username: data.username, user_id: data.user_id, roles: data.roles, avatar:data.avatar, email:data.email }
                Storage.set(USER, user, true);
                return true;
            }
            return false
        } catch (error) {
            return catcher(error);
        }
    },

    async signup(data) {
        let api = apiCall("signup");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );
            if (response.data.data.hasOwnProperty("access_token")) {
                Storage.set(TOKEN_NAME, response.data.data.access_token, true);
                let data = response.data.data
                const user = { name: data.name, username: data.username, user_id: data.user_id, roles: data.roles, avatar:data.avatar, email:data.email }
                Storage.set(USER, user, true);
                return true;
            }
            return false;
        } catch (error) {
            return catcher(error);
        }
    },

    async socialProvider(providerName, response, values) {
        if (!response) {
            return
        }
        let info = {}
        if (providerName == PROVIDER_FACEBOOK) {
            info = {
                name: response.name,
                email: response.email,
                code: response.id,
                type: SOCIAL_LOGIN,
                provider: providerName,
            };
        } else {
            info = {
                name: response.profileObj.name,
                email: response.profileObj.email,
                code: response.googleId,
                type: SOCIAL_LOGIN,
                provider: providerName,
            };
        }

        let data = Object.assign(values, info);
        return await this.signup(data);
    },
    checkAuth() {
        let userInfo = Storage.get(TOKEN_NAME)
        if (!userInfo) {
            return false
        }
        return true
    },
    async logout() {
        let api = apiCall("logout");
        try {
            const response = await client[api.method](
                api.url,
                api.header
            );
            if(response) {
                Storage.destroy()
            }
        } catch (error) {
            Storage.destroy()
        }
    },
    async resetPassword(data) {
        let api = apiCall("reset-password");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );
            if(response) {
                Storage.destroy()
            }
            return true
        } catch (error) {
            return catcher(error);
        }
    },
    async emailPassword(data) {
        let api = apiCall("email-password");
        try {
            const response = await client[api.method](
                api.url,
                api.header,
                data
            );
            if(response) {
                Storage.destroy()
            }
            return true
        } catch (error) {
            return catcher(error);
        }
    },
    async changePassword(data) {
        let api = apiCall("change-password");
        try {
            await client[api.method](
                api.url,
                api.header,
                data
            );
            return true
        } catch (error) {
            return catcher(error);
        }
    },


};

export default AuthService;
