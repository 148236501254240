import apiCall from "../helpers/apicall"
import { client } from "../helpers/client"
import { catcher } from "../helpers"

const CreateClientService = {
    async fetchClient() {
        let api = apiCall("get-client");
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postClient(data) {
        let api = apiCall("post-client");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    // async deleteClient(id) {
    //     let api = apiCall("delete-client", {}, id)
    //     try {
    //         const response = await client[api.method](api.url, api.header);
    //         return response.data;
    //     } catch (error) {
    //         return catcher(error);
    //     }
    // },


}

export default CreateClientService