import React, { useEffect, useState } from "react";
import "./style.css";
import Multistep from "../../../layouts/multistep";
import Education from "../../../components/Education";
import { produce } from "immer";
import UserInfo from "../../../services/UserInfo";
import DataRequired from "../../../helpers/dataRequired";
import RemoveItem from "../../../helpers/removeItem";
import Errors from "../../../components/Errors";
import CountryInfo from "../../../services/CountryInfo";
import { EDUCATION } from "../../../constants/UserInformation";
import Navbar from "../../../components/CommonNavbar";
import dayjs from "dayjs";

const fields = {
    institution: "",
    location: "",
    degree: "",
    field_of_study: "",
    start_date: null,
    end_date: null,
    score: "",
    score_out_of: "",
    description: "",
    info_type: EDUCATION
};

const Educational = (props) => {
    const [values, setValues] = useState([fields]);
    const [errors, setErrors] = useState({});
    const [country, setCountry] = useState([]);

    useEffect(() => {
        getEducation()
        view()
        getCountry()
        document.getElementById("show-number").innerHTML = "Step 3 - 4";
        document.getElementById("completed-3").className += " completed";
    }, []);

    const view = () => {
        if (values.length === 1) {
            return (document.getElementById("removeBtn").style.display =
                "none");
        }
    };

    const getCountry = async () => {
        let response = await CountryInfo.country();
        if (response) {
            setCountry(response.data);
        }
    };

    const getEducation = async () => {
        let response = await UserInfo.getEducation();
        console.log(response)
        if (response.length > 0) {
            let payload = []
            response.map((value, key) => {
                let data = produce(response, draftState => {
                    draftState[key].start_date = new Date(draftState[key].start_date)
                    draftState[key].end_date = new Date(draftState[key].end_date)
                })
                payload.push(data[key])
            })
            setValues(payload);
        }
    };

    const handleDateChange = (date, item) => {
        let arr = [];
        arr = item.split("-");

        setValues((values) =>
            produce(values, (v) => {
                v[arr[1]][arr[0]] = date;
            })
        );
    }

    const handleChange = (event, key = null) => {
        // console.log(event, event.target)
        let value = ""
        let id = ""
        if (key == null) {
            value = event.target.value;
            id = event.target.id
        } else {
            value = event.value
            id = key
        }

        let arr = [];
        arr = id.split("-");

        setValues((values) =>
            produce(values, (v) => {
                v[arr[1]][arr[0]] = value;
            })
        );

        // console.log(country)
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        let final = Object.assign({}, values)
        final = Object.values(final)

        let payload = []
        final.map((value, key) => {
            let data = produce(final, draftState => {
                draftState[key].start_date = dayjs(draftState[key].start_date).format('YYYY-MM-DD')
                draftState[key].end_date = dayjs(draftState[key].end_date).format('YYYY-MM-DD')
            })
            payload.push(data[key])
        })
        let res = await UserInfo.saveUserInfo(DataRequired(payload));
        if (typeof res == "object" && !res.hasOwnProperty("data")) {
            setErrors(res);
        } else if (res.hasOwnProperty("data") && res.status == "success") {
            console.log("response", res.data)
            props.nextStep();
        }
    };

    const addMoreEducationForm = () => {
        setValues([...values, fields]);
    };

    const remove = async (event, itemKey, id) => {
        event.preventDefault();
        if (id) {
            await UserInfo.deleteUserInfo(id);
        }
        setValues(RemoveItem(itemKey, values));
    };

    return (
        <>
            <Navbar />
            <Multistep formType={props.formType}>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="">
                        <div className="row">
                            <div className="col-lg-12 col-md-12  ">
                                <h4 className="form-title">Education</h4>
                                <button
                                    type="button"
                                    onClick={() => {
                                        props.nextStep()
                                    }}
                                    className="cs-btn btn-next-previous btn--next-ms mr-3 float-right"
                                    id="skipBtn"
                                >
                                    Skip
                                </button>
                            </div>
                        </div>
                        <div className="educational--career-wrapper">
                            {values.map((item, key) => {
                                return (
                                    <Education
                                        key={key}
                                        handleChange={handleChange}
                                        itemKey={key}
                                        item={item}
                                        remove={remove}
                                        handleDateChange={handleDateChange}
                                        country={country}
                                    />
                                );
                            })}
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-12 col-md-12">
                                <p
                                    className="cs-btn btn--add-more"
                                    id="btn--more-profession"
                                    onClick={addMoreEducationForm}
                                >
                                    + add more
                                </p>
                            </div>
                        </div>
                    </div>
                    <Errors errorList={errors} />

                    <div className="nextprevious--btn-container">
                        <div className="previous-btn-container">
                            <button
                                type="button"
                                className="cs-btn btn-next-previous btn--previous-ms"
                                id="prevBtn"
                                onClick={props.prevStep}
                            >
                                Previous
                            </button>
                        </div>
                        <div className="next-btn-container">
                            <button
                                type="submit"
                                className="cs-btn btn-next-previous btn--next-ms"
                                id="nextBtn"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </Multistep>
        </>
    );
};

export default Educational;
