import React, { useEffect, useState } from "react";
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import "./style.css";
import { useParams } from "react-router-dom";
import CareerJobsService from "../../services/CareerJobsService";
import CountryInfo from "../../helpers/CountryInfo";
import history from "../../routes/history";
import Navbar from "../../components/CommonNavbar"
// import createDOMPurify from 'dompurify'
// import { JSDOM } from 'jsdom'

// const window = (new JSDOM('')).window
// const DOMPurify = createDOMPurify(window)

const JobDetails = () => {
    const [jobDetails, setJobDetails] = useState("");
    const { id } = useParams();

    useEffect(() => {
        fetchJobById(id);
    }, []);

    const fetchJobById = async (scholarshipId) => {
        const response = await CareerJobsService.fetchJobById(scholarshipId);
        setJobDetails(response);
    };

    const goToAgentPage = (jobDetails) => {
        let queryParams = `?country_id=${jobDetails.country_id}&category_id=2`  //2 means jobs category
        return history.push('/agents' + queryParams);
    }

    const goToPersonalAgentProfile = (id) => {
        return history.push('/agents/' + id)
    }

    return (
        <div className="job-details-wrapper">
            <Navbar />
            <div className="container extra-space">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-xs-12 jobs-card">
                        {/* job description */}
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">About The Job</h3>
                                {jobDetails.description &&
                                    <div dangerouslySetInnerHTML={{ __html: jobDetails.description }} />}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4 col-lg-4 jobs-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="job-detail-detail">
                                    <div className="inner-image">
                                        {/* <a href="https://apusthemes.com/wp-demo/kormosala/employer/bitcoin/">
                      <img width={180} height={57} src="https://apusthemes.com/wp-demo/kormosala/wp-content/uploads/2019/09/new-c3-180x57.jpg" className="attachment-kormosala-logo-size size-kormosala-logo-size wp-post-image" alt="" />
                    </a> */}
                                    </div>
                                    <h3 className="card-title">Job Overview</h3>
                                    <ul className="list">
                                        <li>
                                            <span className="text">
                                                Company Name:{" "}
                                                <b>{jobDetails.name} </b>{" "}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text">
                                                Category:{" "}
                                                <b>
                                                    {jobDetails.speciality
                                                        ? jobDetails.speciality
                                                            .name
                                                        : ""}{" "}
                                                </b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text">
                                                Location:{" "}
                                                <b>
                                                    {jobDetails.country_id
                                                        ? CountryInfo(
                                                            jobDetails.country_id,
                                                        ).country_name
                                                        : " "}{" "}
                                                </b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text">
                                                Offered Salary:{" "}
                                                <b>
                                                    Approx. ${jobDetails.salary}
                                                </b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text">
                                                Posted:{" "}
                                                <b>
                                                    Posted{" "}
                                                    {jobDetails.created_at}{" "}
                                                </b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text">
                                                Website:
                                                <a
                                                    className="value"
                                                    href={jobDetails.website}
                                                    style={{ color: "blue" }}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    Visit Website
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {jobDetails.agent_information && (
                                    <div className={"mt-3 mr-5"}>
                                        <button
                                            className={"cs-btn-success"}
                                            onClick={() => { goToPersonalAgentProfile(jobDetails.agent_information.id) }}
                                        >
                                            Apply with{" "}
                                            {jobDetails.agent_information.name}
                                        </button>
                                        <br />
                                    </div>
                                )}

                                <div className={"mt-3 mr-5"}>
                                    <button
                                        className={"cs-btn-success"}
                                        onClick={() => { goToAgentPage(jobDetails) }}
                                    >
                                        Choose from Supervisor List
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDetails;
