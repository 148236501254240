import React, { useState, useEffect } from 'react';
import CareerJobsService from "../../services/CareerJobsService";
import ScholarshipsService from "../../services/ScholarshipsSvc"
import { Countries } from "../../constants/Coutries"
import { Link } from "react-router-dom";
import CountryInfo from "../../helpers/CountryInfo";

const LatestPosts = ({ removeMenu }) => {
    const [jobsList, setJobsList] = useState([]);
    const [scholarshipList, setScholarshipList] = useState([]);

    useEffect(() => {
        fetchJobs()
        fetchScholarships()
    }, []);

    const fetchJobs = async () => {
        let jobsList = await CareerJobsService.fetchJobs({ take: 3 })
        setJobsList(jobsList)
    }

    const fetchScholarships = async () => {
        let scholarshipList = await ScholarshipsService.fetchScholarships({ take: 3 })
        setScholarshipList(scholarshipList)
    }

    // function strip_html_tags(str) {
    //     if ((str === null) || (str === ''))
    //         return false;
    //     else
    //         str = str.toString();
    //     return str.replace(/<[^>]*>/g, '');
    // }

    return (
        <div>
            <section className="latest--job-wrapper" onClick={removeMenu}>
                <div className="container">
                    <div className="job--list-card">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className="latest--job-title">Latest Jobs</h3>
                                <div className="leftside-jobs">
                                    {jobsList && jobsList.map((item) => {
                                        return (
                                            <div className="leftside--jobs-content" key={item.id}>
                                                <h3>{item.name}</h3>
                                                <h6>Location: {item.country_id ?CountryInfo(item.country_id).country_name: 'N/A'}</h6>
                                                <div className="mb-2">
                                                    <div>
                                                        {item.description &&
                                                        <p dangerouslySetInnerHTML={{ __html: item.description }} />}
                                                    </div>
                                                </div>
                                                <Link to={`/job-details/${item.id}`} className="text-right d-block">
                                                    more
                                                </Link>
                                            </div>
                                        )
                                    })}
                                    <Link to="/jobs" className="see-all">See all latest Jobs</Link>
                                </div>
                            </div>

                            <div className="col-lg-6" id="post-title">
                                <h3 className="latest--job-title">Latest Scholarships</h3>
                                <div className="leftside-jobs">
                                    {scholarshipList && scholarshipList.map((item) => {
                                        return (
                                            <div className="leftside--jobs-content" key={item.id}>
                                                <h3>{item.name}</h3>
                                                <h6>Location: {item.country_id ? CountryInfo(item.country_id).country_name: 'N/A'}</h6>
                                                <div className="mb-2">
                                                    <div>
                                                        {item.description &&
                                                        <p dangerouslySetInnerHTML={{ __html: item.description }} />}
                                                    </div>
                                                </div>
                                                <Link to={`/scholarships/${item.id}`} className="text-right d-block">more</Link>
                                            </div>
                                        )
                                    }
                                    )}
                                    <Link to="/scholarships" className="see-all">See all latest Scholarships</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div >
    );
}

export default LatestPosts
