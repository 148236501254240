import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../services/AuthService";
import Storage from "../../helpers/storage";
import { commonAvatar } from "../../helpers";
import FeatherIcon from "feather-icons-react";
import UserInfo from "../../services/UserInfo";
import {Link} from "react-router-dom";

const Header = () => {
    const [user, setUser] = useState([])
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)

    useEffect(() => {
        setUser(Storage.get('user'))
        unreadNotification()
    }, [])


    const activeMenu = (event) => {
        let elements = document.getElementsByClassName("active-nav");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('active-nav');
        }
        if (event.target.id) {
            document.getElementById(event.target.id).classList.add("active-nav")
        }
    }

    const dropdown = () => {
        document.getElementById("dropdown").classList.toggle("show");
    };

    const dropdownAccount = () => {
        document.getElementById("account").classList.toggle("show");
    };

    const hideSidebar = () => {
        document.getElementById("sidebar").classList.toggle("sidebar-hidden");
        document.getElementById("content-page").classList.toggle("sidebar-toggle");
        document.getElementById("logo-box").classList.toggle("logo-hidden");
        document.getElementById("burger-icon").classList.toggle("burger-icon");
        document.getElementById("footer").classList.toggle("footer-left");
    }

    const logout = () => {
        AuthService.logout().then(() => {
            return window.location.reload()
        })
    }

    const unreadNotification = async () => {
        let data = await UserInfo.unreadNotificationCount()
        setUnreadNotificationCount(data.data)
    }

    return (
        <div className="navbar-custom">
            <div className="container-fluid">
                <ul className="list-unstyled topnav-menu float-right mb-0">
                    <li>
                        <Link className="nav-link dropdown-toggle waves-effect waves-light" to={"/"}>Home
                        </Link>
                    </li>
                    {window.screen.width > 576 &&
                        <>
                            <li>
                                <Link className="nav-link dropdown-toggle waves-effect waves-light" to={"/scholarships"}>Scholarship
                                List
                            </Link>
                            </li>
                            <li>
                                <Link className="nav-link dropdown-toggle waves-effect waves-light" to={"/jobs"}>Job
                                List</Link>
                            </li>
                            <li>
                                <Link to={"/agents"} className="nav-link dropdown-toggle waves-effect waves-light">
                                    Find Supervisor
                            </Link>
                            </li>
                        </>
                    }

                    {user.roles &&
                        <li className="dropdown notification-list topbar-dropdown">
                            <div className="dropdown">
                                <span
                                    className="nav-link dropdown-toggle waves-effect waves-light"
                                    onClick={dropdown}
                                    data-toggle="dropdown"

                                    role="button"
                                    aria-haspopup="false"
                                    aria-expanded="false"
                                >
                                    {/* <i className="fa fa-bell noti-icon" aria-hidden="true" /> */}
                                    {/*<FontAwesomeIcon icon={faBell} />*/}
                                    Roles
                            </span>

                                <div id="dropdown" className="dropdown-content">
                                    <div>
                                        {user.roles && user.roles.map((value, key) => {
                                            return (
                                                <p key={key} className="m-4">{value == 'AGENT' ? 'SUPERVISOR' : value}</p>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                            <div className="dropdown" onClick={() => {
                                window.location.href = '/dashboard/notifications'
                            }}>
                                <span
                                    className="nav-link dropdown-toggle waves-effect waves-light"
                                    // onClick={}
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="false"
                                    aria-expanded="false"
                                >
                                    {/* <i className="fa fa-bell noti-icon" aria-hidden="true" /> */}
                                    <FontAwesomeIcon icon={faBell} />
                                    <span className="badge badge-danger rounded-circle noti-icon-badge">
                                        {unreadNotificationCount}
                                    </span>
                                </span>
                            </div>
                        </li>
                    }
                    <li className="dropdown notification-list topbar-dropdown">
                        <div className="account">
                            <span
                                className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
                                onClick={dropdownAccount}
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="false"
                                aria-expanded="false"
                            >
                                <img
                                    src={user.avatar ? user.avatar : commonAvatar()}
                                    alt="user-image"
                                    className="rounded-circle"
                                />
                                <span className="pro-user-name m-2">
                                    {user.name}
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                    />{" "}
                                </span>
                            </span>

                            <div id="account" className="dropdown-content">
                                <Link to={'/password/change'}
                                    className="dropdown-item notify-item">
                                    <FeatherIcon icon="lock" />
                                    <span className="font-14 m-2">Change Password</span>
                                </Link>
                                {user.roles && user.roles.includes('CLIENT') &&
                                    <Link id="profile" to="/user/profile/client" className="dropdown-item notify-item">
                                        <FeatherIcon icon="user" />
                                        <span> Edit Profile </span>
                                    </Link>
                                }
                                {
                                    (user.roles && user.roles.includes('AGENT')) &&
                                    <Link id="profile" to="/user/profile/agent"
                                        className="dropdown-item notify-item">
                                        <FeatherIcon icon="user" />
                                        <span> Edit supervising info </span>
                                    </Link>
                                }
                                <span style={{ 'cursor': 'pointer' }}
                                    onClick={() => logout()}
                                    className="dropdown-item notify-item">
                                    <FeatherIcon icon="log-out" />
                                    <span className="font-14 m-2">Logout</span>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* LOGO */}
                <div id="logo-box" className="logo-box">
                    <span className="logo logo-dark text-center">
                        <a href="/">
                            {window.screen.width > 576 &&
                                <img src="/images/logo_final.png" />
                            }

                            {window.screen.width < 576 &&
                                <img src="/images/blackbg_rhdpi.png" />
                            }
                        </a>
                    </span>
                    <span className="logo logo-light text-center">
                        <a href="/">
                            {window.screen.width > 576 &&
                                <img src="/images/logo_final.png" />
                            }

                            {window.screen.width < 576 &&
                                <img src="/images/squarelogo1.png" width="50" />
                            }
                        </a>
                    </span>

                </div>
                <ul className="list-unstyled topnav-menu topnav-menu-left m-0" id="burger-icon">
                    <li>
                        <button className="button-menu-mobile waves-effect waves-light" onClick={hideSidebar}>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    </li>
                    <li>
                        {/* Mobile menu toggle (Horizontal Layout)*/}
                        <span
                            className="navbar-toggle nav-link"
                            data-toggle="collapse"
                            data-target="#topnav-menu-content"
                        >
                            <div className="lines">
                                <span />
                                <span />
                                <span />
                            </div>
                        </span>
                        {/* End mobile menu toggle*/}
                    </li>
                </ul>
                <div className="clearfix" />
            </div>
        </div>
    );
};

export default Header;
