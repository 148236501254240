import React from 'react';
import {TYPES} from "../../constants/VisaCategory";
import {LIST} from "../../constants/AgentResponsibilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import SearchSelect from "../../components/Select"
import ReactQuill from "react-quill";

const AgentInformation = ({itemKey, item, remove, handleChange, country, handleResponsibilityChange, handleResponsibilityRemove}) => {

    const options = country.map((country) => {
        return {
            label: country.country_name,
            value: country.id
        }
    })

    // console.log("sss", item)

    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Destination<span style={{'color': 'red'}}>*</span></label>
                    <SearchSelect UniqueId={`country_id-${itemKey}`} SearchList={options} handleChange={handleChange}
                                  SelectedItem={item.country_id}/>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Visa Category<span style={{'color': 'red'}}>*</span></label>
                    <select className="form-control"
                            id={`visa_category_id-${itemKey}`}
                            value={item.visa_category_id}
                            name={`visa_category_id[${itemKey}]`}
                            onChange={handleChange}>
                        <option>Select Category</option>
                        {Object.keys(TYPES).map(value => {
                            return (
                                <option value={value} key={value}>{TYPES[value]}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Fee(Taka)<span style={{'color': 'red'}}>*</span></label>
                    <input type="number"
                           className="form-control"
                           id={`fee-${itemKey}`}
                           value={item.fee}
                           name={`fee[${itemKey}]`}
                           onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Non refundable fee (Taka)</label>
                    <input type="number"
                           className="form-control"
                           id={`non_refundable_fee-${itemKey}`}
                           value={item.non_refundable_fee}
                           name={`non_refundable_fee[${itemKey}]`}
                           onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Responsibility<span style={{'color': 'red'}}>*</span></label>
                    <select
                        id={`responsibility-${itemKey}`}
                        value={item.responsibility}
                        name={`responsibility[${itemKey}]`}
                        className="form-control"
                        onChange={handleResponsibilityChange}
                    >
                        <option>Select Responsibilities</option>
                        {Object.keys(LIST).map((value, key) => {

                            return (
                                <option value={LIST[value]} key={key}>{LIST[value]}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>Responsibilities</label>
                    {item.responsibilities && item.responsibilities.map((value, key) => {
                        return (
                            <div className="input-group mb-3" key={key}>
                                <input type="text" className="form-control"
                                       id={`responsibility-${itemKey}`}
                                       name={`responsibility[${itemKey}]`}
                                       value={value == "others" ? " " : value}
                                       readOnly={value == "others" ? false : true}
                                />
                                <div className="input-group-append">
                                    <span style={{cursor: 'pointer'}} className="input-group-text"
                                          onClick={(e) => handleResponsibilityRemove(value, itemKey)}>
                                        <FontAwesomeIcon icon={faTrash} style={{color: 'red'}}/>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group">
                    <label>Processing Time (Months)<span style={{'color': 'red'}}>*</span></label>
                    <input type="number"
                           className="form-control"
                           placeholder="2"
                           id={`processing_time-${itemKey}`}
                           value={item.processing_time}
                           name={`processing_time[${itemKey}]`}
                           onChange={handleChange}
                    />
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                    <label>Service Description</label>
                    <ReactQuill rows={3} name={`description[${itemKey}]`} theme="snow"
                                id={`description-${itemKey}`} value={item.description} onChange={(e) => handleChange(e, `description-${itemKey}`)} placeholder={'Maximum 250 words'}/>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="custom-control custom-switch">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`is_enabled-${itemKey}`}
                        value={item.is_enabled}
                        name={`is_enabled[${itemKey}]`}
                        onChange={handleChange}
                        checked={item.is_enabled}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor={`is_enabled-${itemKey}`}
                    >
                        {item.is_enabled ? " Disable not to show Clients your service" : " Enable to show Clients your service"}
                    </label>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-12 col-sm-12">
                    <button
                        id="removeBtn"
                        className="cs-btn btn-remove"
                        onClick={(e) => remove(e, itemKey, item.id)}
                    >
                        Remove{" "}
                    </button>
                </div>
            </div>
        </>
    );
}

export default AgentInformation;
