import React from "react";
import CountryInfo from "../../helpers/CountryInfo";
import {Link} from "react-router-dom";

const AgentListCard = ({ agentList }) => {
    return (
        <>
            {agentList && agentList.map((agent, index) => {
                return (
                    <>
                    {agent.agent_info.length > 0 &&
                    <div key={index}>
                        <div className="single-job">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="job-content">
                                        <div className="job--text-content">
                                            <h5>
                                                {agent.name}
                                            </h5>
                                            <div className="label-container">
                                                <span className="badge badge-primary mr-2">
                                                    Profession: {agent.profession}
                                                </span>
                                                <span className="badge badge-danger mr-2">
                                                    Nationality: {agent.nationality ? CountryInfo(agent.nationality).country_name : " "}
                                                </span>
                                            </div>
                                            <div className="active-status">
                                                Visa Category
                                            </div>
                                            {
                                                agent.agent_info && agent.agent_info.map((value, index) => {
                                                    return (
                                                        <span key={index} className="badge badge-pill badge-info mr-2">
                                                           {value.country_id ? CountryInfo(value.country_id).country_name : " "}:
                                                           {value.visa_category.name}
                                                        </span>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                                <Link to={`/agents/${agent.id}`} className="col-lg-3 text-in-center">
                                    <h5 className="cs-btn-blue">Click For Details</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                    }
                    </>
                )
            })}
        </>
    );
};

export default AgentListCard;
