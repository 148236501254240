import React, {useEffect, useState} from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import {FB_APP_ID, GOOGLE_API_URL} from "../../config";
import {Link} from "react-router-dom";
import FormInput from "../../components/Input";
import "./style.css";
import AuthService from "../../services/AuthService";
import {CLIENT_ROLE, PROVIDER_FACEBOOK, PROVIDER_GOOGLE, REGULAR_LOGIN, TOKEN_NAME,} from "../../constants/Auth";
import Storage from "../../helpers/storage";
import Loader from "../../components/Spinner";
import Errors from "../../components/Errors";
import LoginSignup from "../../layouts/loginSignup";
import Navbar from "../../components/CommonNavbar"

const Login = (props) => {
    const [showPage, setShowPage] = useState(false);
    const [errors, setErrors] = useState({});
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
        type: REGULAR_LOGIN,
    });

    useEffect(() => {
        if (Storage.has(TOKEN_NAME)) {
            props.history.push("/");
        }
        setShowPage(true);
    }, [props.history]);

    const handleChange = ({target}) => {
        setCredentials({
            ...credentials,
            [target.name]: target.value,
        });
    };
    const responseFacebook = async (response) => {
        let data = {role: CLIENT_ROLE};
        let res = await AuthService.socialProvider(
            PROVIDER_FACEBOOK,
            response,
            data
        );
        if (res === true) {
            const user = await Storage.get('user')
            const url = await Storage.get('fallback_url')
            if (user.roles.length < 1) {
                props.history.push("/user/profile/client");
            } else if (url) {
                window.location.href = url
            } else {
                props.history.push("/dashboard");
            }
        } else if (typeof res == "object") {
            setErrors(res);
        }
    }
    const responseGoogle = async (response) => {
        let data = {role: CLIENT_ROLE};
        let res = await AuthService.socialProvider(
            PROVIDER_GOOGLE,
            response,
            data
        );
        if (res === true) {
            const user = await Storage.get('user')
            const url = await Storage.get('fallback_url')
            if (user.roles.length < 1) {
                props.history.push("/user/profile/client");
            } else if (url) {
                window.location.href = url
            } else {
                props.history.push("/dashboard");
            }
        } else if (typeof res == "object") {
            setErrors(res);
        }
    };

    const handleLogin = async (event) => {
        setShowPage(true);
        event.preventDefault();
        let res = await AuthService.login(credentials);
        if (res === true) {
            const user = await Storage.get('user')
            const url = await Storage.get('fallback_url')
            if (user.roles.length < 1) {
                props.history.push("/user/profile/client");
            } else if (url) {
                window.location.href = url
            } else {
                props.history.push("/dashboard");
            }
        } else if (typeof res == "object") {
            setErrors(res);
        }
    };
    return (
        <>
            {!showPage ? (
                <Loader/>
            ) : (
                <LoginSignup>
                    <Navbar/>
                    <div className="login-card">
                        <h2 className="login-title">Welcome Back</h2>
                        <FacebookLogin
                            textButton="Log in With Facebook"
                            size={"medium"}
                            cssClass="external-login"
                            appId={FB_APP_ID}
                            autoLoad={false}
                            fields="name,email"
                            // callback={responseFacebook}
                        />
                        <GoogleLogin
                            className="external-login"
                            autoLoad={false}
                            clientId={GOOGLE_API_URL}
                            buttonText="Log in With Google"
                            // onSuccess={responseGoogle}
                            // onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                        />
                        <div>
                            <span className="divider">Or</span>
                        </div>

                        <div className="email--signup-container">
                            <form className="signup--form" onSubmit={handleLogin}>
                                <div className="form-group">
                                    <FormInput
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={credentials.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <FormInput
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        value={credentials.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <Errors errorList={errors}/>
                                <button
                                    type="submit"
                                    className="cs-btn btn-success form-control btn--custom-signin"
                                >
                                    Sign in
                                </button>
                            </form>
                            <p className="have--account-title">
                                Forget Your Password? <Link to="/password/email">Click Here</Link>
                            </p>
                            <p className="have--account-title">
                                Don't have an account? <Link to="/signup">Sign Up</Link>
                            </p>
                        </div>
                    </div>
                </LoginSignup>
            )}
        </>
    );
};

export default Login;
