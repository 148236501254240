import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { Dropdown } from "react-bootstrap";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DasboardFaqsList = ({ faqsList, handleUpdate, handleDelete }) => {

    return (
        <div className="col-lg-12">
            <div className="table-responsive-sm align-content-between">
                <div className={faqsList && faqsList.length > 1 ? "table-table-head align-items-center" : "none"}>
                    <div className="column-head w-30">Question</div>
                    <div className="column-head w-65">Answer</div>
                    <div className="column-head w-5">Action</div>
                </div>
                {faqsList && faqsList.map((faqs, key) => {
                    return (
                        <div
                            className="table--content-box align-items-center"
                            key={key}
                        >
                            <div className="column w-30">{faqs.question}</div>
                            <div className="column w-65">{faqs.answer}</div>
                            <div className="column w-5">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <span
                                            className="text-primary"
                                            data-toggle="dropdown"
                                        >
                                            <FontAwesomeIcon icon={faCog} />{" "}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleUpdate(faqs.id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                className="text-primary fa-1x"
                                                icon={faWrench}
                                            />{" "}
                                            Update
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleDelete(faqs.id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                className="text-primary fa-1x"
                                                icon={faTrash}
                                            />{" "}
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DasboardFaqsList;
