export const ADVISING = 'Advising';
export const EMBASSY_FEE = 'Embassy Fee';
export const SEMESTER_TUITION_FEE = 'Tuition Fee(semester)';
export const YEAR_TUITION_FEE = 'Tuition Fee(Year)';
export const PLANE_FARE = 'Plane Fare';
export const ACCOMMODATION = 'Accommodation';
export const OTHER = 'Other';


export const LIST = {
    ADVISING,
    EMBASSY_FEE,
    SEMESTER_TUITION_FEE,
    YEAR_TUITION_FEE,
    PLANE_FARE,
    ACCOMMODATION,
    OTHER
}