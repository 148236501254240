import index from '../api'
import { API_URL } from '../config'
import { notify } from './notifyManager';
import Storage from './storage';
import { TOKEN_NAME } from '../constants/Auth';

const DEFAULT_HEADER = {
  Accept: "application/json",
};

const apiCall = (name, query= {}, ...params) => {
    let apiObject = index.find((route) => route.name === name) || null;
    
    if (!apiObject) {
      notify("error", "Url Not found!");
      return
    }

    let route = {
        'method' : apiObject.method,
        'url': API_URL + apiObject.url,
    }

    route.header = DEFAULT_HEADER
    
    if(apiObject.isAuth) {
        if(!Storage.has(TOKEN_NAME)) {
           notify("error", "You are Unauthenticated!");
           return 
        }
        
         Object.assign(route.header, {
           Authorization: `bearer ${Storage.get(TOKEN_NAME)}`
        });
    }

    
    if(params) {
        params.forEach((param) => {
        route.url = route.url.replace(/{.*?}/, param);
        });
        
    }
    
    let keys = Object.keys(query);
    
    if (keys.length > 0) {
        let prepareQuery = "?";
        prepareQuery += keys.map((key) => key + "=" + query[key]).join('&');
        route.url += prepareQuery;
    }
    return route
}

export default apiCall

