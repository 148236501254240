export const REGULAR_LOGIN = "regular"
export const SOCIAL_LOGIN = "social"
export const TOKEN_NAME = "my_agent_token"
export const CLIENT_ROLE = 3;
export const AGENT_ROLE = 4;
export const AFFILIATE_ROLE = 5;
export const PROVIDER_GOOGLE = 'google'
export const PROVIDER_FACEBOOK = 'facebook'
export const USER = 'user'
export const FALLBACK_URL = 'fallback_url'
