import apiCall from "../helpers/apicall"
import { client } from "../helpers/client"
import { catcher } from "../helpers"

const BannerTextService = {
    async fetchBannerText() {
        let api = apiCall("get-banner-text");
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postBannerText(data) {
        let api = apiCall("post-banner-text");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async deleteBannerText(id) {
        let api = apiCall("delete-banner-text", {}, id)
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },


}

export default BannerTextService