import React, { useEffect, useState } from "react";
import "./style.css";
import Multistep from "../../../layouts/multistep";
import UserInfo from "../../../services/UserInfo";
import Errors from "../../../components/Errors";
import Loader from "../../../components/Spinner";
import CountryInfo from "../../../services/CountryInfo";
import { objectToFormData } from "../../../helpers";
import SearchSelect from "../../../components/Select"
import Navbar from "../../../components/CommonNavbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const BasicInfo = (props) => {
    const [errors, setErrors] = useState({});
    const [showPage, setShowPage] = useState(false);
    const [country, setCountry] = useState([]);
    const [avatar, setAvatar] = useState(null);
    const [kycFront, setKycFront] = useState(null);
    const [kycBack, setKycBack] = useState(null);

    const [values, setValues] = useState({
        name: "",
        nationality: "",
        date_of_birth: "",
        profession: "",
        career_objective: "",
        address: "",
        phone_no: "",
        website: "",
        passport_no: "",
        date_of_expire: "",
        bank_name: "",
        bank_branch_name: "",
        account_name: "",
        bank_account_id: "",
    });

    useEffect(() => {
        getBasicInfo();
        getCountry();

    }, []);


    const getCountry = async () => {
        let response = await CountryInfo.country();
        if (response) {
            setCountry(response.data.map(item => {
                return ({
                    label: item.country_name,
                    value: item.id
                })
            }));
            setShowPage(true);

        }
    };

    const getBasicInfo = async () => {
        let response = await UserInfo.getBasic();
        if (response) {
            setValues({
                name: response.name,
                address: response.address ? response.address : "",
                nationality: response.nationality,
                career_objective: response.career_objective
                    ? response.career_objective
                    : "",
                profession: response.profession,
                date_of_birth: response.date_of_birth
                    ? new Date(response.date_of_birth)
                    : "",
                passport_no: response.passport_no,
                date_of_expire: response.date_of_expire
                    ? new Date(response.date_of_expire)
                    : null,
                phone_no: response.phone_no,
                id: response.id,
                website: response.website,
                bank_name: response.bank_name,
                bank_branch_name: response.bank_branch_name,
                account_name: response.account_name,
                bank_account_id: response.bank_account_id
            });
        }
    };

    const handleChange = (event, key = null) => {

        let value = ""
        let name = ""
        if (key == null) {
            value = event.target.value;
            name = event.target.name
        } else {
            value = event.value
            name = key
        }
        // const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleDateChange = (date, key) => {
        const value = new Date(date)
        setValues({
            ...values,
            [key]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowPage(true);
        let payload = Object.assign({}, values);
        if (values.date_of_birth && values.date_of_birth != "") {
            Object.assign(payload, {
                date_of_birth: dayjs(values.date_of_birth).format('YYYY-MM-DD'),

            });
        }

        if (values.date_of_expire && values.date_of_expire != "") {
            Object.assign(payload, {
                date_of_expire: dayjs(values.date_of_expire).format('YYYY-MM-DD'),
            });

        }

        let finalPayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null));
        let data = objectToFormData(finalPayload)
        data.append('_method', 'PUT')
        if (avatar) {
            data.append('avatar', avatar)
        }
        if (kycFront) {
            data.append('kyc_front', kycFront)
        }
        if (kycBack) {
            data.append('kyc_back', kycBack)
        }
        let res = await UserInfo.basicInfo(data);

        if (res.hasOwnProperty('user_id')) {
            props.nextStep();
        } else {
            setErrors(res);
        }
    };

    return (
        <>
            <Navbar />
            {!showPage ? (
                <Loader />
            ) : (
                <Multistep formType={props.formType}>
                    <form id="regForm" onSubmit={handleSubmit}>
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="form-title">
                                        Basic Information
                                    </h4>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Name<span style={{ 'color': 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Doe"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Profession<span style={{ 'color': 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Business Analyst"
                                            name="profession"
                                            value={values.profession}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12"
                                    style={{ position: "relative"}}>
                                    <div className="form-group">
                                        <label>Date of Birth<span style={{ 'color': 'red' }}>*</span></label>
                                        {/* <CalendarPicker
                                            variable={values.date_of_birth}
                                            onChangeHandler={(newValue) =>
                                                handleDateChange(
                                                    newValue,
                                                    "date_of_birth",
                                                )
                                            }
                                        /> */}
                                        <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                                            selected={values.date_of_birth}
                                            onChange={date => handleDateChange(date, "date_of_birth")}
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Phone Number<span style={{ 'color': 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="+1218*******6"
                                            name="phone_no"
                                            value={values.phone_no}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Website</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="hello.com"
                                            name="website"
                                            value={values.website}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12"
                                    style={{ position: "relative", zIndex: "1000" }}>
                                    <div className="form-group">
                                        <label>Nationality<span style={{ 'color': 'red' }}>*</span></label>
                                        <SearchSelect UniqueId="nationality" SearchList={country}
                                            handleChange={handleChange} SelectedItem={values.nationality} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label> Passport No.</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="AH81******5"
                                            name="passport_no"
                                            value={values.passport_no}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12"
                                    style={{ position: "relative"}}>
                                    <div className="form-group">
                                        <label>Date of Expire</label>
                                        <DatePicker placeholder="YYYY-MM-DD" dateFormat="yyyy-MM-dd"
                                            selected={values.date_of_expire}
                                            onChange={(newValue) =>
                                                handleDateChange(
                                                    newValue,
                                                    "date_of_expire",
                                                )
                                            }
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <textarea
                                            className="form-control"
                                            rows={3}
                                            placeholder="Street address, City or town, State, Postal code and County"
                                            name="address"
                                            value={values.address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>About yourself</label>
                                        <textarea
                                            className="form-control"
                                            rows={3}
                                            placeholder="Describe your career objective here. Maximum 250 words"
                                            value={values.career_objective}
                                            name="career_objective"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                     <label style={{ fontWeight: "bold" }}>Account Details:</label>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label> Bank Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="City Bank"
                                            name="bank_name"
                                            value={values.bank_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label> Bank Branch Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="branch name"
                                            name="bank_branch_name"
                                            value={values.bank_branch_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label> Account Holder Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name="account_name"
                                            value={values.account_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label> Bank Account No.</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1233****"
                                            name="bank_account_id"
                                            value={values.bank_account_id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Profile picture</label>
                                        <input id="file-input" type="file"
                                            accept={'image/jpeg,image/jpg,image/png'}
                                            className={'form-control'}
                                            onChange={(event) => setAvatar(event.target.files[0])} />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 row">
                                    <hr />
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>NID/PASSPORT/DRIVING - <span
                                                style={{ 'color': 'red' }}>FRONT</span></label>
                                            <input id="file-input" type="file"
                                                accept={'image/jpeg,image/jpg,image/png'}
                                                className={'form-control'}
                                                onChange={(event) => setKycFront(event.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>NID/PASSPORT/DRIVING - <span
                                                style={{ 'color': 'red' }}>BACK</span></label>
                                            <input id="file-input" type="file"
                                                accept={'image/jpeg,image/jpg,image/png'}
                                                className={'form-control'}
                                                onChange={(event) => setKycBack(event.target.files[0])} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Errors errorList={errors} />

                        <div className="nextprevious--btn-container">
                            <div className="previous-btn-container" />
                            <div className="next-btn-container">
                                <button
                                    type="submit"
                                    className="cs-btn btn-next-previous btn--next-ms"
                                    id="nextBtn"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </form>
                </Multistep>
            )}
        </>
    );
};

export default BasicInfo;
