const DateManager = (date, ...keys) => {
    if (date == null) {
        return ""
    }
    const DATE = date.split('-')
    let finalDate = ''
    if(keys) {
       keys.map((value, key) => {
           if(key > 0) {
               finalDate += '/'
           }
           if (value === 'y') {
               finalDate +=  DATE[0]
           }
           if (value === 'm') {
               finalDate +=  DATE[1]
           }
           if (value === 'd') {
               finalDate +=  DATE[2]
           }
       })

    }
    return finalDate
}

export default DateManager