import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import AuthService from "../services/AuthService";

const RouteWithLayout = (props) => {
    const { layout: Layout, component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(matchProps) =>
                AuthService.checkAuth() ? (
                <Layout>
                    <Component {...matchProps} />
                </Layout>
                ) : (
                <Redirect to="/login" />
                )
            }
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default RouteWithLayout;
