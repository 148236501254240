import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Errors from "../Errors";

const SupportTicketModal = ({
                                handleClose,
                                handleSubmit,
                                handleChange,
                                values,
                                show,
                                errors
                            }) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Open a Ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="regForm" onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>Subject</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Subject"
                                    name="subject"
                                    value={values.subject}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>Attachment</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    placeholder="Add File"
                                    name="attachment"
                                    value={values.attachment}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea
                                    type="text"
                                    rows="5"
                                    className="form-control"
                                    placeholder="Description.."
                                    name="message"
                                    value={values.message}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <Errors errorList={errors}/>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="float-right">
                                <Button className="button-modal-close cs-btn-remove" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="submit"
                                        className={'button-modal-submit cs-btn-success'}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default SupportTicketModal