import { catcher } from "../helpers"
import apiCall from "../helpers/apicall"
import { client } from "../helpers/client"

const PaymentService = {
    async getPayment() {
        let api = apiCall("get-payment");
        try {
            const response = await client[api.method](api.url, api.header);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },
    async postPayment(data) {
        let api = apiCall("post-payment");
        try {
            const response = await client[api.method](api.url, api.header, data);
            return response.data;
        } catch (error) {
            return catcher(error);
        }
    },


}

export default PaymentService