import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { Dropdown } from "react-bootstrap";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { bannerText } from "../../constants/bannerText"

const BannerTextList = ({ BannerText, handleUpdate }) => {
    // console.log(data 2, BannerText)
    return (
        <div className="col-lg-12">
            <div className="table-responsive-sm">
                <div className={BannerText && BannerText.length > 1 ? "table-table-head align-items-center" : "none"}>
                    <div className="column-head w-20">Name</div>
                    <div className="column-head w-15">Type</div>
                    <div className="column-head w-25">Url</div>
                    <div className="column-head w-15">Action</div>
                </div>
                {BannerText && BannerText.map((text, key) => {
                    return (
                        <div
                            className="table--content-box align-items-center"
                            key={key}
                        >
                            <div className="column w-20">{text.name}</div>
                            <div className="column w-15">
                                {text.type
                                    ? bannerText[text.type]
                                    : " "}

                            </div>
                            <div className="column text-primary w-25">
                                {text.url}
                            </div>
                            <div className="column w-15" id="banner-drop">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <span
                                            className="text-primary"
                                            data-toggle="dropdown"
                                        >
                                            <FontAwesomeIcon icon={faCog} />{" "}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => handleUpdate(text.id)}
                                        >
                                            <FontAwesomeIcon
                                                className="text-primary fa-1x"
                                                icon={faWrench}
                                            />{" "}
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BannerTextList
