import React, { useState } from "react";
import Basic from "../MultistepForm/Basic";
import Professional from "../MultistepForm/Professional";
import Educational from "../MultistepForm/Educational";
import Certification from "../MultistepForm/Certification";

const ClientInfo = (props) => {
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const formType = "client"

    switch (step) {
        case 1:
            return (
                <Basic nextStep={nextStep} prevStep={prevStep} formType={formType} />
            );

        case 2:
            return (
                <Professional
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                />
            );

        case 3:
            return (
                <Educational
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                />
            );

        case 4:
            return (
                <Certification
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                    history={props.history}
                />
            );
        default:
            return (
                <Basic nextStep={nextStep} prevStep={prevStep} formType={formType} />
            );
    }
};

export default ClientInfo;
