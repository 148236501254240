import React from 'react';
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import Navbar from "../../components/CommonNavbar"

const ContactUs = () => {
    return (
        <>
            <Navbar/>
            <div className="job-wrapper" style={{
                backgroundImage: "url(/images/background.png)",
                minHeight: "100vh",
            }}>
                <div className="container job--list-wrapper">
                    <div className="about--text-content" style={{background: "#fff", padding: "30px", marginTop: "60px"}}>
                        <h3 className="card-title mb-5">Contact Information</h3>
                        <ul>
                            <li >
                                {/* <FontAwesomeIcon icon={faLocationArrow} /> */}
                                <p>
                                    <strong className="card-title">Head Office:</strong> <br/> Ursinusstr. 5, 85077, Manching, Germany <br/>
                                    Mobile: +4915210501282 (WhatsApp, Bip) <br />
                                    Opening hour only with appointment
                                </p>
                            </li>
                            <li >
                                {/* <FontAwesomeIcon icon={faPhone} /> */}
                                <p>
                                    <strong className="card-title">Local Office: </strong> <br/> 25/14, Takwa road, Konapara, Demra, Dhaka-1362 <br/>
                                    Mobile: +8801517-833750, +8801674-335571 (WhatsApp, Imo) <br />
                                    Sunday - Thursday : 08:00-12:30 & 15:00 - 17:00
                                </p>
                            </li>
                            <li >
                                {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                                <p>
                                    <strong className="card-title">Email: </strong> <br />
                                    contact@supervisor4u.com
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs
