import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import DashboardFileList from "../../components/DashboardFileList"
import FileService from '../../services/FileService';
import VisaCategoryService from "../../services/VisaCategoryService";
import { FileStatus } from "../../constants/FileStatus";
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

const DashboardJobs = () => {
    const [showPage, setShowPage] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [visaCategories, setVisaCategories] = useState([]);
    const [clientName, setClientName] = useState("")
    const [agentName, setAgentName] = useState("")
    const [status, setStatus] = useState("")
    const [visaCategory, setVisaCategory] = useState("")


    useEffect(() => {
        fetchFiles()
        fetchVisaCategories()
    }, []);

    const fetchFiles = async () => {
        let query = {}
        if (agentName.length > 0) Object.assign(query, { agent_name: agentName });
        if (clientName.length > 0) Object.assign(query, { client_name: clientName });
        if (status.length > 0)
            Object.assign(query, { status: status });
        if (visaCategory.length > 0) Object.assign(query, { visa_category: visaCategory });
        let response = await FileService.fetchFiles(query)
        if (response) {
            setFileList(response);
            setShowPage(true);
        }
    };

    const resetSearch = () => {
        setAgentName("");
        setClientName("");
        setVisaCategory("");
        setStatus("");
    };

    const fetchVisaCategories = async () => {
        let response = await VisaCategoryService.fetchCategories();
        if (response) {
            setVisaCategories(response)
        }
    };
    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="campaigns-card">
                                <div className="title-box d-flex justify-content-between">
                                    <div className="title-left">
                                        <h2>File List</h2>
                                    </div>
                                </div>

                                <div className="filter-box mb-3">
                                    <div className="form-group row mb-1">
                                        <div className="search-input mb-3 col-lg-3 col-md-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search By Client Name"
                                                onChange={(e) =>
                                                    setClientName(e.target.value)
                                                }
                                                value={clientName}
                                            />
                                        </div>
                                        <div className="search-input mb-3 col-lg-3 col-md-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search By Supervisor Name"
                                                onChange={(e) =>
                                                    setAgentName(e.target.value)
                                                }
                                                value={agentName}
                                            />
                                        </div>
                                        <div className="form-group col-lg-3 col-md-3">
                                            <select
                                                className="form-control"
                                                value={status}
                                                onChange={(e) =>
                                                    setStatus(e.target.value)
                                                }
                                            >
                                                <option value="">Select Status</option>
                                                    {Object.values(FileStatus).map((status, key) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={key}
                                                        >
                                                            {status}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-3 col-md-3">
                                            <select
                                                className="form-control"
                                                value={visaCategory}
                                                onChange={(e) =>
                                                    setVisaCategory(e.target.value)
                                                }
                                            >
                                                <option value="">Select Visa Category</option>
                                                {visaCategories.map((visaCategory) => {
                                                    return (
                                                        <option
                                                            key={visaCategory.id}
                                                            value={visaCategory.id}
                                                        >
                                                            {visaCategory.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                    </div>
                                    <div className="form-group mb-0">
                                        <button
                                            className="btn--apply-filter"
                                            onClick={() => fetchFiles()}
                                        >
                                            Apply Filter
                                        </button>
                                        <button
                                            className="btn--reset-all ml-3"
                                            onClick={() => resetSearch()}
                                        >
                                            Reset All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DashboardFileList fileList={fileList} handleUpdate={resetSearch} />
                    </div>
                </div>

            )}
        </>
    );
}

export default DashboardJobs;