import apiCall from "../helpers/apicall"
import { client } from "../helpers/client"
import { catcher } from "../helpers"

const FaqsService = {
  async fetchFaqs(query) {
    let api = apiCall("get-faqs", query);
    try {
      const response = await client[api.method](api.url, api.header);
      return response.data.data;
    } catch (error) {
      return catcher(error);
    }
  },
  async postFaqs(data) {
    let api = apiCall("post-faqs");
    try {
      const response = await client[api.method](api.url, api.header, data);
      return response.data;
    } catch (error) {
      return catcher(error);
    }
  },
  async updateFaqs(data, id) {
    let api = apiCall("update-faqs", {}, id)
    try {
      const response = await client[api.method](api.url, api.header, data);
      return response.data;
    } catch (error) {
      return catcher(error);
    }
  },
  async deleteFaqs(id) {
    let api = apiCall("delete-faqs", {}, id)
    try {
      const response = await client[api.method](api.url, api.header);
      return response.data;
    } catch (error) {
      return catcher(error);
    }
  }
}

export default FaqsService