export const Countries = [
    {
        "id": 1,
        "country_name": "Afghanistan",
        "capital": "Kabul",
        "citizenship": "Afghan",
        "currency": "afghani",
        "currency_code": "AFN",
        "full_name": "Islamic Republic of Afghanistan",
        "short_name": "AF",
        "country_mobile_code": "93",
        "currency_symbol": "؋",
        "flag": "AF.png"
    },
    {
        "id": 2,
        "country_name": "Albania",
        "capital": "Tirana",
        "citizenship": "Albanian",
        "currency": "lek",
        "currency_code": "ALL",
        "full_name": "Republic of Albania",
        "short_name": "AL",
        "country_mobile_code": "355",
        "currency_symbol": "Lek",
        "flag": "AL.png"
    },
    {
        "id": 3,
        "country_name": "Antarctica",
        "capital": "Antartica",
        "citizenship": "of Antartica",
        "currency": null,
        "currency_code": null,
        "full_name": "Antarctica",
        "short_name": "AQ",
        "country_mobile_code": "672",
        "currency_symbol": null,
        "flag": "AQ.png"
    },
    {
        "id": 4,
        "country_name": "Algeria",
        "capital": "Algiers",
        "citizenship": "Algerian",
        "currency": "Algerian dinar",
        "currency_code": "DZD",
        "full_name": "People’s Democratic Republic of Algeria",
        "short_name": "DZ",
        "country_mobile_code": "213",
        "currency_symbol": "DZD",
        "flag": "DZ.png"
    },
    {
        "id": 5,
        "country_name": "American Samoa",
        "capital": "Pago Pago",
        "citizenship": "American Samoan",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Territory of American",
        "short_name": "AS",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "AS.png"
    },
    {
        "id": 6,
        "country_name": "Andorra",
        "capital": "Andorra la Vella",
        "citizenship": "Andorran",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Principality of Andorra",
        "short_name": "AD",
        "country_mobile_code": "376",
        "currency_symbol": "€",
        "flag": "AD.png"
    },
    {
        "id": 7,
        "country_name": "Angola",
        "capital": "Luanda",
        "citizenship": "Angolan",
        "currency": "kwanza",
        "currency_code": "AOA",
        "full_name": "Republic of Angola",
        "short_name": "AO",
        "country_mobile_code": "244",
        "currency_symbol": "Kz",
        "flag": "AO.png"
    },
    {
        "id": 8,
        "country_name": "Antigua and Barbuda",
        "capital": "St John’s",
        "citizenship": "of Antigua and Barbuda",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Antigua and Barbuda",
        "short_name": "AG",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "AG.png"
    },
    {
        "id": 9,
        "country_name": "Azerbaijan",
        "capital": "Baku",
        "citizenship": "Azerbaijani",
        "currency": "Azerbaijani manat",
        "currency_code": "AZN",
        "full_name": "Republic of Azerbaijan",
        "short_name": "AZ",
        "country_mobile_code": "994",
        "currency_symbol": "ман",
        "flag": "AZ.png"
    },
    {
        "id": 10,
        "country_name": "Argentina",
        "capital": "Buenos Aires",
        "citizenship": "Argentinian",
        "currency": "Argentine peso",
        "currency_code": "ARS",
        "full_name": "Argentine Republic",
        "short_name": "AR",
        "country_mobile_code": "54",
        "currency_symbol": "$",
        "flag": "AR.png"
    },
    {
        "id": 11,
        "country_name": "Australia",
        "capital": "Canberra",
        "citizenship": "Australian",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Commonwealth of Australia",
        "short_name": "AU",
        "country_mobile_code": "61",
        "currency_symbol": "$",
        "flag": "AU.png"
    },
    {
        "id": 12,
        "country_name": "Austria",
        "capital": "Vienna",
        "citizenship": "Austrian",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Austria",
        "short_name": "AT",
        "country_mobile_code": "43",
        "currency_symbol": "€",
        "flag": "AT.png"
    },
    {
        "id": 13,
        "country_name": "Bahamas",
        "capital": "Nassau",
        "citizenship": "Bahamian",
        "currency": "Bahamian dollar",
        "currency_code": "BSD",
        "full_name": "Commonwealth of the Bahamas",
        "short_name": "BS",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "BS.png"
    },
    {
        "id": 14,
        "country_name": "Bahrain",
        "capital": "Manama",
        "citizenship": "Bahraini",
        "currency": "Bahraini dinar",
        "currency_code": "BHD",
        "full_name": "Kingdom of Bahrain",
        "short_name": "BH",
        "country_mobile_code": "973",
        "currency_symbol": "BHD",
        "flag": "BH.png"
    },
    {
        "id": 15,
        "country_name": "Bangladesh",
        "capital": "Dhaka",
        "citizenship": "Bangladeshi",
        "currency": "taka (inv.)",
        "currency_code": "BDT",
        "full_name": "People’s Republic of Bangladesh",
        "short_name": "BD",
        "country_mobile_code": "880",
        "currency_symbol": "BDT",
        "flag": "BD.png"
    },
    {
        "id": 16,
        "country_name": "Armenia",
        "capital": "Yerevan",
        "citizenship": "Armenian",
        "currency": "dram (inv.)",
        "currency_code": "AMD",
        "full_name": "Republic of Armenia",
        "short_name": "AM",
        "country_mobile_code": "374",
        "currency_symbol": "AMD",
        "flag": "AM.png"
    },
    {
        "id": 17,
        "country_name": "Barbados",
        "capital": "Bridgetown",
        "citizenship": "Barbadian",
        "currency": "Barbados dollar",
        "currency_code": "BBD",
        "full_name": "Barbados",
        "short_name": "BB",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "BB.png"
    },
    {
        "id": 18,
        "country_name": "Belgium",
        "capital": "Brussels",
        "citizenship": "Belgian",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Kingdom of Belgium",
        "short_name": "BE",
        "country_mobile_code": "32",
        "currency_symbol": "€",
        "flag": "BE.png"
    },
    {
        "id": 19,
        "country_name": "Bermuda",
        "capital": "Hamilton",
        "citizenship": "Bermudian",
        "currency": "Bermuda dollar",
        "currency_code": "BMD",
        "full_name": "Bermuda",
        "short_name": "BM",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "BM.png"
    },
    {
        "id": 20,
        "country_name": "Bhutan",
        "capital": "Thimphu",
        "citizenship": "Bhutanese",
        "currency": "ngultrum (inv.)",
        "currency_code": "BTN",
        "full_name": "Kingdom of Bhutan",
        "short_name": "BT",
        "country_mobile_code": "975",
        "currency_symbol": "BTN",
        "flag": "BT.png"
    },
    {
        "id": 21,
        "country_name": "Bolivia, Plurinational State of",
        "capital": "Sucre (BO1)",
        "citizenship": "Bolivian",
        "currency": "boliviano",
        "currency_code": "BOB",
        "full_name": "Plurinational State of Bolivia",
        "short_name": "BO",
        "country_mobile_code": "591",
        "currency_symbol": "$b",
        "flag": "BO.png"
    },
    {
        "id": 22,
        "country_name": "Bosnia and Herzegovina",
        "capital": "Sarajevo",
        "citizenship": "of Bosnia and Herzegovina",
        "currency": "convertible mark",
        "currency_code": "BAM",
        "full_name": "Bosnia and Herzegovina",
        "short_name": "BA",
        "country_mobile_code": "387",
        "currency_symbol": "KM",
        "flag": "BA.png"
    },
    {
        "id": 23,
        "country_name": "Botswana",
        "capital": "Gaborone",
        "citizenship": "Botswanan",
        "currency": "pula (inv.)",
        "currency_code": "BWP",
        "full_name": "Republic of Botswana",
        "short_name": "BW",
        "country_mobile_code": "267",
        "currency_symbol": "P",
        "flag": "BW.png"
    },
    {
        "id": 24,
        "country_name": "Bouvet Island",
        "capital": "Bouvet island",
        "citizenship": "of Bouvet island",
        "currency": null,
        "currency_code": null,
        "full_name": "Bouvet Island",
        "short_name": "BV",
        "country_mobile_code": "47",
        "currency_symbol": "kr",
        "flag": "BV.png"
    },
    {
        "id": 25,
        "country_name": "Brazil",
        "capital": "Brasilia",
        "citizenship": "Brazilian",
        "currency": "real (pl. reais)",
        "currency_code": "BRL",
        "full_name": "Federative Republic of Brazil",
        "short_name": "BR",
        "country_mobile_code": "55",
        "currency_symbol": "R$",
        "flag": "BR.png"
    },
    {
        "id": 26,
        "country_name": "Belize",
        "capital": "Belmopan",
        "citizenship": "Belizean",
        "currency": "Belize dollar",
        "currency_code": "BZD",
        "full_name": "Belize",
        "short_name": "BZ",
        "country_mobile_code": "501",
        "currency_symbol": "BZ$",
        "flag": "BZ.png"
    },
    {
        "id": 27,
        "country_name": "British Indian Ocean Territory",
        "capital": "Diego Garcia",
        "citizenship": "Changosian",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "British Indian Ocean Territory",
        "short_name": "IO",
        "country_mobile_code": "246",
        "currency_symbol": "$",
        "flag": "IO.png"
    },
    {
        "id": 28,
        "country_name": "Solomon Islands",
        "capital": "Honiara",
        "citizenship": "Solomon Islander",
        "currency": "Solomon Islands dollar",
        "currency_code": "SBD",
        "full_name": "Solomon Islands",
        "short_name": "SB",
        "country_mobile_code": "677",
        "currency_symbol": "$",
        "flag": "SB.png"
    },
    {
        "id": 29,
        "country_name": "Virgin Islands, British",
        "capital": "Road Town",
        "citizenship": "British Virgin Islander;",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "British Virgin Islands",
        "short_name": "VG",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "VG.png"
    },
    {
        "id": 30,
        "country_name": "Brunei Darussalam",
        "capital": "Bandar Seri Begawan",
        "citizenship": "Bruneian",
        "currency": "Brunei dollar",
        "currency_code": "BND",
        "full_name": "Brunei Darussalam",
        "short_name": "BN",
        "country_mobile_code": "673",
        "currency_symbol": "$",
        "flag": "BN.png"
    },
    {
        "id": 31,
        "country_name": "Bulgaria",
        "capital": "Sofia",
        "citizenship": "Bulgarian",
        "currency": "lev (pl. leva)",
        "currency_code": "BGN",
        "full_name": "Republic of Bulgaria",
        "short_name": "BG",
        "country_mobile_code": "359",
        "currency_symbol": "лв",
        "flag": "BG.png"
    },
    {
        "id": 32,
        "country_name": "Myanmar",
        "capital": "Yangon",
        "citizenship": "Burmese",
        "currency": "kyat",
        "currency_code": "MMK",
        "full_name": "Union of Myanmar/",
        "short_name": "MM",
        "country_mobile_code": "95",
        "currency_symbol": "K",
        "flag": "MM.png"
    },
    {
        "id": 33,
        "country_name": "Burundi",
        "capital": "Bujumbura",
        "citizenship": "Burundian",
        "currency": "Burundi franc",
        "currency_code": "BIF",
        "full_name": "Republic of Burundi",
        "short_name": "BI",
        "country_mobile_code": "257",
        "currency_symbol": "BIF",
        "flag": "BI.png"
    },
    {
        "id": 34,
        "country_name": "Belarus",
        "capital": "Minsk",
        "citizenship": "Belarusian",
        "currency": "Belarusian rouble",
        "currency_code": "BYR",
        "full_name": "Republic of Belarus",
        "short_name": "BY",
        "country_mobile_code": "375",
        "currency_symbol": "p.",
        "flag": "BY.png"
    },
    {
        "id": 35,
        "country_name": "Cambodia",
        "capital": "Phnom Penh",
        "citizenship": "Cambodian",
        "currency": "riel",
        "currency_code": "KHR",
        "full_name": "Kingdom of Cambodia",
        "short_name": "KH",
        "country_mobile_code": "855",
        "currency_symbol": "៛",
        "flag": "KH.png"
    },
    {
        "id": 36,
        "country_name": "Cameroon",
        "capital": "Yaoundé",
        "citizenship": "Cameroonian",
        "currency": "CFA franc (BEAC)",
        "currency_code": "XAF",
        "full_name": "Republic of Cameroon",
        "short_name": "CM",
        "country_mobile_code": "237",
        "currency_symbol": "FCF",
        "flag": "CM.png"
    },
    {
        "id": 37,
        "country_name": "Canada",
        "capital": "Ottawa",
        "citizenship": "Canadian",
        "currency": "Canadian dollar",
        "currency_code": "CAD",
        "full_name": "Canada",
        "short_name": "CA",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "CA.png"
    },
    {
        "id": 38,
        "country_name": "Cape Verde",
        "capital": "Praia",
        "citizenship": "Cape Verdean",
        "currency": "Cape Verde escudo",
        "currency_code": "CVE",
        "full_name": "Republic of Cape Verde",
        "short_name": "CV",
        "country_mobile_code": "238",
        "currency_symbol": "CVE",
        "flag": "CV.png"
    },
    {
        "id": 39,
        "country_name": "Cayman Islands",
        "capital": "George Town",
        "citizenship": "Caymanian",
        "currency": "Cayman Islands dollar",
        "currency_code": "KYD",
        "full_name": "Cayman Islands",
        "short_name": "KY",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "KY.png"
    },
    {
        "id": 40,
        "country_name": "Central African Republic",
        "capital": "Bangui",
        "citizenship": "Central African",
        "currency": "CFA franc (BEAC)",
        "currency_code": "XAF",
        "full_name": "Central African Republic",
        "short_name": "CF",
        "country_mobile_code": "236",
        "currency_symbol": "CFA",
        "flag": "CF.png"
    },
    {
        "id": 41,
        "country_name": "Sri Lanka",
        "capital": "Colombo",
        "citizenship": "Sri Lankan",
        "currency": "Sri Lankan rupee",
        "currency_code": "LKR",
        "full_name": "Democratic Socialist Republic of Sri Lanka",
        "short_name": "LK",
        "country_mobile_code": "94",
        "currency_symbol": "₨",
        "flag": "LK.png"
    },
    {
        "id": 42,
        "country_name": "Chad",
        "capital": "N’Djamena",
        "citizenship": "Chadian",
        "currency": "CFA franc (BEAC)",
        "currency_code": "XAF",
        "full_name": "Republic of Chad",
        "short_name": "TD",
        "country_mobile_code": "235",
        "currency_symbol": "XAF",
        "flag": "TD.png"
    },
    {
        "id": 43,
        "country_name": "Chile",
        "capital": "Santiago",
        "citizenship": "Chilean",
        "currency": "Chilean peso",
        "currency_code": "CLP",
        "full_name": "Republic of Chile",
        "short_name": "CL",
        "country_mobile_code": "56",
        "currency_symbol": "CLP",
        "flag": "CL.png"
    },
    {
        "id": 44,
        "country_name": "China",
        "capital": "Beijing",
        "citizenship": "Chinese",
        "currency": "renminbi-yuan (inv.)",
        "currency_code": "CNY",
        "full_name": "People’s Republic of China",
        "short_name": "CN",
        "country_mobile_code": "86",
        "currency_symbol": "¥",
        "flag": "CN.png"
    },
    {
        "id": 45,
        "country_name": "Taiwan, Province of China",
        "capital": "Taipei",
        "citizenship": "Taiwanese",
        "currency": "new Taiwan dollar",
        "currency_code": "TWD",
        "full_name": "Republic of China, Taiwan (TW1)",
        "short_name": "TW",
        "country_mobile_code": "886",
        "currency_symbol": "NT$",
        "flag": "TW.png"
    },
    {
        "id": 46,
        "country_name": "Christmas Island",
        "capital": "Flying Fish Cove",
        "citizenship": "Christmas Islander",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Christmas Island Territory",
        "short_name": "CX",
        "country_mobile_code": "61",
        "currency_symbol": "$",
        "flag": "CX.png"
    },
    {
        "id": 47,
        "country_name": "Cocos (Keeling) Islands",
        "capital": "Bantam",
        "citizenship": "Cocos Islander",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Territory of Cocos (Keeling) Islands",
        "short_name": "CC",
        "country_mobile_code": "61",
        "currency_symbol": "$",
        "flag": "CC.png"
    },
    {
        "id": 48,
        "country_name": "Colombia",
        "capital": "Santa Fe de Bogotá",
        "citizenship": "Colombian",
        "currency": "Colombian peso",
        "currency_code": "COP",
        "full_name": "Republic of Colombia",
        "short_name": "CO",
        "country_mobile_code": "57",
        "currency_symbol": "$",
        "flag": "CO.png"
    },
    {
        "id": 49,
        "country_name": "Comoros",
        "capital": "Moroni",
        "citizenship": "Comorian",
        "currency": "Comorian franc",
        "currency_code": "KMF",
        "full_name": "Union of the Comoros",
        "short_name": "KM",
        "country_mobile_code": "269",
        "currency_symbol": "KMF",
        "flag": "KM.png"
    },
    {
        "id": 50,
        "country_name": "Mayotte",
        "capital": "Mamoudzou",
        "citizenship": "Mahorais",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Departmental Collectivity of Mayotte",
        "short_name": "YT",
        "country_mobile_code": "262",
        "currency_symbol": "€",
        "flag": "YT.png"
    },
    {
        "id": 51,
        "country_name": "Congo",
        "capital": "Brazzaville",
        "citizenship": "Congolese",
        "currency": "CFA franc (BEAC)",
        "currency_code": "XAF",
        "full_name": "Republic of the Congo",
        "short_name": "CG",
        "country_mobile_code": "242",
        "currency_symbol": "FCF",
        "flag": "CG.png"
    },
    {
        "id": 52,
        "country_name": "Congo, the Democratic Republic of the",
        "capital": "Kinshasa",
        "citizenship": "Congolese",
        "currency": "Congolese franc",
        "currency_code": "CDF",
        "full_name": "Democratic Republic of the Congo",
        "short_name": "CD",
        "country_mobile_code": "243",
        "currency_symbol": "CDF",
        "flag": "CD.png"
    },
    {
        "id": 53,
        "country_name": "Cook Islands",
        "capital": "Avarua",
        "citizenship": "Cook Islander",
        "currency": "New Zealand dollar",
        "currency_code": "NZD",
        "full_name": "Cook Islands",
        "short_name": "CK",
        "country_mobile_code": "682",
        "currency_symbol": "$",
        "flag": "CK.png"
    },
    {
        "id": 54,
        "country_name": "Costa Rica",
        "capital": "San José",
        "citizenship": "Costa Rican",
        "currency": "Costa Rican colón (pl. colones)",
        "currency_code": "CRC",
        "full_name": "Republic of Costa Rica",
        "short_name": "CR",
        "country_mobile_code": "506",
        "currency_symbol": "₡",
        "flag": "CR.png"
    },
    {
        "id": 55,
        "country_name": "Croatia",
        "capital": "Zagreb",
        "citizenship": "Croatian",
        "currency": "kuna (inv.)",
        "currency_code": "HRK",
        "full_name": "Republic of Croatia",
        "short_name": "HR",
        "country_mobile_code": "385",
        "currency_symbol": "kn",
        "flag": "HR.png"
    },
    {
        "id": 56,
        "country_name": "Cuba",
        "capital": "Havana",
        "citizenship": "Cuban",
        "currency": "Cuban peso",
        "currency_code": "CUP",
        "full_name": "Republic of Cuba",
        "short_name": "CU",
        "country_mobile_code": "53",
        "currency_symbol": "₱",
        "flag": "CU.png"
    },
    {
        "id": 57,
        "country_name": "Cyprus",
        "capital": "Nicosia",
        "citizenship": "Cypriot",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Cyprus",
        "short_name": "CY",
        "country_mobile_code": "357",
        "currency_symbol": "CYP",
        "flag": "CY.png"
    },
    {
        "id": 58,
        "country_name": "Czech Republic",
        "capital": "Prague",
        "citizenship": "Czech",
        "currency": "Czech koruna (pl. koruny)",
        "currency_code": "CZK",
        "full_name": "Czech Republic",
        "short_name": "CZ",
        "country_mobile_code": "420",
        "currency_symbol": "Kč",
        "flag": "CZ.png"
    },
    {
        "id": 59,
        "country_name": "Benin",
        "capital": "Porto Novo (BJ1)",
        "citizenship": "Beninese",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Republic of Benin",
        "short_name": "BJ",
        "country_mobile_code": "229",
        "currency_symbol": "XOF",
        "flag": "BJ.png"
    },
    {
        "id": 60,
        "country_name": "Denmark",
        "capital": "Copenhagen",
        "citizenship": "Danish",
        "currency": "Danish krone",
        "currency_code": "DKK",
        "full_name": "Kingdom of Denmark",
        "short_name": "DK",
        "country_mobile_code": "45",
        "currency_symbol": "kr",
        "flag": "DK.png"
    },
    {
        "id": 61,
        "country_name": "Dominica",
        "capital": "Roseau",
        "citizenship": "Dominican",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Commonwealth of Dominica",
        "short_name": "DM",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "DM.png"
    },
    {
        "id": 62,
        "country_name": "Dominican Republic",
        "capital": "Santo Domingo",
        "citizenship": "Dominican",
        "currency": "Dominican peso",
        "currency_code": "DOP",
        "full_name": "Dominican Republic",
        "short_name": "DO",
        "country_mobile_code": "1",
        "currency_symbol": "RD$",
        "flag": "DO.png"
    },
    {
        "id": 63,
        "country_name": "Ecuador",
        "capital": "Quito",
        "citizenship": "Ecuadorian",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Republic of Ecuador",
        "short_name": "EC",
        "country_mobile_code": "593",
        "currency_symbol": "$",
        "flag": "EC.png"
    },
    {
        "id": 64,
        "country_name": "El Salvador",
        "capital": "San Salvador",
        "citizenship": "Salvadoran",
        "currency": "Salvadorian colón (pl. colones)",
        "currency_code": "SVC",
        "full_name": "Republic of El Salvador",
        "short_name": "SV",
        "country_mobile_code": "503",
        "currency_symbol": "$",
        "flag": "SV.png"
    },
    {
        "id": 65,
        "country_name": "Equatorial Guinea",
        "capital": "Malabo",
        "citizenship": "Equatorial Guinean",
        "currency": "CFA franc (BEAC)",
        "currency_code": "XAF",
        "full_name": "Republic of Equatorial Guinea",
        "short_name": "GQ",
        "country_mobile_code": "240",
        "currency_symbol": "FCF",
        "flag": "GQ.png"
    },
    {
        "id": 66,
        "country_name": "Ethiopia",
        "capital": "Addis Ababa",
        "citizenship": "Ethiopian",
        "currency": "birr (inv.)",
        "currency_code": "ETB",
        "full_name": "Federal Democratic Republic of Ethiopia",
        "short_name": "ET",
        "country_mobile_code": "251",
        "currency_symbol": "ETB",
        "flag": "ET.png"
    },
    {
        "id": 67,
        "country_name": "Eritrea",
        "capital": "Asmara",
        "citizenship": "Eritrean",
        "currency": "nakfa",
        "currency_code": "ERN",
        "full_name": "State of Eritrea",
        "short_name": "ER",
        "country_mobile_code": "291",
        "currency_symbol": "Nfk",
        "flag": "ER.png"
    },
    {
        "id": 68,
        "country_name": "Estonia",
        "capital": "Tallinn",
        "citizenship": "Estonian",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Estonia",
        "short_name": "EE",
        "country_mobile_code": "372",
        "currency_symbol": "kr",
        "flag": "EE.png"
    },
    {
        "id": 69,
        "country_name": "Faroe Islands",
        "capital": "Tórshavn",
        "citizenship": "Faeroese",
        "currency": "Danish krone",
        "currency_code": "DKK",
        "full_name": "Faeroe Islands",
        "short_name": "FO",
        "country_mobile_code": "298",
        "currency_symbol": "kr",
        "flag": "FO.png"
    },
    {
        "id": 70,
        "country_name": "Falkland Islands (Malvinas)",
        "capital": "Stanley",
        "citizenship": "Falkland Islander",
        "currency": "Falkland Islands pound",
        "currency_code": "FKP",
        "full_name": "Falkland Islands",
        "short_name": "FK",
        "country_mobile_code": "500",
        "currency_symbol": "£",
        "flag": "FK.png"
    },
    {
        "id": 71,
        "country_name": "South Georgia and the South Sandwich Islands",
        "capital": "King Edward Point (Grytviken)",
        "citizenship": "of South Georgia and the South Sandwich Islands",
        "currency": null,
        "currency_code": null,
        "full_name": "South Georgia and the South Sandwich Islands",
        "short_name": "GS",
        "country_mobile_code": "44",
        "currency_symbol": "£",
        "flag": "GS.png"
    },
    {
        "id": 72,
        "country_name": "Fiji",
        "capital": "Suva",
        "citizenship": "Fijian",
        "currency": "Fiji dollar",
        "currency_code": "FJD",
        "full_name": "Republic of Fiji",
        "short_name": "FJ",
        "country_mobile_code": "679",
        "currency_symbol": "$",
        "flag": "FJ.png"
    },
    {
        "id": 73,
        "country_name": "Finland",
        "capital": "Helsinki",
        "citizenship": "Finnish",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Finland",
        "short_name": "FI",
        "country_mobile_code": "358",
        "currency_symbol": "€",
        "flag": "FI.png"
    },
    {
        "id": 74,
        "country_name": "Åland Islands",
        "capital": "Mariehamn",
        "citizenship": "Åland Islander",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Åland Islands",
        "short_name": "AX",
        "country_mobile_code": "358",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 75,
        "country_name": "France",
        "capital": "Paris",
        "citizenship": "French",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "French Republic",
        "short_name": "FR",
        "country_mobile_code": "33",
        "currency_symbol": "€",
        "flag": "FR.png"
    },
    {
        "id": 76,
        "country_name": "French Guiana",
        "capital": "Cayenne",
        "citizenship": "Guianese",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "French Guiana",
        "short_name": "GF",
        "country_mobile_code": "594",
        "currency_symbol": "€",
        "flag": "GF.png"
    },
    {
        "id": 77,
        "country_name": "French Polynesia",
        "capital": "Papeete",
        "citizenship": "Polynesian",
        "currency": "CFP franc",
        "currency_code": "XPF",
        "full_name": "French Polynesia",
        "short_name": "PF",
        "country_mobile_code": "689",
        "currency_symbol": "XPF",
        "flag": "PF.png"
    },
    {
        "id": 78,
        "country_name": "French Southern Territories",
        "capital": "Port-aux-Francais",
        "citizenship": "of French Southern and Antarctic Lands",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "French Southern and Antarctic Lands",
        "short_name": "TF",
        "country_mobile_code": "33",
        "currency_symbol": "€",
        "flag": "TF.png"
    },
    {
        "id": 79,
        "country_name": "Djibouti",
        "capital": "Djibouti",
        "citizenship": "Djiboutian",
        "currency": "Djibouti franc",
        "currency_code": "DJF",
        "full_name": "Republic of Djibouti",
        "short_name": "DJ",
        "country_mobile_code": "253",
        "currency_symbol": "DJF",
        "flag": "DJ.png"
    },
    {
        "id": 80,
        "country_name": "Gabon",
        "capital": "Libreville",
        "citizenship": "Gabonese",
        "currency": "CFA franc (BEAC)",
        "currency_code": "XAF",
        "full_name": "Gabonese Republic",
        "short_name": "GA",
        "country_mobile_code": "241",
        "currency_symbol": "FCF",
        "flag": "GA.png"
    },
    {
        "id": 81,
        "country_name": "Georgia",
        "capital": "Tbilisi",
        "citizenship": "Georgian",
        "currency": "lari",
        "currency_code": "GEL",
        "full_name": "Georgia",
        "short_name": "GE",
        "country_mobile_code": "995",
        "currency_symbol": "GEL",
        "flag": "GE.png"
    },
    {
        "id": 82,
        "country_name": "Gambia",
        "capital": "Banjul",
        "citizenship": "Gambian",
        "currency": "dalasi (inv.)",
        "currency_code": "GMD",
        "full_name": "Republic of the Gambia",
        "short_name": "GM",
        "country_mobile_code": "220",
        "currency_symbol": "D",
        "flag": "GM.png"
    },
    {
        "id": 83,
        "country_name": "Palestinian Territory, Occupied",
        "capital": null,
        "citizenship": "Palestinian",
        "currency": null,
        "currency_code": null,
        "full_name": null,
        "short_name": "PS",
        "country_mobile_code": "970",
        "currency_symbol": "₪",
        "flag": "PS.png"
    },
    {
        "id": 84,
        "country_name": "Germany",
        "capital": "Berlin",
        "citizenship": "German",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Federal Republic of Germany",
        "short_name": "DE",
        "country_mobile_code": "49",
        "currency_symbol": "€",
        "flag": "DE.png"
    },
    {
        "id": 85,
        "country_name": "Ghana",
        "capital": "Accra",
        "citizenship": "Ghanaian",
        "currency": "Ghana cedi",
        "currency_code": "GHS",
        "full_name": "Republic of Ghana",
        "short_name": "GH",
        "country_mobile_code": "233",
        "currency_symbol": "¢",
        "flag": "GH.png"
    },
    {
        "id": 86,
        "country_name": "Gibraltar",
        "capital": "Gibraltar",
        "citizenship": "Gibraltarian",
        "currency": "Gibraltar pound",
        "currency_code": "GIP",
        "full_name": "Gibraltar",
        "short_name": "GI",
        "country_mobile_code": "350",
        "currency_symbol": "£",
        "flag": "GI.png"
    },
    {
        "id": 87,
        "country_name": "Kiribati",
        "capital": "Tarawa",
        "citizenship": "Kiribatian",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Republic of Kiribati",
        "short_name": "KI",
        "country_mobile_code": "686",
        "currency_symbol": "$",
        "flag": "KI.png"
    },
    {
        "id": 88,
        "country_name": "Greece",
        "capital": "Athens",
        "citizenship": "Greek",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Hellenic Republic",
        "short_name": "GR",
        "country_mobile_code": "30",
        "currency_symbol": "€",
        "flag": "GR.png"
    },
    {
        "id": 89,
        "country_name": "Greenland",
        "capital": "Nuuk",
        "citizenship": "Greenlander",
        "currency": "Danish krone",
        "currency_code": "DKK",
        "full_name": "Greenland",
        "short_name": "GL",
        "country_mobile_code": "299",
        "currency_symbol": "kr",
        "flag": "GL.png"
    },
    {
        "id": 90,
        "country_name": "Grenada",
        "capital": "St George’s",
        "citizenship": "Grenadian",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Grenada",
        "short_name": "GD",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "GD.png"
    },
    {
        "id": 91,
        "country_name": "Guadeloupe",
        "capital": "Basse Terre",
        "citizenship": "Guadeloupean",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Guadeloupe",
        "short_name": "GP",
        "country_mobile_code": "590",
        "currency_symbol": "€",
        "flag": "GP.png"
    },
    {
        "id": 92,
        "country_name": "Guam",
        "capital": "Agaña (Hagåtña)",
        "citizenship": "Guamanian",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Territory of Guam",
        "short_name": "GU",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "GU.png"
    },
    {
        "id": 93,
        "country_name": "Guatemala",
        "capital": "Guatemala City",
        "citizenship": "Guatemalan",
        "currency": "quetzal (pl. quetzales)",
        "currency_code": "GTQ",
        "full_name": "Republic of Guatemala",
        "short_name": "GT",
        "country_mobile_code": "502",
        "currency_symbol": "Q",
        "flag": "GT.png"
    },
    {
        "id": 94,
        "country_name": "Guinea",
        "capital": "Conakry",
        "citizenship": "Guinean",
        "currency": "Guinean franc",
        "currency_code": "GNF",
        "full_name": "Republic of Guinea",
        "short_name": "GN",
        "country_mobile_code": "224",
        "currency_symbol": "GNF",
        "flag": "GN.png"
    },
    {
        "id": 95,
        "country_name": "Guyana",
        "capital": "Georgetown",
        "citizenship": "Guyanese",
        "currency": "Guyana dollar",
        "currency_code": "GYD",
        "full_name": "Cooperative Republic of Guyana",
        "short_name": "GY",
        "country_mobile_code": "592",
        "currency_symbol": "$",
        "flag": "GY.png"
    },
    {
        "id": 96,
        "country_name": "Haiti",
        "capital": "Port-au-Prince",
        "citizenship": "Haitian",
        "currency": "gourde",
        "currency_code": "HTG",
        "full_name": "Republic of Haiti",
        "short_name": "HT",
        "country_mobile_code": "509",
        "currency_symbol": "G",
        "flag": "HT.png"
    },
    {
        "id": 97,
        "country_name": "Heard Island and McDonald Islands",
        "capital": "Territory of Heard Island and McDonald Islands",
        "citizenship": "of Territory of Heard Island and McDonald Islands",
        "currency": null,
        "currency_code": null,
        "full_name": "Territory of Heard Island and McDonald Islands",
        "short_name": "HM",
        "country_mobile_code": "61",
        "currency_symbol": "$",
        "flag": "HM.png"
    },
    {
        "id": 98,
        "country_name": "Holy See (Vatican City State)",
        "capital": "Vatican City",
        "citizenship": "of the Holy See/of the Vatican",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "the Holy See/ Vatican City State",
        "short_name": "VA",
        "country_mobile_code": "39",
        "currency_symbol": "€",
        "flag": "VA.png"
    },
    {
        "id": 99,
        "country_name": "Honduras",
        "capital": "Tegucigalpa",
        "citizenship": "Honduran",
        "currency": "lempira",
        "currency_code": "HNL",
        "full_name": "Republic of Honduras",
        "short_name": "HN",
        "country_mobile_code": "504",
        "currency_symbol": "L",
        "flag": "HN.png"
    },
    {
        "id": 100,
        "country_name": "Hong Kong",
        "capital": "(HK3)",
        "citizenship": "Hong Kong Chinese",
        "currency": "Hong Kong dollar",
        "currency_code": "HKD",
        "full_name": "Hong Kong Special Administrative Region of the People’s Republic of China (HK2)",
        "short_name": "HK",
        "country_mobile_code": "852",
        "currency_symbol": "$",
        "flag": "HK.png"
    },
    {
        "id": 101,
        "country_name": "Hungary",
        "capital": "Budapest",
        "citizenship": "Hungarian",
        "currency": "forint (inv.)",
        "currency_code": "HUF",
        "full_name": "Republic of Hungary",
        "short_name": "HU",
        "country_mobile_code": "36",
        "currency_symbol": "Ft",
        "flag": "HU.png"
    },
    {
        "id": 102,
        "country_name": "Iceland",
        "capital": "Reykjavik",
        "citizenship": "Icelander",
        "currency": "króna (pl. krónur)",
        "currency_code": "ISK",
        "full_name": "Republic of Iceland",
        "short_name": "IS",
        "country_mobile_code": "354",
        "currency_symbol": "kr",
        "flag": "IS.png"
    },
    {
        "id": 103,
        "country_name": "India",
        "capital": "New Delhi",
        "citizenship": "Indian",
        "currency": "Indian rupee",
        "currency_code": "INR",
        "full_name": "Republic of India",
        "short_name": "IN",
        "country_mobile_code": "91",
        "currency_symbol": "₹",
        "flag": "IN.png"
    },
    {
        "id": 104,
        "country_name": "Indonesia",
        "capital": "Jakarta",
        "citizenship": "Indonesian",
        "currency": "Indonesian rupiah (inv.)",
        "currency_code": "IDR",
        "full_name": "Republic of Indonesia",
        "short_name": "ID",
        "country_mobile_code": "62",
        "currency_symbol": "Rp",
        "flag": "ID.png"
    },
    {
        "id": 105,
        "country_name": "Iran, Islamic Republic of",
        "capital": "Tehran",
        "citizenship": "Iranian",
        "currency": "Iranian rial",
        "currency_code": "IRR",
        "full_name": "Islamic Republic of Iran",
        "short_name": "IR",
        "country_mobile_code": "98",
        "currency_symbol": "﷼",
        "flag": "IR.png"
    },
    {
        "id": 106,
        "country_name": "Iraq",
        "capital": "Baghdad",
        "citizenship": "Iraqi",
        "currency": "Iraqi dinar",
        "currency_code": "IQD",
        "full_name": "Republic of Iraq",
        "short_name": "IQ",
        "country_mobile_code": "964",
        "currency_symbol": "IQD",
        "flag": "IQ.png"
    },
    {
        "id": 107,
        "country_name": "Ireland",
        "capital": "Dublin",
        "citizenship": "Irish",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Ireland (IE1)",
        "short_name": "IE",
        "country_mobile_code": "353",
        "currency_symbol": "€",
        "flag": "IE.png"
    },
    {
        "id": 108,
        "country_name": "Israel",
        "capital": "(IL1)",
        "citizenship": "Israeli",
        "currency": "shekel",
        "currency_code": "ILS",
        "full_name": "State of Israel",
        "short_name": "IL",
        "country_mobile_code": "972",
        "currency_symbol": "₪",
        "flag": "IL.png"
    },
    {
        "id": 109,
        "country_name": "Italy",
        "capital": "Rome",
        "citizenship": "Italian",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Italian Republic",
        "short_name": "IT",
        "country_mobile_code": "39",
        "currency_symbol": "€",
        "flag": "IT.png"
    },
    {
        "id": 110,
        "country_name": "Côte d'Ivoire",
        "capital": "Yamoussoukro (CI1)",
        "citizenship": "Ivorian",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Republic of Côte d’Ivoire",
        "short_name": "CI",
        "country_mobile_code": "225",
        "currency_symbol": "XOF",
        "flag": "CI.png"
    },
    {
        "id": 111,
        "country_name": "Jamaica",
        "capital": "Kingston",
        "citizenship": "Jamaican",
        "currency": "Jamaica dollar",
        "currency_code": "JMD",
        "full_name": "Jamaica",
        "short_name": "JM",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "JM.png"
    },
    {
        "id": 112,
        "country_name": "Japan",
        "capital": "Tokyo",
        "citizenship": "Japanese",
        "currency": "yen (inv.)",
        "currency_code": "JPY",
        "full_name": "Japan",
        "short_name": "JP",
        "country_mobile_code": "81",
        "currency_symbol": "¥",
        "flag": "JP.png"
    },
    {
        "id": 113,
        "country_name": "Kazakhstan",
        "capital": "Astana",
        "citizenship": "Kazakh",
        "currency": "tenge (inv.)",
        "currency_code": "KZT",
        "full_name": "Republic of Kazakhstan",
        "short_name": "KZ",
        "country_mobile_code": "7",
        "currency_symbol": "лв",
        "flag": "KZ.png"
    },
    {
        "id": 114,
        "country_name": "Jordan",
        "capital": "Amman",
        "citizenship": "Jordanian",
        "currency": "Jordanian dinar",
        "currency_code": "JOD",
        "full_name": "Hashemite Kingdom of Jordan",
        "short_name": "JO",
        "country_mobile_code": "962",
        "currency_symbol": "JOD",
        "flag": "JO.png"
    },
    {
        "id": 115,
        "country_name": "Kenya",
        "capital": "Nairobi",
        "citizenship": "Kenyan",
        "currency": "Kenyan shilling",
        "currency_code": "KES",
        "full_name": "Republic of Kenya",
        "short_name": "KE",
        "country_mobile_code": "254",
        "currency_symbol": "KES",
        "flag": "KE.png"
    },
    {
        "id": 116,
        "country_name": "Korea, Democratic People's Republic of",
        "capital": "Pyongyang",
        "citizenship": "North Korean",
        "currency": "North Korean won (inv.)",
        "currency_code": "KPW",
        "full_name": "Democratic People’s Republic of Korea",
        "short_name": "KP",
        "country_mobile_code": "850",
        "currency_symbol": "₩",
        "flag": "KP.png"
    },
    {
        "id": 117,
        "country_name": "Korea, Republic of",
        "capital": "Seoul",
        "citizenship": "South Korean",
        "currency": "South Korean won (inv.)",
        "currency_code": "KRW",
        "full_name": "Republic of Korea",
        "short_name": "KR",
        "country_mobile_code": "82",
        "currency_symbol": "₩",
        "flag": "KR.png"
    },
    {
        "id": 118,
        "country_name": "Kuwait",
        "capital": "Kuwait City",
        "citizenship": "Kuwaiti",
        "currency": "Kuwaiti dinar",
        "currency_code": "KWD",
        "full_name": "State of Kuwait",
        "short_name": "KW",
        "country_mobile_code": "965",
        "currency_symbol": "KWD",
        "flag": "KW.png"
    },
    {
        "id": 119,
        "country_name": "Kyrgyzstan",
        "capital": "Bishkek",
        "citizenship": "Kyrgyz",
        "currency": "som",
        "currency_code": "KGS",
        "full_name": "Kyrgyz Republic",
        "short_name": "KG",
        "country_mobile_code": "996",
        "currency_symbol": "лв",
        "flag": "KG.png"
    },
    {
        "id": 120,
        "country_name": "Lao People's Democratic Republic",
        "capital": "Vientiane",
        "citizenship": "Lao",
        "currency": "kip (inv.)",
        "currency_code": "LAK",
        "full_name": "Lao People’s Democratic Republic",
        "short_name": "LA",
        "country_mobile_code": "856",
        "currency_symbol": "₭",
        "flag": "LA.png"
    },
    {
        "id": 121,
        "country_name": "Lebanon",
        "capital": "Beirut",
        "citizenship": "Lebanese",
        "currency": "Lebanese pound",
        "currency_code": "LBP",
        "full_name": "Lebanese Republic",
        "short_name": "LB",
        "country_mobile_code": "961",
        "currency_symbol": "£",
        "flag": "LB.png"
    },
    {
        "id": 122,
        "country_name": "Lesotho",
        "capital": "Maseru",
        "citizenship": "Basotho",
        "currency": "loti (pl. maloti)",
        "currency_code": "LSL",
        "full_name": "Kingdom of Lesotho",
        "short_name": "LS",
        "country_mobile_code": "266",
        "currency_symbol": "L",
        "flag": "LS.png"
    },
    {
        "id": 123,
        "country_name": "Latvia",
        "capital": "Riga",
        "citizenship": "Latvian",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Latvia",
        "short_name": "LV",
        "country_mobile_code": "371",
        "currency_symbol": "Ls",
        "flag": "LV.png"
    },
    {
        "id": 124,
        "country_name": "Liberia",
        "capital": "Monrovia",
        "citizenship": "Liberian",
        "currency": "Liberian dollar",
        "currency_code": "LRD",
        "full_name": "Republic of Liberia",
        "short_name": "LR",
        "country_mobile_code": "231",
        "currency_symbol": "$",
        "flag": "LR.png"
    },
    {
        "id": 125,
        "country_name": "Libya",
        "capital": "Tripoli",
        "citizenship": "Libyan",
        "currency": "Libyan dinar",
        "currency_code": "LYD",
        "full_name": "Socialist People’s Libyan Arab Jamahiriya",
        "short_name": "LY",
        "country_mobile_code": "218",
        "currency_symbol": "LYD",
        "flag": "LY.png"
    },
    {
        "id": 126,
        "country_name": "Liechtenstein",
        "capital": "Vaduz",
        "citizenship": "Liechtensteiner",
        "currency": "Swiss franc",
        "currency_code": "CHF",
        "full_name": "Principality of Liechtenstein",
        "short_name": "LI",
        "country_mobile_code": "423",
        "currency_symbol": "CHF",
        "flag": "LI.png"
    },
    {
        "id": 127,
        "country_name": "Lithuania",
        "capital": "Vilnius",
        "citizenship": "Lithuanian",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Lithuania",
        "short_name": "LT",
        "country_mobile_code": "370",
        "currency_symbol": "Lt",
        "flag": "LT.png"
    },
    {
        "id": 128,
        "country_name": "Luxembourg",
        "capital": "Luxembourg",
        "citizenship": "Luxembourger",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Grand Duchy of Luxembourg",
        "short_name": "LU",
        "country_mobile_code": "352",
        "currency_symbol": "€",
        "flag": "LU.png"
    },
    {
        "id": 129,
        "country_name": "Macao",
        "capital": "Macao (MO3)",
        "citizenship": "Macanese",
        "currency": "pataca",
        "currency_code": "MOP",
        "full_name": "Macao Special Administrative Region of the People’s Republic of China (MO2)",
        "short_name": "MO",
        "country_mobile_code": "853",
        "currency_symbol": "MOP",
        "flag": "MO.png"
    },
    {
        "id": 130,
        "country_name": "Madagascar",
        "capital": "Antananarivo",
        "citizenship": "Malagasy",
        "currency": "ariary",
        "currency_code": "MGA",
        "full_name": "Republic of Madagascar",
        "short_name": "MG",
        "country_mobile_code": "261",
        "currency_symbol": "MGA",
        "flag": "MG.png"
    },
    {
        "id": 131,
        "country_name": "Malawi",
        "capital": "Lilongwe",
        "citizenship": "Malawian",
        "currency": "Malawian kwacha (inv.)",
        "currency_code": "MWK",
        "full_name": "Republic of Malawi",
        "short_name": "MW",
        "country_mobile_code": "265",
        "currency_symbol": "MK",
        "flag": "MW.png"
    },
    {
        "id": 132,
        "country_name": "Malaysia",
        "capital": "Kuala Lumpur (MY1)",
        "citizenship": "Malaysian",
        "currency": "ringgit (inv.)",
        "currency_code": "MYR",
        "full_name": "Malaysia",
        "short_name": "MY",
        "country_mobile_code": "60",
        "currency_symbol": "RM",
        "flag": "MY.png"
    },
    {
        "id": 133,
        "country_name": "Maldives",
        "capital": "Malé",
        "citizenship": "Maldivian",
        "currency": "rufiyaa",
        "currency_code": "MVR",
        "full_name": "Republic of Maldives",
        "short_name": "MV",
        "country_mobile_code": "960",
        "currency_symbol": "Rf",
        "flag": "MV.png"
    },
    {
        "id": 134,
        "country_name": "Mali",
        "capital": "Bamako",
        "citizenship": "Malian",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Republic of Mali",
        "short_name": "ML",
        "country_mobile_code": "223",
        "currency_symbol": "XOF",
        "flag": "ML.png"
    },
    {
        "id": 135,
        "country_name": "Malta",
        "capital": "Valletta",
        "citizenship": "Maltese",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Malta",
        "short_name": "MT",
        "country_mobile_code": "356",
        "currency_symbol": "MTL",
        "flag": "MT.png"
    },
    {
        "id": 136,
        "country_name": "Martinique",
        "capital": "Fort-de-France",
        "citizenship": "Martinican",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Martinique",
        "short_name": "MQ",
        "country_mobile_code": "596",
        "currency_symbol": "€",
        "flag": "MQ.png"
    },
    {
        "id": 137,
        "country_name": "Mauritania",
        "capital": "Nouakchott",
        "citizenship": "Mauritanian",
        "currency": "ouguiya",
        "currency_code": "MRO",
        "full_name": "Islamic Republic of Mauritania",
        "short_name": "MR",
        "country_mobile_code": "222",
        "currency_symbol": "UM",
        "flag": "MR.png"
    },
    {
        "id": 138,
        "country_name": "Mauritius",
        "capital": "Port Louis",
        "citizenship": "Mauritian",
        "currency": "Mauritian rupee",
        "currency_code": "MUR",
        "full_name": "Republic of Mauritius",
        "short_name": "MU",
        "country_mobile_code": "230",
        "currency_symbol": "₨",
        "flag": "MU.png"
    },
    {
        "id": 139,
        "country_name": "Mexico",
        "capital": "Mexico City",
        "citizenship": "Mexican",
        "currency": "Mexican peso",
        "currency_code": "MXN",
        "full_name": "United Mexican States",
        "short_name": "MX",
        "country_mobile_code": "52",
        "currency_symbol": "$",
        "flag": "MX.png"
    },
    {
        "id": 140,
        "country_name": "Monaco",
        "capital": "Monaco",
        "citizenship": "Monegasque",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Principality of Monaco",
        "short_name": "MC",
        "country_mobile_code": "377",
        "currency_symbol": "€",
        "flag": "MC.png"
    },
    {
        "id": 141,
        "country_name": "Mongolia",
        "capital": "Ulan Bator",
        "citizenship": "Mongolian",
        "currency": "tugrik",
        "currency_code": "MNT",
        "full_name": "Mongolia",
        "short_name": "MN",
        "country_mobile_code": "976",
        "currency_symbol": "₮",
        "flag": "MN.png"
    },
    {
        "id": 142,
        "country_name": "Moldova, Republic of",
        "capital": "Chisinau",
        "citizenship": "Moldovan",
        "currency": "Moldovan leu (pl. lei)",
        "currency_code": "MDL",
        "full_name": "Republic of Moldova",
        "short_name": "MD",
        "country_mobile_code": "373",
        "currency_symbol": "MDL",
        "flag": "MD.png"
    },
    {
        "id": 143,
        "country_name": "Montenegro",
        "capital": "Podgorica",
        "citizenship": "Montenegrin",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Montenegro",
        "short_name": "ME",
        "country_mobile_code": "382",
        "currency_symbol": "€",
        "flag": "ME.png"
    },
    {
        "id": 144,
        "country_name": "Montserrat",
        "capital": "Plymouth (MS2)",
        "citizenship": "Montserratian",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Montserrat",
        "short_name": "MS",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "MS.png"
    },
    {
        "id": 145,
        "country_name": "Morocco",
        "capital": "Rabat",
        "citizenship": "Moroccan",
        "currency": "Moroccan dirham",
        "currency_code": "MAD",
        "full_name": "Kingdom of Morocco",
        "short_name": "MA",
        "country_mobile_code": "212",
        "currency_symbol": "MAD",
        "flag": "MA.png"
    },
    {
        "id": 146,
        "country_name": "Mozambique",
        "capital": "Maputo",
        "citizenship": "Mozambican",
        "currency": "metical",
        "currency_code": "MZN",
        "full_name": "Republic of Mozambique",
        "short_name": "MZ",
        "country_mobile_code": "258",
        "currency_symbol": "MT",
        "flag": "MZ.png"
    },
    {
        "id": 147,
        "country_name": "Oman",
        "capital": "Muscat",
        "citizenship": "Omani",
        "currency": "Omani rial",
        "currency_code": "OMR",
        "full_name": "Sultanate of Oman",
        "short_name": "OM",
        "country_mobile_code": "968",
        "currency_symbol": "﷼",
        "flag": "OM.png"
    },
    {
        "id": 148,
        "country_name": "Namibia",
        "capital": "Windhoek",
        "citizenship": "Namibian",
        "currency": "Namibian dollar",
        "currency_code": "NAD",
        "full_name": "Republic of Namibia",
        "short_name": "NA",
        "country_mobile_code": "264",
        "currency_symbol": "$",
        "flag": "NA.png"
    },
    {
        "id": 149,
        "country_name": "Nauru",
        "capital": "Yaren",
        "citizenship": "Nauruan",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Republic of Nauru",
        "short_name": "NR",
        "country_mobile_code": "674",
        "currency_symbol": "$",
        "flag": "NR.png"
    },
    {
        "id": 150,
        "country_name": "Nepal",
        "capital": "Kathmandu",
        "citizenship": "Nepalese",
        "currency": "Nepalese rupee",
        "currency_code": "NPR",
        "full_name": "Nepal",
        "short_name": "NP",
        "country_mobile_code": "977",
        "currency_symbol": "₨",
        "flag": "NP.png"
    },
    {
        "id": 151,
        "country_name": "Netherlands",
        "capital": "Amsterdam (NL2)",
        "citizenship": "Dutch",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Kingdom of the Netherlands",
        "short_name": "NL",
        "country_mobile_code": "31",
        "currency_symbol": "€",
        "flag": "NL.png"
    },
    {
        "id": 152,
        "country_name": "Curaçao",
        "capital": "Willemstad",
        "citizenship": "Curaçaoan",
        "currency": "Netherlands Antillean guilder (CW1)",
        "currency_code": "ANG",
        "full_name": "Curaçao",
        "short_name": "CW",
        "country_mobile_code": "599",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 153,
        "country_name": "Aruba",
        "capital": "Oranjestad",
        "citizenship": "Aruban",
        "currency": "Aruban guilder",
        "currency_code": "AWG",
        "full_name": "Aruba",
        "short_name": "AW",
        "country_mobile_code": "297",
        "currency_symbol": "ƒ",
        "flag": "AW.png"
    },
    {
        "id": 154,
        "country_name": "Sint Maarten (Dutch part)",
        "capital": "Philipsburg",
        "citizenship": "Sint Maartener",
        "currency": "Netherlands Antillean guilder (SX1)",
        "currency_code": "ANG",
        "full_name": "Sint Maarten",
        "short_name": "SX",
        "country_mobile_code": "721",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 155,
        "country_name": "Bonaire, Sint Eustatius and Saba",
        "capital": null,
        "citizenship": "of Bonaire, Sint Eustatius and Saba",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": null,
        "short_name": "BQ",
        "country_mobile_code": "599",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 156,
        "country_name": "New Caledonia",
        "capital": "Nouméa",
        "citizenship": "New Caledonian",
        "currency": "CFP franc",
        "currency_code": "XPF",
        "full_name": "New Caledonia",
        "short_name": "NC",
        "country_mobile_code": "687",
        "currency_symbol": "XPF",
        "flag": "NC.png"
    },
    {
        "id": 157,
        "country_name": "Vanuatu",
        "capital": "Port Vila",
        "citizenship": "Vanuatuan",
        "currency": "vatu (inv.)",
        "currency_code": "VUV",
        "full_name": "Republic of Vanuatu",
        "short_name": "VU",
        "country_mobile_code": "678",
        "currency_symbol": "Vt",
        "flag": "VU.png"
    },
    {
        "id": 158,
        "country_name": "New Zealand",
        "capital": "Wellington",
        "citizenship": "New Zealander",
        "currency": "New Zealand dollar",
        "currency_code": "NZD",
        "full_name": "New Zealand",
        "short_name": "NZ",
        "country_mobile_code": "64",
        "currency_symbol": "$",
        "flag": "NZ.png"
    },
    {
        "id": 159,
        "country_name": "Nicaragua",
        "capital": "Managua",
        "citizenship": "Nicaraguan",
        "currency": "córdoba oro",
        "currency_code": "NIO",
        "full_name": "Republic of Nicaragua",
        "short_name": "NI",
        "country_mobile_code": "505",
        "currency_symbol": "C$",
        "flag": "NI.png"
    },
    {
        "id": 160,
        "country_name": "Niger",
        "capital": "Niamey",
        "citizenship": "Nigerien",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Republic of Niger",
        "short_name": "NE",
        "country_mobile_code": "227",
        "currency_symbol": "XOF",
        "flag": "NE.png"
    },
    {
        "id": 161,
        "country_name": "Nigeria",
        "capital": "Abuja",
        "citizenship": "Nigerian",
        "currency": "naira (inv.)",
        "currency_code": "NGN",
        "full_name": "Federal Republic of Nigeria",
        "short_name": "NG",
        "country_mobile_code": "234",
        "currency_symbol": "₦",
        "flag": "NG.png"
    },
    {
        "id": 162,
        "country_name": "Niue",
        "capital": "Alofi",
        "citizenship": "Niuean",
        "currency": "New Zealand dollar",
        "currency_code": "NZD",
        "full_name": "Niue",
        "short_name": "NU",
        "country_mobile_code": "683",
        "currency_symbol": "$",
        "flag": "NU.png"
    },
    {
        "id": 163,
        "country_name": "Norfolk Island",
        "capital": "Kingston",
        "citizenship": "Norfolk Islander",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Territory of Norfolk Island",
        "short_name": "NF",
        "country_mobile_code": "672",
        "currency_symbol": "$",
        "flag": "NF.png"
    },
    {
        "id": 164,
        "country_name": "Norway",
        "capital": "Oslo",
        "citizenship": "Norwegian",
        "currency": "Norwegian krone (pl. kroner)",
        "currency_code": "NOK",
        "full_name": "Kingdom of Norway",
        "short_name": "NO",
        "country_mobile_code": "47",
        "currency_symbol": "kr",
        "flag": "NO.png"
    },
    {
        "id": 165,
        "country_name": "Northern Mariana Islands",
        "capital": "Saipan",
        "citizenship": "Northern Mariana Islander",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Commonwealth of the Northern Mariana Islands",
        "short_name": "MP",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "MP.png"
    },
    {
        "id": 166,
        "country_name": "United States Minor Outlying Islands",
        "capital": "United States Minor Outlying Islands",
        "citizenship": "of United States Minor Outlying Islands",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "United States Minor Outlying Islands",
        "short_name": "UM",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "UM.png"
    },
    {
        "id": 167,
        "country_name": "Micronesia, Federated States of",
        "capital": "Palikir",
        "citizenship": "Micronesian",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Federated States of Micronesia",
        "short_name": "FM",
        "country_mobile_code": "691",
        "currency_symbol": "$",
        "flag": "FM.png"
    },
    {
        "id": 168,
        "country_name": "Marshall Islands",
        "capital": "Majuro",
        "citizenship": "Marshallese",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Republic of the Marshall Islands",
        "short_name": "MH",
        "country_mobile_code": "692",
        "currency_symbol": "$",
        "flag": "MH.png"
    },
    {
        "id": 169,
        "country_name": "Palau",
        "capital": "Melekeok",
        "citizenship": "Palauan",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Republic of Palau",
        "short_name": "PW",
        "country_mobile_code": "680",
        "currency_symbol": "$",
        "flag": "PW.png"
    },
    {
        "id": 170,
        "country_name": "Pakistan",
        "capital": "Islamabad",
        "citizenship": "Pakistani",
        "currency": "Pakistani rupee",
        "currency_code": "PKR",
        "full_name": "Islamic Republic of Pakistan",
        "short_name": "PK",
        "country_mobile_code": "92",
        "currency_symbol": "₨",
        "flag": "PK.png"
    },
    {
        "id": 171,
        "country_name": "Panama",
        "capital": "Panama City",
        "citizenship": "Panamanian",
        "currency": "balboa",
        "currency_code": "PAB",
        "full_name": "Republic of Panama",
        "short_name": "PA",
        "country_mobile_code": "507",
        "currency_symbol": "B/.",
        "flag": "PA.png"
    },
    {
        "id": 172,
        "country_name": "Papua New Guinea",
        "capital": "Port Moresby",
        "citizenship": "Papua New Guinean",
        "currency": "kina (inv.)",
        "currency_code": "PGK",
        "full_name": "Independent State of Papua New Guinea",
        "short_name": "PG",
        "country_mobile_code": "675",
        "currency_symbol": "PGK",
        "flag": "PG.png"
    },
    {
        "id": 173,
        "country_name": "Paraguay",
        "capital": "Asunción",
        "citizenship": "Paraguayan",
        "currency": "guaraní",
        "currency_code": "PYG",
        "full_name": "Republic of Paraguay",
        "short_name": "PY",
        "country_mobile_code": "595",
        "currency_symbol": "Gs",
        "flag": "PY.png"
    },
    {
        "id": 174,
        "country_name": "Peru",
        "capital": "Lima",
        "citizenship": "Peruvian",
        "currency": "new sol",
        "currency_code": "PEN",
        "full_name": "Republic of Peru",
        "short_name": "PE",
        "country_mobile_code": "51",
        "currency_symbol": "S/.",
        "flag": "PE.png"
    },
    {
        "id": 175,
        "country_name": "Philippines",
        "capital": "Manila",
        "citizenship": "Filipino",
        "currency": "Philippine peso",
        "currency_code": "PHP",
        "full_name": "Republic of the Philippines",
        "short_name": "PH",
        "country_mobile_code": "63",
        "currency_symbol": "Php",
        "flag": "PH.png"
    },
    {
        "id": 176,
        "country_name": "Pitcairn",
        "capital": "Adamstown",
        "citizenship": "Pitcairner",
        "currency": "New Zealand dollar",
        "currency_code": "NZD",
        "full_name": "Pitcairn Islands",
        "short_name": "PN",
        "country_mobile_code": "649",
        "currency_symbol": "$",
        "flag": "PN.png"
    },
    {
        "id": 177,
        "country_name": "Poland",
        "capital": "Warsaw",
        "citizenship": "Polish",
        "currency": "zloty",
        "currency_code": "PLN",
        "full_name": "Republic of Poland",
        "short_name": "PL",
        "country_mobile_code": "48",
        "currency_symbol": "zł",
        "flag": "PL.png"
    },
    {
        "id": 178,
        "country_name": "Portugal",
        "capital": "Lisbon",
        "citizenship": "Portuguese",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Portuguese Republic",
        "short_name": "PT",
        "country_mobile_code": "351",
        "currency_symbol": "€",
        "flag": "PT.png"
    },
    {
        "id": 179,
        "country_name": "Guinea-Bissau",
        "capital": "Bissau",
        "citizenship": "Guinea-Bissau national",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Republic of Guinea-Bissau",
        "short_name": "GW",
        "country_mobile_code": "245",
        "currency_symbol": "XOF",
        "flag": "GW.png"
    },
    {
        "id": 180,
        "country_name": "Timor-Leste",
        "capital": "Dili",
        "citizenship": "East Timorese",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Democratic Republic of East Timor",
        "short_name": "TL",
        "country_mobile_code": "670",
        "currency_symbol": "$",
        "flag": "TL.png"
    },
    {
        "id": 181,
        "country_name": "Puerto Rico",
        "capital": "San Juan",
        "citizenship": "Puerto Rican",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Commonwealth of Puerto Rico",
        "short_name": "PR",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "PR.png"
    },
    {
        "id": 182,
        "country_name": "Qatar",
        "capital": "Doha",
        "citizenship": "Qatari",
        "currency": "Qatari riyal",
        "currency_code": "QAR",
        "full_name": "State of Qatar",
        "short_name": "QA",
        "country_mobile_code": "974",
        "currency_symbol": "﷼",
        "flag": "QA.png"
    },
    {
        "id": 183,
        "country_name": "Réunion",
        "capital": "Saint-Denis",
        "citizenship": "Reunionese",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Réunion",
        "short_name": "RE",
        "country_mobile_code": "262",
        "currency_symbol": "€",
        "flag": "RE.png"
    },
    {
        "id": 184,
        "country_name": "Romania",
        "capital": "Bucharest",
        "citizenship": "Romanian",
        "currency": "Romanian leu (pl. lei)",
        "currency_code": "RON",
        "full_name": "Romania",
        "short_name": "RO",
        "country_mobile_code": "40",
        "currency_symbol": "lei",
        "flag": "RO.png"
    },
    {
        "id": 185,
        "country_name": "Russian Federation",
        "capital": "Moscow",
        "citizenship": "Russian",
        "currency": "Russian rouble",
        "currency_code": "RUB",
        "full_name": "Russian Federation",
        "short_name": "RU",
        "country_mobile_code": "7",
        "currency_symbol": "руб",
        "flag": "RU.png"
    },
    {
        "id": 186,
        "country_name": "Rwanda",
        "capital": "Kigali",
        "citizenship": "Rwandan; Rwandese",
        "currency": "Rwandese franc",
        "currency_code": "RWF",
        "full_name": "Republic of Rwanda",
        "short_name": "RW",
        "country_mobile_code": "250",
        "currency_symbol": "RWF",
        "flag": "RW.png"
    },
    {
        "id": 187,
        "country_name": "Saint Barthélemy",
        "capital": "Gustavia",
        "citizenship": "of Saint Barthélemy",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Collectivity of Saint Barthélemy",
        "short_name": "BL",
        "country_mobile_code": "590",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 188,
        "country_name": "Saint Helena, Ascension and Tristan da Cunha",
        "capital": "Jamestown",
        "citizenship": "Saint Helenian",
        "currency": "Saint Helena pound",
        "currency_code": "SHP",
        "full_name": "Saint Helena, Ascension and Tristan da Cunha",
        "short_name": "SH",
        "country_mobile_code": "290",
        "currency_symbol": "£",
        "flag": "SH.png"
    },
    {
        "id": 189,
        "country_name": "Saint Kitts and Nevis",
        "capital": "Basseterre",
        "citizenship": "Kittsian; Nevisian",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Federation of Saint Kitts and Nevis",
        "short_name": "KN",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "KN.png"
    },
    {
        "id": 190,
        "country_name": "Anguilla",
        "capital": "The Valley",
        "citizenship": "Anguillan",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Anguilla",
        "short_name": "AI",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "AI.png"
    },
    {
        "id": 191,
        "country_name": "Saint Lucia",
        "capital": "Castries",
        "citizenship": "Saint Lucian",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Saint Lucia",
        "short_name": "LC",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "LC.png"
    },
    {
        "id": 192,
        "country_name": "Saint Martin (French part)",
        "capital": "Marigot",
        "citizenship": "of Saint Martin",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Collectivity of Saint Martin",
        "short_name": "MF",
        "country_mobile_code": "590",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 193,
        "country_name": "Saint Pierre and Miquelon",
        "capital": "Saint-Pierre",
        "citizenship": "St-Pierrais; Miquelonnais",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Territorial Collectivity of Saint Pierre and Miquelon",
        "short_name": "PM",
        "country_mobile_code": "508",
        "currency_symbol": "€",
        "flag": "PM.png"
    },
    {
        "id": 194,
        "country_name": "Saint Vincent and the Grenadines",
        "capital": "Kingstown",
        "citizenship": "Vincentian",
        "currency": "East Caribbean dollar",
        "currency_code": "XCD",
        "full_name": "Saint Vincent and the Grenadines",
        "short_name": "VC",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "VC.png"
    },
    {
        "id": 195,
        "country_name": "San Marino",
        "capital": "San Marino",
        "citizenship": "San Marinese",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of San Marino",
        "short_name": "SM",
        "country_mobile_code": "378",
        "currency_symbol": "€",
        "flag": "SM.png"
    },
    {
        "id": 196,
        "country_name": "Sao Tome and Principe",
        "capital": "São Tomé",
        "citizenship": "São Toméan",
        "currency": "dobra",
        "currency_code": "STD",
        "full_name": "Democratic Republic of São Tomé and Príncipe",
        "short_name": "ST",
        "country_mobile_code": "239",
        "currency_symbol": "Db",
        "flag": "ST.png"
    },
    {
        "id": 197,
        "country_name": "Saudi Arabia",
        "capital": "Riyadh",
        "citizenship": "Saudi Arabian",
        "currency": "riyal",
        "currency_code": "SAR",
        "full_name": "Kingdom of Saudi Arabia",
        "short_name": "SA",
        "country_mobile_code": "966",
        "currency_symbol": "﷼",
        "flag": "SA.png"
    },
    {
        "id": 198,
        "country_name": "Senegal",
        "capital": "Dakar",
        "citizenship": "Senegalese",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Republic of Senegal",
        "short_name": "SN",
        "country_mobile_code": "221",
        "currency_symbol": "XOF",
        "flag": "SN.png"
    },
    {
        "id": 199,
        "country_name": "Serbia",
        "capital": "Belgrade",
        "citizenship": "Serb",
        "currency": "Serbian dinar",
        "currency_code": "RSD",
        "full_name": "Republic of Serbia",
        "short_name": "RS",
        "country_mobile_code": "381",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 200,
        "country_name": "Seychelles",
        "capital": "Victoria",
        "citizenship": "Seychellois",
        "currency": "Seychelles rupee",
        "currency_code": "SCR",
        "full_name": "Republic of Seychelles",
        "short_name": "SC",
        "country_mobile_code": "248",
        "currency_symbol": "₨",
        "flag": "SC.png"
    },
    {
        "id": 201,
        "country_name": "Sierra Leone",
        "capital": "Freetown",
        "citizenship": "Sierra Leonean",
        "currency": "leone",
        "currency_code": "SLL",
        "full_name": "Republic of Sierra Leone",
        "short_name": "SL",
        "country_mobile_code": "232",
        "currency_symbol": "Le",
        "flag": "SL.png"
    },
    {
        "id": 202,
        "country_name": "Singapore",
        "capital": "Singapore",
        "citizenship": "Singaporean",
        "currency": "Singapore dollar",
        "currency_code": "SGD",
        "full_name": "Republic of Singapore",
        "short_name": "SG",
        "country_mobile_code": "65",
        "currency_symbol": "$",
        "flag": "SG.png"
    },
    {
        "id": 203,
        "country_name": "Slovakia",
        "capital": "Bratislava",
        "citizenship": "Slovak",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Slovak Republic",
        "short_name": "SK",
        "country_mobile_code": "421",
        "currency_symbol": "Sk",
        "flag": "SK.png"
    },
    {
        "id": 204,
        "country_name": "Viet Nam",
        "capital": "Hanoi",
        "citizenship": "Vietnamese",
        "currency": "dong",
        "currency_code": "VND",
        "full_name": "Socialist Republic of Vietnam",
        "short_name": "VN",
        "country_mobile_code": "84",
        "currency_symbol": "₫",
        "flag": "VN.png"
    },
    {
        "id": 205,
        "country_name": "Slovenia",
        "capital": "Ljubljana",
        "citizenship": "Slovene",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Republic of Slovenia",
        "short_name": "SI",
        "country_mobile_code": "386",
        "currency_symbol": "€",
        "flag": "SI.png"
    },
    {
        "id": 206,
        "country_name": "Somalia",
        "capital": "Mogadishu",
        "citizenship": "Somali",
        "currency": "Somali shilling",
        "currency_code": "SOS",
        "full_name": "Somali Republic",
        "short_name": "SO",
        "country_mobile_code": "252",
        "currency_symbol": "S",
        "flag": "SO.png"
    },
    {
        "id": 207,
        "country_name": "South Africa",
        "capital": "Pretoria (ZA1)",
        "citizenship": "South African",
        "currency": "rand",
        "currency_code": "ZAR",
        "full_name": "Republic of South Africa",
        "short_name": "ZA",
        "country_mobile_code": "27",
        "currency_symbol": "R",
        "flag": "ZA.png"
    },
    {
        "id": 208,
        "country_name": "Zimbabwe",
        "capital": "Harare",
        "citizenship": "Zimbabwean",
        "currency": "Zimbabwe dollar (ZW1)",
        "currency_code": "ZWL",
        "full_name": "Republic of Zimbabwe",
        "short_name": "ZW",
        "country_mobile_code": "263",
        "currency_symbol": "Z$",
        "flag": "ZW.png"
    },
    {
        "id": 209,
        "country_name": "Spain",
        "capital": "Madrid",
        "citizenship": "Spaniard",
        "currency": "euro",
        "currency_code": "EUR",
        "full_name": "Kingdom of Spain",
        "short_name": "ES",
        "country_mobile_code": "34",
        "currency_symbol": "€",
        "flag": "ES.png"
    },
    {
        "id": 210,
        "country_name": "South Sudan",
        "capital": "Juba",
        "citizenship": "South Sudanese",
        "currency": "South Sudanese pound",
        "currency_code": "SSP",
        "full_name": "Republic of South Sudan",
        "short_name": "SS",
        "country_mobile_code": "211",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 211,
        "country_name": "Sudan",
        "capital": "Khartoum",
        "citizenship": "Sudanese",
        "currency": "Sudanese pound",
        "currency_code": "SDG",
        "full_name": "Republic of the Sudan",
        "short_name": "SD",
        "country_mobile_code": "249",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 212,
        "country_name": "Western Sahara",
        "capital": "Al aaiun",
        "citizenship": "Sahrawi",
        "currency": "Moroccan dirham",
        "currency_code": "MAD",
        "full_name": "Western Sahara",
        "short_name": "EH",
        "country_mobile_code": "212",
        "currency_symbol": "MAD",
        "flag": "EH.png"
    },
    {
        "id": 213,
        "country_name": "Suriname",
        "capital": "Paramaribo",
        "citizenship": "Surinamese",
        "currency": "Surinamese dollar",
        "currency_code": "SRD",
        "full_name": "Republic of Suriname",
        "short_name": "SR",
        "country_mobile_code": "597",
        "currency_symbol": "$",
        "flag": "SR.png"
    },
    {
        "id": 214,
        "country_name": "Svalbard and Jan Mayen",
        "capital": "Longyearbyen",
        "citizenship": "of Svalbard",
        "currency": "Norwegian krone (pl. kroner)",
        "currency_code": "NOK",
        "full_name": "Svalbard and Jan Mayen",
        "short_name": "SJ",
        "country_mobile_code": "47",
        "currency_symbol": "kr",
        "flag": "SJ.png"
    },
    {
        "id": 215,
        "country_name": "Swaziland",
        "capital": "Mbabane",
        "citizenship": "Swazi",
        "currency": "lilangeni",
        "currency_code": "SZL",
        "full_name": "Kingdom of Swaziland",
        "short_name": "SZ",
        "country_mobile_code": "268",
        "currency_symbol": "SZL",
        "flag": "SZ.png"
    },
    {
        "id": 216,
        "country_name": "Sweden",
        "capital": "Stockholm",
        "citizenship": "Swedish",
        "currency": "krona (pl. kronor)",
        "currency_code": "SEK",
        "full_name": "Kingdom of Sweden",
        "short_name": "SE",
        "country_mobile_code": "46",
        "currency_symbol": "kr",
        "flag": "SE.png"
    },
    {
        "id": 217,
        "country_name": "Switzerland",
        "capital": "Berne",
        "citizenship": "Swiss",
        "currency": "Swiss franc",
        "currency_code": "CHF",
        "full_name": "Swiss Confederation",
        "short_name": "CH",
        "country_mobile_code": "41",
        "currency_symbol": "CHF",
        "flag": "CH.png"
    },
    {
        "id": 218,
        "country_name": "Syrian Arab Republic",
        "capital": "Damascus",
        "citizenship": "Syrian",
        "currency": "Syrian pound",
        "currency_code": "SYP",
        "full_name": "Syrian Arab Republic",
        "short_name": "SY",
        "country_mobile_code": "963",
        "currency_symbol": "£",
        "flag": "SY.png"
    },
    {
        "id": 219,
        "country_name": "Tajikistan",
        "capital": "Dushanbe",
        "citizenship": "Tajik",
        "currency": "somoni",
        "currency_code": "TJS",
        "full_name": "Republic of Tajikistan",
        "short_name": "TJ",
        "country_mobile_code": "992",
        "currency_symbol": "TJS",
        "flag": "TJ.png"
    },
    {
        "id": 220,
        "country_name": "Thailand",
        "capital": "Bangkok",
        "citizenship": "Thai",
        "currency": "baht (inv.)",
        "currency_code": "THB",
        "full_name": "Kingdom of Thailand",
        "short_name": "TH",
        "country_mobile_code": "66",
        "currency_symbol": "฿",
        "flag": "TH.png"
    },
    {
        "id": 221,
        "country_name": "Togo",
        "capital": "Lomé",
        "citizenship": "Togolese",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Togolese Republic",
        "short_name": "TG",
        "country_mobile_code": "228",
        "currency_symbol": "XOF",
        "flag": "TG.png"
    },
    {
        "id": 222,
        "country_name": "Tokelau",
        "capital": "(TK2)",
        "citizenship": "Tokelauan",
        "currency": "New Zealand dollar",
        "currency_code": "NZD",
        "full_name": "Tokelau",
        "short_name": "TK",
        "country_mobile_code": "690",
        "currency_symbol": "$",
        "flag": "TK.png"
    },
    {
        "id": 223,
        "country_name": "Tonga",
        "capital": "Nuku’alofa",
        "citizenship": "Tongan",
        "currency": "pa’anga (inv.)",
        "currency_code": "TOP",
        "full_name": "Kingdom of Tonga",
        "short_name": "TO",
        "country_mobile_code": "676",
        "currency_symbol": "T$",
        "flag": "TO.png"
    },
    {
        "id": 224,
        "country_name": "Trinidad and Tobago",
        "capital": "Port of Spain",
        "citizenship": "Trinidadian; Tobagonian",
        "currency": "Trinidad and Tobago dollar",
        "currency_code": "TTD",
        "full_name": "Republic of Trinidad and Tobago",
        "short_name": "TT",
        "country_mobile_code": "1",
        "currency_symbol": "TT$",
        "flag": "TT.png"
    },
    {
        "id": 225,
        "country_name": "United Arab Emirates",
        "capital": "Abu Dhabi",
        "citizenship": "Emirian",
        "currency": "UAE dirham",
        "currency_code": "AED",
        "full_name": "United Arab Emirates",
        "short_name": "AE",
        "country_mobile_code": "971",
        "currency_symbol": "AED",
        "flag": "AE.png"
    },
    {
        "id": 226,
        "country_name": "Tunisia",
        "capital": "Tunis",
        "citizenship": "Tunisian",
        "currency": "Tunisian dinar",
        "currency_code": "TND",
        "full_name": "Republic of Tunisia",
        "short_name": "TN",
        "country_mobile_code": "216",
        "currency_symbol": "TND",
        "flag": "TN.png"
    },
    {
        "id": 227,
        "country_name": "Turkey",
        "capital": "Ankara",
        "citizenship": "Turk",
        "currency": "Turkish lira (inv.)",
        "currency_code": "TRY",
        "full_name": "Republic of Turkey",
        "short_name": "TR",
        "country_mobile_code": "90",
        "currency_symbol": "₺",
        "flag": "TR.png"
    },
    {
        "id": 228,
        "country_name": "Turkmenistan",
        "capital": "Ashgabat",
        "citizenship": "Turkmen",
        "currency": "Turkmen manat (inv.)",
        "currency_code": "TMT",
        "full_name": "Turkmenistan",
        "short_name": "TM",
        "country_mobile_code": "993",
        "currency_symbol": "m",
        "flag": "TM.png"
    },
    {
        "id": 229,
        "country_name": "Turks and Caicos Islands",
        "capital": "Cockburn Town",
        "citizenship": "Turks and Caicos Islander",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "Turks and Caicos Islands",
        "short_name": "TC",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "TC.png"
    },
    {
        "id": 230,
        "country_name": "Tuvalu",
        "capital": "Funafuti",
        "citizenship": "Tuvaluan",
        "currency": "Australian dollar",
        "currency_code": "AUD",
        "full_name": "Tuvalu",
        "short_name": "TV",
        "country_mobile_code": "688",
        "currency_symbol": "$",
        "flag": "TV.png"
    },
    {
        "id": 231,
        "country_name": "Uganda",
        "capital": "Kampala",
        "citizenship": "Ugandan",
        "currency": "Uganda shilling",
        "currency_code": "UGX",
        "full_name": "Republic of Uganda",
        "short_name": "UG",
        "country_mobile_code": "256",
        "currency_symbol": "UGX",
        "flag": "UG.png"
    },
    {
        "id": 232,
        "country_name": "Ukraine",
        "capital": "Kiev",
        "citizenship": "Ukrainian",
        "currency": "hryvnia",
        "currency_code": "UAH",
        "full_name": "Ukraine",
        "short_name": "UA",
        "country_mobile_code": "380",
        "currency_symbol": "₴",
        "flag": "UA.png"
    },
    {
        "id": 233,
        "country_name": "Macedonia, the former Yugoslav Republic of",
        "capital": "Skopje",
        "citizenship": "of the former Yugoslav Republic of Macedonia",
        "currency": "denar (pl. denars)",
        "currency_code": "MKD",
        "full_name": "the former Yugoslav Republic of Macedonia",
        "short_name": "MK",
        "country_mobile_code": "389",
        "currency_symbol": "ден",
        "flag": "MK.png"
    },
    {
        "id": 234,
        "country_name": "Egypt",
        "capital": "Cairo",
        "citizenship": "Egyptian",
        "currency": "Egyptian pound",
        "currency_code": "EGP",
        "full_name": "Arab Republic of Egypt",
        "short_name": "EG",
        "country_mobile_code": "20",
        "currency_symbol": "£",
        "flag": "EG.png"
    },
    {
        "id": 235,
        "country_name": "United Kingdom",
        "capital": "London",
        "citizenship": "British",
        "currency": "pound sterling",
        "currency_code": "GBP",
        "full_name": "United Kingdom of Great Britain and Northern Ireland",
        "short_name": "GB",
        "country_mobile_code": "44",
        "currency_symbol": "£",
        "flag": "GB.png"
    },
    {
        "id": 236,
        "country_name": "Guernsey",
        "capital": "St Peter Port",
        "citizenship": "of Guernsey",
        "currency": "Guernsey pound (GG2)",
        "currency_code": "GGP (GG2)",
        "full_name": "Bailiwick of Guernsey",
        "short_name": "GG",
        "country_mobile_code": "44",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 237,
        "country_name": "Jersey",
        "capital": "St Helier",
        "citizenship": "of Jersey",
        "currency": "Jersey pound (JE2)",
        "currency_code": "JEP (JE2)",
        "full_name": "Bailiwick of Jersey",
        "short_name": "JE",
        "country_mobile_code": "44",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 238,
        "country_name": "Isle of Man",
        "capital": "Douglas",
        "citizenship": "Manxman; Manxwoman",
        "currency": "Manx pound (IM2)",
        "currency_code": "IMP (IM2)",
        "full_name": "Isle of Man",
        "short_name": "IM",
        "country_mobile_code": "44",
        "currency_symbol": null,
        "flag": null
    },
    {
        "id": 239,
        "country_name": "Tanzania, United Republic of",
        "capital": "Dodoma (TZ1)",
        "citizenship": "Tanzanian",
        "currency": "Tanzanian shilling",
        "currency_code": "TZS",
        "full_name": "United Republic of Tanzania",
        "short_name": "TZ",
        "country_mobile_code": "255",
        "currency_symbol": "TZS",
        "flag": "TZ.png"
    },
    {
        "id": 240,
        "country_name": "United States",
        "capital": "Washington DC",
        "citizenship": "American",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "United States of America",
        "short_name": "US",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "US.png"
    },
    {
        "id": 241,
        "country_name": "Virgin Islands, U.S.",
        "capital": "Charlotte Amalie",
        "citizenship": "US Virgin Islander",
        "currency": "US dollar",
        "currency_code": "USD",
        "full_name": "United States Virgin Islands",
        "short_name": "VI",
        "country_mobile_code": "1",
        "currency_symbol": "$",
        "flag": "VI.png"
    },
    {
        "id": 242,
        "country_name": "Burkina Faso",
        "capital": "Ouagadougou",
        "citizenship": "Burkinabe",
        "currency": "CFA franc (BCEAO)",
        "currency_code": "XOF",
        "full_name": "Burkina Faso",
        "short_name": "BF",
        "country_mobile_code": "226",
        "currency_symbol": "XOF",
        "flag": "BF.png"
    },
    {
        "id": 243,
        "country_name": "Uruguay",
        "capital": "Montevideo",
        "citizenship": "Uruguayan",
        "currency": "Uruguayan peso",
        "currency_code": "UYU",
        "full_name": "Eastern Republic of Uruguay",
        "short_name": "UY",
        "country_mobile_code": "598",
        "currency_symbol": "$U",
        "flag": "UY.png"
    },
    {
        "id": 244,
        "country_name": "Uzbekistan",
        "capital": "Tashkent",
        "citizenship": "Uzbek",
        "currency": "sum (inv.)",
        "currency_code": "UZS",
        "full_name": "Republic of Uzbekistan",
        "short_name": "UZ",
        "country_mobile_code": "998",
        "currency_symbol": "лв",
        "flag": "UZ.png"
    },
    {
        "id": 245,
        "country_name": "Venezuela, Bolivarian Republic of",
        "capital": "Caracas",
        "citizenship": "Venezuelan",
        "currency": "bolívar fuerte (pl. bolívares fuertes)",
        "currency_code": "VEF",
        "full_name": "Bolivarian Republic of Venezuela",
        "short_name": "VE",
        "country_mobile_code": "58",
        "currency_symbol": "Bs",
        "flag": "VE.png"
    },
    {
        "id": 246,
        "country_name": "Wallis and Futuna",
        "capital": "Mata-Utu",
        "citizenship": "Wallisian; Futunan; Wallis and Futuna Islander",
        "currency": "CFP franc",
        "currency_code": "XPF",
        "full_name": "Wallis and Futuna",
        "short_name": "WF",
        "country_mobile_code": "681",
        "currency_symbol": "XPF",
        "flag": "WF.png"
    },
    {
        "id": 247,
        "country_name": "Samoa",
        "capital": "Apia",
        "citizenship": "Samoan",
        "currency": "tala (inv.)",
        "currency_code": "WST",
        "full_name": "Independent State of Samoa",
        "short_name": "WS",
        "country_mobile_code": "685",
        "currency_symbol": "WS$",
        "flag": "WS.png"
    },
    {
        "id": 248,
        "country_name": "Yemen",
        "capital": "San’a",
        "citizenship": "Yemenite",
        "currency": "Yemeni rial",
        "currency_code": "YER",
        "full_name": "Republic of Yemen",
        "short_name": "YE",
        "country_mobile_code": "967",
        "currency_symbol": "﷼",
        "flag": "YE.png"
    },
    {
        "id": 249,
        "country_name": "Zambia",
        "capital": "Lusaka",
        "citizenship": "Zambian",
        "currency": "Zambian kwacha (inv.)",
        "currency_code": "ZMW",
        "full_name": "Republic of Zambia",
        "short_name": "ZM",
        "country_mobile_code": "260",
        "currency_symbol": "ZK",
        "flag": "ZM.png"
    }
]