import React, {useEffect, useState} from 'react'

const DataNotFound = () => {
    const [shouldShow, setShouldShow] = useState(false)

    useEffect(() => {
        let timer = setTimeout(() => {
            setShouldShow(true)
        }, 1000)

        return () => clearTimeout(timer)
    }, [shouldShow])

    return (
        <>
            {shouldShow &&
            <div  style={{textAlign: 'center'}}>
                <img src={'/images/data_not_found.svg'} alt={'Not Found'} style={{ width: '60%' }}/>
                <h5> Sorry! No Data Found. </h5>
            </div>}
        </>
    )

}

export default DataNotFound