export default [
    {
        name: "login",
        method: "post",
        url: "/login",
        isAuth: false,
    },
    {
        name: "signup",
        method: "post",
        url: "/register",
        isAuth: false,
    },
    {
        name: "logout",
        method: "post",
        url: "/logout",
        isAuth: true
    },
    {
        name: "get-users",
        method: "get",
        url: "/users",
        isAuth: true,
    },
    {
        name: "user-profile",
        method: "get",
        url: "/users/{id}",
        isAuth: true,
    },
    {
        name: "update-user-info",
        method: "post",
        url: "/users/{id}",
        isAuth: true,
    },
    {
        name: "get-user-information",
        method: "get",
        url: "/user-info",
        isAuth: true,
    },
    {
        name: "get-user-information-by-id",
        method: "get",
        url: "/user-info/{id}",
        isAuth: true,
    },
    {
        name: "post-user-info",
        method: "post",
        url: "/user-info",
        isAuth: true,
    },
    {
        name: "delete-user-info",
        method: "delete",
        url: "/user-info/{id}",
        isAuth: true,
    },
    {
        name: "country",
        method: "get",
        url: "/public/countries",
        isAuth: false,
    },
    {
        name: "get-client-info",
        method: "get",
        url: "/user/client-info",
        isAuth: true,
    },
    {
        name: "get-agent-info",
        method: "get",
        url: "/user/agent-info",
        isAuth: true,
    },
    {
        name: "get-agent-info-by-id",
        method: "get",
        url: "/user/agent-info/{id}",
        isAuth: true,
    },
    {
        name: "save-agent-info",
        method: "post",
        url: "/user/agent-info",
        isAuth: true,
    },
    {
        name: "delete-agent-info",
        method: "delete",
        url: "/user/agent-info/{id}",
        isAuth: true,
    },
    {
        name: "post-scholarships",
        method: "post",
        url: "/scholarships",
        isAuth: true
    },
    {
        name: "get-scholarships",
        method: "get",
        url: "/scholarships",
        isAuth: false
    },
    {
        name: "update-scholarships",
        method: "put",
        url: "/scholarships/{id}",
        isAuth: true
    },
    {
        name: "delete-scholarships",
        method: "delete",
        url: "/scholarships/{id}",
        isAuth: true
    },
    {
        name: "get-scholarship-by-id",
        method: "get",
        url: "/scholarships/{id}",
        isAuth: false
    },
    {
        name: "post-jobs",
        method: "post",
        url: "/career-jobs",
        isAuth: true
    },
    {
        name: "get-jobs",
        method: "get",
        url: "/career-jobs",
        isAuth: false
    },
    {
        name: "get-jobs-by-id",
        method: "get",
        url: "/career-jobs/{id}",
        isAuth: false
    },
    {
        name: "update-jobs",
        method: "put",
        url: "/career-jobs/{id}",
        isAuth: true
    },
    {
        name: "delete-jobs",
        method: "delete",
        url: "/career-jobs/{id}",
        isAuth: true
    },
    {
        name: "get-specialities",
        method: "get",
        url: "/specialities",
        isAuth: false
    },
    {
        name: "get-faqs",
        method: "get",
        url: "/public/faqs",
        isAuth: false
    },
    {
        name: "post-faqs",
        method: "post",
        url: "/faqs",
        isAuth: true
    },
    {
        name: "update-faqs",
        method: "put",
        url: "/faqs/{id}",
        isAuth: true
    },
    {
        name: "delete-faqs",
        method: "delete",
        url: "/faqs/{id}",
        isAuth: true
    },
    {
        name: "conversation-list",
        method: "get",
        url: "/conversations",
        isAuth: true
    },
    {
        name: "get-conversation-messages",
        method: "get",
        url: "/conversations/{id}",
        isAuth: true
    },
    {
        name: "post-conversation-message",
        method: "post",
        url: "/conversations/messages",
        isAuth: true
    },
    {
        name: "post-ticket",
        method: "post",
        url: "/support-tickets",
        isAuth: true
    },
    {
        name: "get-tickets",
        method: "get",
        url: "/support-tickets",
        isAuth: true
    },
    {
        name: "post-tickets-message",
        method: "post",
        url: "/support-tickets/message",
        isAuth: true
    },
    {
        name: "get-ticket-messages",
        method: "get",
        url: "/support-tickets/{id}/messages",
        isAuth: true
    },
    {
        name: "update-tickets-status",
        method: "post",
        url: "/support-tickets/{id}/update-status",
        isAuth: true
    },
    {
        name: "get-agent-list",
        method: "get",
        url: "/agents",
        isAuth: false
    },
    {
        name: "get-agent-by-user-id",
        method: "get",
        url: "/user/{id}/agents",
        isAuth: false
    },
    {
        name: "get-visa-categories",
        method: "get",
        url: "/visa-categories",
        isAuth: false
    },
    {
        name: "post-file",
        method: "post",
        url: "/files",
        isAuth: true
    },
    {
        name: "get-files",
        method: "get",
        url: "/files",
        isAuth: true
    },
    {
        name: "get-files-by-id",
        method: "get",
        url: "/files/{id}",
        isAuth: true
    },
    {
        name: "update-files-by-id",
        method: "post",
        url: "/update-files",
        isAuth: true
    },
    {
        name: "get-conversation-by-file-id",
        method: "get",
        url: "/files/{fileId}/conversations",
        isAuth: true
    },
    {
        name: "get-banner-text",
        method: "get",
        url: "/home/settings",
        isAuth: false
    },
    {
        name: "post-banner-text",
        method: "post",
        url: "/home/settings",
        isAuth: true
    },

    {
        name: "delete-banner-text",
        method: "delete",
        url: "/home/settings/{id}",
        isAuth: true
    },
    {
        name: "post-client",
        method: "post",
        url: "/create/client",
        isAuth: true
    },
    {
        name: "get-client",
        method: "get",
        url: "/create/client",
        isAuth: true
    },
    {
        name: "post-payment",
        method: "post",
        url: "/payments",
        isAuth: true
    },
    {
        name: "get-payment",
        method: "get",
        url: "/payments",
        isAuth: true
    },
    {
        name: "join-as-affiliator",
        method: "post",
        url: "/join-as-affiliator",
        isAuth: true
    },
    {
        name: "update-user-status",
        method: "post",
        url: "/update/user-status",
        isAuth: true
    },
    {
        name: "save-file-as-pdf",
        method: "get",
        url: "/pdf/files/{id}",
        isAuth: true
    },
    {
        name: "mark-notification-read",
        method: "put",
        url: "/notification/mark-as-read",
        isAuth: true
    },
    {
        name: "notification-list",
        method: "get",
        url: "/notification",
        isAuth: true
    },
    {
        name: "unread-notification-count",
        method: "get",
        url: "/notification/unread",
        isAuth: true
    },
    {
        name: "reset-password",
        method: "post",
        url: "/password/reset",
        isAuth: false
    },
    {
        name: "email-password",
        method: "post",
        url: "/password/email",
        isAuth: false
    },
    {
        name: "change-password",
        method: "post",
        url: "/password/change",
        isAuth: true
    },
    {
        name: "supervisor-countries",
        method: "get",
        url: "/public/supervisor-countries",
        isAuth: false,
    },
    {
        name: "career-job-countries",
        method: "get",
        url: "/public/career-job-countries",
        isAuth: false,
    },
    {
        name: "scholarship-countries",
        method: "get",
        url: "/public/scholarship-countries",
        isAuth: false,
    },
    {
        name: "file-payment-request",
        method: "get",
        url: "/files/{id}/{type}",
        isAuth: true,
    },




];
