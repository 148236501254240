import React, { useEffect, useState } from 'react';
import "../../layouts/dashboard/customdash.css"
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../components/Spinner";
import UserInfo from "../../services/UserInfo";
import DashboardUserList from "../../components/DashboardUserList";

const DashboardUsers = () => {
    const [showPage, setShowPage] = useState(true);
    const [users, setUsers] = useState([])
    const [searchName, setSearchName] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [searchRole, setSearchRole] = useState("")

    useEffect(() => {
        fetchUsers()
    }, []);

    const fetchUsers = async () => {
        let query = {}
        if (searchName.length > 0) Object.assign(query, { name: searchName });
        if (searchEmail.length > 0) Object.assign(query, { email: searchEmail });
        if (searchRole.length > 0) Object.assign(query, { role: searchRole });
        let response = await UserInfo.getUsers(query)

        if (response) {
            setUsers(response);
            setShowPage(true);
        }
    };

    const updateUserStatus = async (userId, status) => {
        let data = {
            user_id: userId,
            status: status
        }
        let response = await UserInfo.updateUserStatus(data)
        if (response) {
            fetchUsers()
        }
    }

    const resetSearch = () => {
        setSearchEmail("")
        setSearchName("")
        setSearchRole("")
        window.location.reload()
    };

    return (
        <>
            {!showPage ? (
                <Loader />
            ) : (
                <div className="container-fluid">
                    <div className="campaigns-card">
                        <div className="title-box d-flex justify-content-between">
                            <div className="title-left">
                                <h2>User List</h2>
                            </div>
                        </div>
                        <div className="filter-box mb-3">
                        <div className="form-group row mb-1">
                            <div className="search-input mb-3 col-lg-4 col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Name"
                                    onChange={(e) =>
                                        setSearchName(
                                            e.target.value,
                                        )
                                    }
                                    value={searchName}
                                />
                            </div>
                            <div className="search-input mb-3 col-lg-4 col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Email"
                                    onChange={(e) =>
                                        setSearchEmail(
                                            e.target.value,
                                        )
                                    }
                                    value={searchEmail}
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6">
                                <select
                                    className="form-control"
                                    value={searchRole}
                                    onChange={(e) =>
                                        setSearchRole(
                                            e.target.value,
                                        )
                                    }
                                >
                                    <option value="">Select Role</option>
                                    <option value="client">CLIENT</option>
                                    <option value="agent">SUPERVISOR</option>
                                    <option value="affiliator">AFFILIATOR</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb-0">
                            <button
                                className="btn--apply-filter"
                                onClick={fetchUsers}
                            >
                                Apply Filter
                            </button>
                            <button
                                className="btn--reset-all ml-3"
                                onClick={resetSearch}
                            >
                                Reset All
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <DashboardUserList userList={users} updateUserStatus={updateUserStatus} />
                    </div>
                </div>
            )}
        </>
    );
}

export default DashboardUsers;