import React, { useEffect, useState } from "react";
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import "./style.css";
import {Link, useParams} from "react-router-dom";
import CountryInfo from "../../helpers/CountryInfo";
import AgentService from "../../services/AgentService";
import AgentModal from "../../components/AgentModal";
import Navbar from "../../components/CommonNavbar"
import Storage from "../../helpers/storage";
import { AGENT_PENDING, INITIATED } from "../../constants/FileStatus";

const AgentDetails = () => {
    const [agentDetails, setAgentDetails] = useState("");
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState("");

    const { id } = useParams();

    useEffect(() => {
        fetchByUserId(id);
        isLogin()
    }, []);

    const fetchByUserId = async (userId) => {
        const response = await AgentService.fetchByUserId(userId)
        setAgentDetails(response)
    }

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (status) => {
        setStatus(status)
        setShow(true);
    };

    const isLogin = async () => {
        const user = await Storage.has('user')
        if (!user) {
            document.getElementById('initiate').classList.add("d-none")
            document.getElementById('agent-profile').classList.add("d-none")
            document.getElementById('login-btn').classList.remove("d-none")
            document.getElementById('login-btn-2').classList.remove("d-none")
        } else {
            document.getElementById('initiate').classList.remove("d-none")
            document.getElementById('agent-profile').classList.remove("d-none")
            document.getElementById('login-btn').classList.add("d-none")
            document.getElementById('login-btn-2').classList.add("d-none")
        }
    }

    return (
        <div className="job-details-wrapper">
            <Navbar />
            <div className="container extra-space">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-xs-12 jobs-card">
                        {/* job description */}
                        <div className="card">
                            <div className="card-body scrollable">
                                <h3 className="card-title mb-3">
                                    About {agentDetails.name}
                                </h3>
                                <div className="card-content">
                                    <p> {agentDetails.career_objective} </p>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="mr-1 ml-1 row" id="initiate">
                                    <button
                                        id="contact-btn-2"
                                        className="cs-btn-success-blue"
                                        onClick={() => handleShow(AGENT_PENDING)}
                                    >
                                        Request To Be Supervisor
                                    </button>
                                    <button
                                        id="contact-btn"
                                        className="cs-btn-success-blue mr-2"
                                        onClick={() => handleShow(INITIATED)}
                                    >
                                        Contact with {agentDetails.name}
                                    </button>
                                   
                                    <AgentModal handleClose={handleClose} show={show} status={status}
                                        agentInfo={agentDetails.agent_info} agentId={agentDetails.id} />
                                </div>
                                <div id="login-btn" className="hidden">
                                    <button className="cs-btn-success-blue" disabled> You have to login first</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 jobs-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="job-detail-detail">
                                    <h3 className="card-title">
                                        Supervisor Profile
                                    </h3>
                                    <ul className="list">
                                        <li>
                                            <span className="text">
                                                Profession:{" "}
                                                <b>
                                                    {agentDetails.profession}{" "}
                                                </b>{" "}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text">
                                                Nationality:{" "}
                                                <b>
                                                    {agentDetails.nationality
                                                        ? CountryInfo(
                                                            agentDetails.nationality,
                                                        ).country_name
                                                        : " "}{" "}
                                                </b>
                                            </span>
                                        </li>
                                        <li>

                                            {agentDetails.email ? (
                                                <>
                                                    {" "}
                                                    Email:
                                                    <span className="text">
                                                        <b>{agentDetails.email} </b>
                                                    </span>
                                                </>
                                            ) : null}
                                        </li>
                                        <li>
                                            {agentDetails.phone ? (
                                                <>
                                                    {" "}
                                                    Phone no:
                                                    <span className="text">
                                                        <b>{agentDetails.phone} </b>
                                                    </span>
                                                </>

                                            ) : null}
                                        </li>
                                        <li>
                                            <span className="text">

                                                {agentDetails.website ? (
                                                    <>
                                                        Website:
                                                    <a
                                                            className="value"
                                                            href={
                                                                agentDetails.website
                                                            }
                                                            style={{
                                                                color: "blue",
                                                            }}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                        >
                                                            Visit Website
                                                    </a>
                                                    </>
                                                ) : null}
                                            </span>
                                        </li>
                                        <li>

                                            {agentDetails.address ? (
                                                <span className="text">
                                                    Address:
                                                    <b>
                                                        {" "}
                                                        {
                                                            agentDetails.address
                                                        }{" "}
                                                    </b>
                                                </span>
                                            ) : null}
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    id="agent-stat"
                                    className="job-detail-detail"
                                >
                                    <h3 className="card-title">
                                        Supervisor Statistics
                                    </h3>
                                    <ul className="list mb-1">
                                        <li >
                                            Succeeded Job: {agentDetails.stats ? agentDetails.stats.completed_jobs : 0}
                                        </li>
                                    </ul>
                                    <ul className="list mb-1">
                                        <li >
                                            Failed Job: {agentDetails.stats ? agentDetails.stats.failed_jobs : 0}
                                        </li>
                                    </ul>
                                    <ul className="list">
                                        <li>
                                            Running Job: {agentDetails.stats ? agentDetails.stats.running_jobs : 0}
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <div id="agent-profile">
                                                <Link to={`/dashboard/users/${agentDetails.id}`} className="cs-btn-success mt-3"> Click For Details</Link>
                                            </div>
                                            <div id="login-btn-2" className="hidden">
                                                <button className="cs-btn-success-blue" disabled> You have to login first</button>
                                            </div>

                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-xs-12 jobs-card">
                        {/* job description */}
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">
                                    Visa Category Details
                                </h3>
                                <table className="table table-responsive-md table-striped">
                                    <thead className="table-success">
                                        <tr>
                                            <th scope="col">Country</th>
                                            <th scope="col">Visa Category</th>
                                            <th scope="col">
                                                Responsibilities
                                        </th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agentDetails.agent_info &&
                                            agentDetails.agent_info.map(
                                                (value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {value.country_id
                                                                    ? CountryInfo(
                                                                        value.country_id,
                                                                    )
                                                                        .country_name
                                                                    : " "}
                                                            </td>
                                                            <td>
                                                                {
                                                                    value
                                                                        .visa_category
                                                                        .name
                                                                }
                                                            </td>
                                                            <td>
                                                                {value.responsibilities.join(
                                                                    ", ",
                                                                )}
                                                            </td>
                                                            <td>
                                                                {value.fee}/-
                                                            </td>
                                                            <td>
                                                                {value.description &&
                                                                <p dangerouslySetInnerHTML={{ __html: value.description }} />}
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentDetails;