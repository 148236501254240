import React, { useEffect, useState } from 'react';
import { TYPES } from "../../../constants/VisaCategory";
import CountryInfo from "../../../helpers/CountryInfo";
import JoinUs from "../../JoinUs";

const AgentTab = ({ data }) => {
    // const [showBtn, setShowBtn] = useState(false)
    //
    // useEffect(() => {
    //     let timer = setTimeout(() => {
    //         setShowBtn(true)
    //     }, 1000)
    //
    //     return () => clearTimeout(timer)
    // }, [])

    return (
        <div className="tab-pane" id="agent">
            <h5 className="mb-4 mt-2 text-uppercase card-title">Supervising Information</h5>
            {data && data.length > 0 && data[0].hasOwnProperty('visa_category_id')
                ? data.map((value, key) => {

                    return (
                        <ul key={key} className="list-unstyled timeline-sm">

                            <li className="timeline-sm-item">
                                <h5 className="mt-0 mb-1"><strong>Country :</strong> {CountryInfo(value.country_id).country_name}</h5>
                                <p className="cs--text-color"><strong>Visa Category
                                :</strong> {TYPES[value.visa_category_id]}</p>
                                <p className="cs--text-color"><strong>Processing Time : </strong>
                                    {value.processing_time} months</p>
                                <p className="cs--text-color"><strong>Non Refundable Fee : </strong>
                                    {value.non_refundable_fee}</p>
                                <strong>Responsibility : <br /></strong>
                                <ol className="text-muted mb-3 font-14">

                                    {value.responsibilities && value.responsibilities.map((responsibility, key) => {
                                        return (
                                            <li className="ml-2 mb-1" style={{ listStyleType: 'square' }}
                                                key={key}>{responsibility} <br /></li>
                                        )
                                    })}
                                </ol>
                                <span><b>Fee : </b>BDT {value.fee}</span>
                                <div className="text-muted mt-2">
                                    <strong>Description : </strong>
                                    {value.description &&
                                    <p dangerouslySetInnerHTML={{ __html: value.description }} />}
                                </div>
                            </li>


                        </ul>)
                }) :
                <div style={{ textAlign: 'center' }}>
                    <img src={'/images/handshake.svg'} alt={'Not Found'} style={{ width: '60%' }} />
                    <h5>No Supervising information Found!</h5>
                </div>}
        </div>
    );
}

export default AgentTab
