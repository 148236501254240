const parse = JSON.parse
const stringify = JSON.stringify

const Storage = {

    clear(key) {
        if(localStorage && localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
        if(sessionStorage && sessionStorage.getItem(key)) {
            sessionStorage.removeItem(key)
        }
    },
    get(key) {
        if(localStorage) {
            return parse(localStorage.getItem(key)) || null
        }
        if(sessionStorage) {
            return parse(localStorage.getItem(key)) || null
        }
    },
    set(key, value, isLocalStorage){
        if(localStorage && isLocalStorage) {
            return localStorage.setItem(key, stringify(value))
        }
        if(sessionStorage) {
            return sessionStorage.setItem(key, stringify(value))
        }
    },
    has(key) {
        if(localStorage && localStorage.getItem(key)) {
            return true
        }
        if(sessionStorage && sessionStorage.getItem(key)) {
            return true
        }
        return false
    },
    destroy(){
        if(localStorage) {
            localStorage.clear()
        }
        if(sessionStorage) {
            sessionStorage.clear()
        }
    }
}

export default Storage