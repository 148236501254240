import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {FileStatus} from "../../constants/FileStatus";

const AfiliatorClientList = ({ Client }) => {
    const [show, setShow] = useState(false);
    const [clientFiles, setClientFiles] = useState([]);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = (files) => {
        setClientFiles(files)
        setShow(true);
    }

    return (
        <div className="col-lg-12">
            <div className="table-responsive-sm">
                <div className={Client && Client.length > 0 ? "table-table-head align-items-center" : "none"}>
                    <div className="column-head w-30">Name</div>
                    <div className="column-head w-30">Email</div>
                    <div className="column-head w-30">File</div>
                    {/* <div className="column-head w-15">Action</div> */}
                </div>
                {Client && Client.map((client, key) => {
                    return (
                        <div
                            className="table--content-box align-items-center" key={key}>
                            <div className="column w-30">{client.name}</div>
                            <div className="column w-30">{client.email}</div>
                            <div className="column text-primary w-30">
                                {client.client_files.length > 0 ?
                                    <button type="button" className="cs-btn-success" onClick={() => {handleShow(client.client_files)}}> See details </button> : <span> "N/A" </span>}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Client File Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive-sm">
                        <div className={Client && Client.length > 0 ? "table-table-head align-items-center" : "none"}>
                            <div className="column-head w-30">Visa Category</div>
                            <div className="column-head w-30">Status</div>
                        </div>
                        {clientFiles.length > 0 && clientFiles.map((item, key) => {
                            return (
                                <div
                                    className="table--content-box align-items-center" key={key}>
                                    <div className="column w-30">{item.visa_category.name}</div>
                                    <div className="column w-30">{FileStatus[item.status]}</div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AfiliatorClientList
