import React, { useState } from "react";
import Basic from "../MultistepForm/Basic";
import Professional from "../MultistepForm/Professional";
import Educational from "../MultistepForm/Educational";
import AgentForm from "../MultistepForm/AgentForm";

const AgentInfo = (props) => {
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };
    const formType = "agent"

    switch (step) {
        case 1:
            return (
                <AgentForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                    history={props.history}
                />
            );

        case 2:
            return (
                <Professional
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                />
            );

        case 3:
            return (
                <Educational
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                />
            );
        case 4:
            return (
                <AgentForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                    history={props.history}
                />
            );
        default:
            return (
                <Basic
                    nextStep={nextStep}
                    prevStep={prevStep}
                    formType={formType}
                />
            );
    }
};

export default AgentInfo;
