import React from "react"

const ForStudent = ({ handleChange, itemKey, item, remove }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="language-skills-wrapper" id="stick">
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <label>ILETS/TOFEL/GRE</label>
              <select
                className="form-control required"
                type="text"
                id={`title-${itemKey}`}
                value={item.title}
                name={`title[${itemKey}]`}
                onChange={handleChange}
              >
                <option>Select Option</option>
                <option value="gre">GRE</option>
                <option value="toefl">TOFEL</option>
                <option value="ielts">ILETS</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <label>Score</label>
              <input
                className="form-control required"
                type="text"
                id={`score-${itemKey}`}
                value={item.score}
                name={`score[${itemKey}]`}
                onChange={handleChange}
                placeholder="7.5"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="col-lg-12 col-sm-12">
            <button
              id="examRemoveBtn"
              className="cs-btn btn-remove"
              onClick={(e) => remove(e, itemKey, item.id)}
            >
              Remove{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForStudent
