import React, {useEffect, useRef} from 'react'
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons/faPaperclip";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import {commonAvatar, showLocalImage} from "../../helpers";

const DescriptionModal = ({
                              addDescription,
                              descriptions,
                              setMessage,
                              handleClose,
                              show,
                              setAttachment,
                              authUser,
                              message
                          }) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <ul className="conversation-list" data-simplebar="init" style={{maxHeight: '500px'}}>
                        <div className="simplebar-wrapper" style={{margin: '0px -15px'}}>
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer"/>
                            </div>
                            <div className="simplebar-mask">
                                <div className="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                                    <div className="simplebar-content-wrapper"
                                         style={{height: 'auto', overflow: 'hidden scroll'}}>
                                        <div id="scroll" className="simplebar-content" style={{padding: '0px 15px'}}>
                                            {descriptions && descriptions.map((description, index) => {
                                                return (
                                                    <li className={`clearfix ${authUser.user_id == description.sender_id ? 'odd' : 'even'}`}
                                                        key={index}>
                                                        <div className="chat-avatar">
                                                            <img
                                                                src={description.user.avatar ? description.user.avatar.source_path : commonAvatar()}
                                                                alt="user-image"
                                                                className="rounded-circle mr-3"
                                                                width={40}
                                                            />
                                                            <i>{description.created_at}</i>
                                                        </div>
                                                        <div className="conversation-text">
                                                            <div className="ctext-wrap">
                                                                <p>
                                                                    {description.message ? description.message : showLocalImage(description.attachment.source_path)}
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </li>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{width: 'auto', height: '907px'}}/>
                        </div>
                        <div className="simplebar-track simplebar-horizontal" style={{visibility: 'hidden'}}>
                            <div className="simplebar-scrollbar" style={{width: '0px', display: 'none'}}/>
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{visibility: 'visible'}}>
                            <div className="simplebar-scrollbar"
                                 style={{
                                     height: '233px',
                                     transform: 'translate3d(0px, 0px, 0px)',
                                     display: 'block'
                                 }}/>
                        </div>
                    </ul>
                    <div className="row">
                        <div className="col">
                            <div className="mt-2 bg-light p-3 rounded">
                                <form className="needs-validation" noValidate name="chat-form" id="chat-form">
                                    <div className="row">
                                        <div className="col mb-2 mb-sm-0">
                                            <input type="text" className="form-control border-0"
                                                   value={message}
                                                   onChange={(event) => {
                                                       setMessage(event.target.value)
                                                   }}
                                                   placeholder="Enter your text"/>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div className="btn-group">
                                                <div className="image-upload">
                                                    <label htmlFor="file-input" style={{marginTop: '6px'}}>
                                                        <FontAwesomeIcon
                                                            className="attach-doc text-primary fa-2x"
                                                            icon={faPaperclip} aria-hidden="true"
                                                            style={{'cursor': 'pointer'}}/>
                                                    </label>
                                                    <input id="file-input" type="file"
                                                           accept={'image/jpeg,image/jpg,image/png'}
                                                           style={{'display': 'none'}}
                                                           onChange={(event) => setAttachment(event.target.files[0])}/>
                                                </div>
                                                <button type="button"
                                                        onClick={() => addDescription()}
                                                        className="btn btn-alert chat-send btn-block">
                                                    <FontAwesomeIcon className="text-primary fa-2x"
                                                                     icon={faPaperPlane}/>
                                                </button>
                                            </div>
                                        </div>
                                        {/* end col */}
                                    </div>
                                    {/* end row*/}
                                </form>
                            </div>
                        </div>
                        {/* end col*/}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DescriptionModal
